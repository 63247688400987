export const groupBy = <T extends { [key: string]: any }>(
  list: T[],
  key: string,
): T[][] => {
  const result = list.reduce((acc, item) => {
    if (item[key] === acc.slice(-1)?.[0]?.[0][key]) {
      return [...acc.slice(0, -1), [...acc.slice(-1)?.[0], item]] as T[][];
    }
    return [...acc, [item]] as T[][];
  }, [] as T[][]);
  return result;
};
