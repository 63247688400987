import {
  AddOutlined,
  ChevronRight,
  ContentCopyOutlined,
  DeleteOutlined,
  ExpandMoreOutlined,
} from "@mui/icons-material";
import { Button, Checkbox } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { translations } from "../../core/constants/translations";
import { useHandleClickOutside, useLangParam } from "../../core/hooks";
import { Time } from "../../core/utils/time-utils";
import { dayObject, timeOptions } from "../../pages/demo/components/constants";
import { selectCommerceId } from "../../store/commerce/selectors";
import { SwitchCheckbox } from "../switch/switch";
import { WeekScheduleTimeframeInterface } from "./interfaces";

export const WeekScheduleTable = <T extends WeekScheduleTimeframeInterface>({
  timeframes,
  onAddTimeframe,
  onRemoveTimeframes,
  onUpdateTimeframes,
}: {
  timeframes: T[];
  onAddTimeframe: (t: Omit<T, "id">) => void;
  onRemoveTimeframes: (ids: string[]) => void;
  onUpdateTimeframes: (t: Partial<T>) => void;
}) => {
  const [dayOpened, setDayOpened] = useState<string>();

  return (
    <div className="flex flex-col p-6">
      {Object.keys(dayObject).map((day, i, list) => {
        const dayFrames = timeframes.filter((frame) => {
          return frame.day === day.toUpperCase();
        });

        const isEnabled = dayFrames.length > 0;

        return (
          <DayScheduleItem
            day={day}
            key={i}
            index={i}
            last={list.length - 1 === i}
            dayFrames={dayFrames}
            isEnabled={isEnabled}
            setIsEnabled={() => {
              if (!isEnabled) {
                onAddTimeframe({
                  day: day.toUpperCase() as any,
                  start: "9:00",
                  duration: 60 * 9,
                } as T);
              } else {
                onRemoveTimeframes(dayFrames.map((f) => f.id));
              }
            }}
            isOpen={dayOpened === day}
            setIsOpen={(v) => {
              if (dayOpened === day || !v) setDayOpened(undefined);
              else setDayOpened(day);
            }}
            onAddTimeframe={onAddTimeframe}
            onRemoveTimeframes={onRemoveTimeframes}
            onUpdateTimeframes={onUpdateTimeframes}
          />
        );
      })}
    </div>
  );
};

const DayScheduleItem = <T extends WeekScheduleTimeframeInterface>({
  index,
  day,
  last,
  isOpen,
  isEnabled,
  setIsEnabled,
  setIsOpen,
  dayFrames,
  onRemoveTimeframes,
  onAddTimeframe,
  onUpdateTimeframes,
}: {
  index: number;
  day: string;
  last: boolean;
  isEnabled: boolean;
  setIsEnabled: (v: boolean) => void;
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  dayFrames: T[];
  onAddTimeframe: (t: Omit<T, "id">) => void;
  onRemoveTimeframes: (ids: string[]) => void;
  onUpdateTimeframes: (t: Partial<T>) => void;
}) => {
  const lang = useLangParam();

  const border = "border-b border-gray-300";

  return (
    <div className={`py-4 ${!last ? border : ""}`}>
      <div
        className={`flex gap-3 items-center ${isEnabled ? "cursor-pointer" : ""}`}
        onClick={() => {
          isEnabled && setIsOpen(!isOpen);
        }}
      >
        <SwitchCheckbox
          value={isEnabled}
          setValue={(v) => {
            setIsEnabled(v);
            setIsOpen(false);
          }}
        />

        <div className="flex items-center justify-between w-full">
          <p className="font-medium w-[70px]">{(translations["Onboarding"]["Business Hours"] as any)[day][lang]}</p>

          {isEnabled && (
            <p className="text-sm text-gray-400">
              {dayFrames.length === 1
                ? `${new Time(dayFrames[0].start!).str} - ${new Time(dayFrames[0].start!).addMinutes(dayFrames[0].duration!)?.str}`
                : `Custom`}
            </p>
          )}

          {isEnabled && (
            <div className="">
              <ChevronRight
                sx={{
                  transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                  transition: "transform .1s ease",
                }}
              />
            </div>
          )}
        </div>
      </div>

      {isOpen && (
        <DayScheduleDropdown
          index={index}
          dayFrames={dayFrames}
          onAddTimeframe={onAddTimeframe}
          onRemoveTimeframes={onRemoveTimeframes}
          onUpdateTimeframes={onUpdateTimeframes}
        />
      )}
    </div>
  );
};

const DayScheduleDropdown = <T extends WeekScheduleTimeframeInterface>({
  index,
  dayFrames,
  onAddTimeframe,
  onRemoveTimeframes,
  onUpdateTimeframes,
}: {
  index: number;
  dayFrames: T[];
  onAddTimeframe: (t: Omit<T, "id">) => void;
  onRemoveTimeframes: (ids: string[]) => void;
  onUpdateTimeframes: (t: Partial<T>) => void;
}) => {
  const commerceId = useSelector(selectCommerceId);

  return (
    <div>
      {dayFrames.map(({ id, start, duration, day }, i) => {
        const isFirst = i === 0;

        const otherFrames = dayFrames.filter((frame) => {
          return frame.id !== id;
        });

        const zeroConflictOptions = timeOptions.filter((option) => {
          return otherFrames.every((frame) => {
            const ot = new Time(option);
            return (
              !frame.start ||
              ot.lt(frame.start) ||
              !frame.start ||
              !frame.duration ||
              ot.gt(new Time(frame.start)?.addMinutes(frame.duration)?.str!)
            );
          });
        });

        const startOptions = zeroConflictOptions.filter((option) => {
          const optionTime = new Time(option);

          if (!duration) return true;

          const endTime = new Time(start ?? "0:00").addMinutes(duration)?.str;

          if (!endTime) return true;

          return optionTime.lt(endTime);
        });

        const endOptions = zeroConflictOptions.filter((option) => {
          const optionTime = new Time(option);

          if (!start) return true;

          return optionTime.gt(start);
        });

        return (
          <div className="flex items-center gap-2 mt-4" key={i}>
            <TimeSelector
              options={startOptions}
              time={start ? new Time(start).str : undefined}
              onChange={(time) => {
                if (!duration) {
                  return onUpdateTimeframes({
                    id,
                    start: time,
                  } as Partial<T>);
                }

                const cm = new Time(start ?? "0:00")
                  .addMinutes(duration)
                  ?.toMinutes();
                const nm = new Time(time).toMinutes();

                if (cm === undefined || nm === undefined) return;

                return onUpdateTimeframes({
                  id,
                  start: time,
                  duration: cm - nm,
                } as Partial<T>);
              }}
            />

            <p className="text-gray-500">to</p>

            <TimeSelector
              options={endOptions}
              time={
                duration
                  ? new Time(start ?? "0:00").addMinutes(duration)?.str
                  : undefined
              }
              onChange={(time) => {
                const cm = new Time(time).toMinutes();

                if (!start) {
                  return onUpdateTimeframes({
                    id,
                    duration: cm,
                  } as Partial<T>);
                }

                const sm = new Time(start).toMinutes();

                if (cm === undefined || sm === undefined) return;

                onUpdateTimeframes({
                  id,
                  duration: cm - sm,
                } as Partial<T>);
              }}
            />

            {isFirst ? (
              <div className="flex justify-between w-20 pl-2">
                <AddOutlined
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    commerceId &&
                      onAddTimeframe({
                        day: day,
                      } as T);
                  }}
                />

                <CopyFrames
                  renderOnTop={index > 3}
                  dayFrames={dayFrames}
                  onAddTimeframe={onAddTimeframe}
                />
              </div>
            ) : (
              <div className="flex justify-between w-20 pl-2">
                <DeleteOutlined
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    commerceId && onRemoveTimeframes([id]);
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const TimeSelector = ({
  time,
  onChange,
  options,
}: {
  time?: string;
  onChange: (time: string) => void;
  options: string[];
}) => {
  const [open, setOpen] = useState(false);

  const ref = useHandleClickOutside<HTMLDivElement>(() => {
    setOpen(false);
  });

  const refs = options.reduce((acc, option) => {
    return {
      ...acc,
      [option]: createRef<HTMLDivElement>(),
    };
  }, {} as { [option: string]: React.RefObject<HTMLDivElement> });

  useEffect(() => {
    if (time) {
      refs[time]?.current?.scrollIntoView();
    }
  }, [refs, time]);

  return (
    <div ref={ref}>
      <div
        className="flex border border-gray-300 rounded-md w-28 p-2 cursor-pointer"
        onClick={() => {
          setOpen((v) => !v);
        }}
      >
        <p>{time}</p>

        <ExpandMoreOutlined
          sx={{
            ml: "auto",
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.1s ease",
          }}
        />
      </div>

      {open && (
        <div className="relative w-0">
          <div className="flex flex-col w-28 absolute bg-white h-56 z-30 overflow-y-scroll rounded-md shadow-md">
            {options.map((time) => {
              return (
                <p
                  ref={refs[time]}
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    onChange(time);
                    setOpen(false);
                  }}
                >
                  {time}
                </p>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const CopyFrames = <T extends WeekScheduleTimeframeInterface>({
  renderOnTop,
  dayFrames,
  onAddTimeframe,
}: {
  renderOnTop: boolean;
  dayFrames: T[];
  onAddTimeframe: (t: Omit<T, "id">) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState<string[]>([]);

  return (
    <div className="">
      <ContentCopyOutlined
        sx={{
          cursor: "pointer",
        }}
        onClick={() => {
          setOpen((v) => !v);
        }}
      />

      {open && (
        <div className="relative">
          <div
            className={`
              absolute bg-white w-48 z-30 rounded-md right-0 shadow
              ${renderOnTop ? "bottom-7" : "top-1"}
            `}
          >
            <p className="font-medium p-3">Copy schedule</p>

            {Object.keys(dayObject).map((day, i) => {
              return (
                <div key={i} className="flex items-center gap-1">
                  <Checkbox
                    value={checked.includes(day)}
                    onClick={() => {
                      if (checked.includes(day))
                        setChecked(checked.filter((c) => c !== day));
                      else setChecked([...checked, day]);
                    }}
                  />

                  <p>{day}</p>
                </div>
              );
            })}

            <div className="p-3 w-full">
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                }}
                onClick={() => {
                  checked.forEach((day) => {
                    dayFrames.forEach((frame) => {
                      onAddTimeframe({
                        day: day.toUpperCase() as any,
                        start: frame.start,
                        duration: frame.duration,
                      } as T);
                    });
                  });

                  setOpen(false);
                }}
              >
                COPY
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
