import Logo from "../../assets/mb-logo.svg";
import { LogoutBtn } from "../buttons/logout.btn"
import { selectUserId } from "../../store/user/selectors";
import { useSelector } from "react-redux";
import { SignInBtn } from "../buttons/sign-in.btn"
import { SignUpBtn } from "../buttons/sign-up.btn"
import { UpgradeBtn } from "../buttons/upgrade.btn"


export function Topbar() {
  const userId = useSelector(selectUserId);
  return (
    <div className="h-20 flex items-center px-10 justify-between select-none">
      <div className="flex items-center">
        <div className="bg-slate-700 p-1 rounded-md mr-3">
          <img id="logo" src={Logo} alt="Logo" className="w-9 h-9" />
        </div>
        
        <div className="font-logo-semibold text-4xl font-semibold text-slate-700">
          Magnetic<span className="font-logo text-indigo-600">Bunny</span>
        </div>
      </div>
      
      <div className="flex gap-2 items-center text-lg font-medium">
        <UpgradeBtn />
        {userId ? <LogoutBtn /> : (<><SignInBtn /> <SignUpBtn /></>) }
      </div>
    </div>
  )
}