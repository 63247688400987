export type Status = "pending" | "fulfilled" | "rejected" | "idle";

export enum Day {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
}

export enum AppointmentStatus {
  scheduled = "scheduled",
  approved = "approved",
  confirmed = "confirmed",
  paid = "paid",
  finished = "finished",
  cancelled = "cancelled",
  rejected = "rejected",
  pending = "pending",
  draft = "draft",
}
