import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupabaseApi } from "../../api/supabase";
import type { TimeframeInterface } from "./interfaces";

const fromUTC = (time: string) => {
  const [hour, minutes, seconds] = time.split(":");

  const validatedTime = `${(hour || "00").padStart(2, "0")}:${minutes || "00"}:${seconds || "00"}`;

  const wrappedTime = new Date(`1970-01-01T${validatedTime}Z`).getTime();
  const offset = new Date().getTimezoneOffset();
  const newTime = new Date(wrappedTime - offset * 60 * 1000);

  return `${newTime.getUTCHours().toString().padStart(2, "0")}:${newTime.getUTCMinutes().toString().padStart(2, "0")}:${newTime.getUTCSeconds().toString().padStart(2, "0")}`;
}

const toUTC = (time: string) => {
  const [hour, minutes, seconds] = time.split(":");

  const validatedTime = `${(hour || "00").padStart(2, "0")}:${minutes || "00"}:${seconds || "00"}`;

  const wrappedTime = new Date(`1970-01-01T${validatedTime}Z`).getTime();
  const offset = new Date().getTimezoneOffset();
  const newTime = new Date(wrappedTime + offset * 60 * 1000);

  return `${newTime.getUTCHours().toString().padStart(2, "0")}:${newTime.getUTCMinutes().toString().padStart(2, "0")}:${newTime.getUTCSeconds().toString().padStart(2, "0")}`;
}

const fromUTCTimeframe = ({ start, ...frame }: Partial<TimeframeInterface>) => {
  return {
    ...frame,
    start: start && fromUTC(start),
  } as TimeframeInterface;
}

const toUTCTimeframe = ({ start, ...frame }: Partial<TimeframeInterface>) => {
  return {
    ...frame,
    start: start && toUTC(start),
  } as TimeframeInterface;
}

export const getTimeframesThunk = createAsyncThunk(
  "timeframe/getTimeframes",
  async (commerce_id: string) => {
    const response = await SupabaseApi.supabase
      .from("commerce_timeframes")
      .select()
      .eq("commerce_id", commerce_id);

    const UTC = response.data as any as TimeframeInterface[];

    return UTC.map(fromUTCTimeframe);
  },
);

export const addTimeframesThunk = createAsyncThunk(
  "timeframe/addTimeframes",
  async (timeframe: Omit<TimeframeInterface, "id">) => {
    const response = await SupabaseApi.supabase
      .from("commerce_timeframes")
      .insert({ ...toUTCTimeframe(timeframe) })
      .select();

    const UTC = response.data?.at(0) as any as TimeframeInterface;

    return fromUTCTimeframe(UTC);
  },
);

export const updateTimeframesThunk = createAsyncThunk(
  "timeframe/updateTimeframes",
  async (timeframe: Partial<TimeframeInterface>) => {
    const { id, ...state } = timeframe;

    if (!id) return;

    await SupabaseApi.supabase
      .from("commerce_timeframes")
      .update({ ...toUTCTimeframe(state) })
      .eq("id", id);

    return timeframe;
  },
);

export const removeTimeframeThunk = createAsyncThunk(
  "timeframe/removeTimeframe",
  async (timeframeId: string) => {
    await SupabaseApi.supabase
      .from("commerce_timeframes")
      .delete()
      .eq("id", timeframeId);

    return timeframeId;
  },
);

export const removeTimeframesThunk = createAsyncThunk(
  "timeframe/removeTimeframes",
  async (timeframeIds: string[]) => {
    await SupabaseApi.supabase
      .from("commerce_timeframes")
      .delete()
      .in("id", timeframeIds);

    return timeframeIds;
  },
);
