import { AddOutlined, ContentCopyOutlined } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeMessages } from "../../store/agents/agents";
import { AppDispatch } from "../../store/store";
import { addCustomerLink } from "../../store/customer-link/thunks";
import { selectCommerceId } from "../../store/commerce/selectors";
import { addCustomerThunk, getCustomerThunk } from "../../store/customer/thunks";
import { selectCustomerLinks } from "../../store/customer-link/selectors";
import { useHandleClickOutside, useLangParam } from "../../core/hooks";
import { setCurrentCustomer } from "../../store/customer/customer";
import { selectCurrentCustomerId, selectCustomerError, selectCustomers } from "../../store/customer/selectors";
import { Modal } from "../modal/modal";
import { translations } from "../../core/constants/translations";
import { getMessages } from "../../store/agents/thunks";

export const ChooseCustomer = () => {
  const [open, setOpen] = useState(false);

  const dropdownRef = useHandleClickOutside<HTMLDivElement>(() => {
    setOpen(false);
  });

  const [openInputCustomer, setOpenInputCustomer] = useState(false);

  return (
    <div
      className="p-3 hover:bg-indigo-200 cursor-pointer rounded-full"
      onClick={() => {
        setOpen(v => !v);
      }}
      ref={dropdownRef}
    >
      {openInputCustomer &&
        <InputCustomerIdModal
          onClose={() => setOpenInputCustomer(false)}
        />
      }

      {open &&
        <ChooseCustomerDropdown
          onOpenInputCustomer={() => {
            setOpenInputCustomer(true);
          }}
        />
      }

      <AddOutlined />
    </div>
  );
}

const ChooseCustomerDropdown = ({
  onOpenInputCustomer,
}: {
  onOpenInputCustomer: () => void,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();

  const translation = translations["Demo"]["Chat"];

  const commerceId = useSelector(selectCommerceId);

  return (
    <div className="relative h-0 w-0">
      <div className={`
        absolute bg-white bottom-4 -left-3 rounded-md w-72
        flex flex-col hover:ignore-parent overflow-hidden
        z-30
      `}>
        <ListOfCustomers />

        <OptionItem
          Icon={<ContentCopyOutlined />}
          title={translation["New chat with uuid"][lang]}
          onClick={onOpenInputCustomer}
        />

        <OptionItem
          Icon={<AddOutlined />}
          title={translation["New Chat"][lang]}
          onClick={() => {
            dispatch(removeMessages());

            dispatch(addCustomerThunk()).unwrap().then(({ id }) => {
              commerceId && dispatch(addCustomerLink({
                customerId: id,
                commerceId,
              }));

              dispatch(setCurrentCustomer(id));
            });
          }}
        />
      </div>
    </div>
  );
}

const InputCustomerIdModal = ({
  onClose,
}: {
  onClose: () => void,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [customerId, setCustomerId] = useState<string>();
  
  const error = useSelector(selectCustomerError);

  return (
    <Modal
      title="Input customer id"
      onClose={onClose}
    >
      <div className="flex flex-col items-center justify-center p-6 gap-4">
        <TextField
          label={"Customer Id"}
          size="small"
          value={customerId}
          onChange={({ target: { value } }) => {
            setCustomerId(value);
          }}
          error={!!error}
          helperText={error}
        />

        <div className="flex gap-6">
          <p
            className={`
              px-6 py-2 rounded-full text-indigo-500 font-medium
              cursor-pointer hover:bg-indigo-100
            `}
            onClick={onClose}
          >
            CANCEL
          </p>

          <p
            className={`
              px-6 py-2 rounded-full text-white font-medium
              ${customerId ? "bg-indigo-500 hover:bg-indigo-400 cursor-pointer" : "bg-gray-300"}
            `}
            onClick={() => {
              customerId &&
                dispatch(getCustomerThunk(customerId)).unwrap()
                  .then(({ id }) => {
                    dispatch(setCurrentCustomer(id));
                    onClose();
                  });
            }}
          >
            SUBMIT
          </p>
        </div>
      </div>
    </Modal>
  );
}

const OptionItem = ({
  Icon,
  title,
  onClick,
}: {
  Icon: ReactElement,
  title: string,
  onClick: () => void,
}) => {
  return (
    <div
      className="flex gap-2 hover:bg-gray-100 p-3"
      onClick={onClick}
    >
      {Icon}

      <p className="">
        {title}
      </p>
    </div>
  );
}

const ListOfCustomers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();

  const translation = translations["Demo"]["Chat"];

  const customerLinks = useSelector(selectCustomerLinks);
  const customers = useSelector(selectCustomers);
  const currentCustomerId = useSelector(selectCurrentCustomerId);
  const commerceId = useSelector(selectCommerceId);

  return (
    <div className={`
      flex flex-col-reverse max-h-36 overflow-y-scroll mr-2
      scrollbar scrollbar-thumb-rounded-full scrollbar-thumb-indigo-400/25
    `}>
      {customerLinks.map(({ customer_id, }, i) => {
        const customer = customers.find(({ id }) => customer_id === id);

        const name = customer?.first_name && customer.last_name ? `${customer.first_name} ${customer.last_name}` : undefined;

        if (customer_id === currentCustomerId) return null;

        return (
          <div
            key={i}
            className={`p-3 gap-2 flex hover:bg-gray-100`}
            onClick={async () => {
              dispatch(removeMessages());
              dispatch(setCurrentCustomer(customer_id));
              commerceId && dispatch(getMessages({
                customerId: customer_id,
                commerceId: commerceId,
              }));
            }}
          >
            <p className="">
              {translation["New chat with"][lang]} {name ?? customer_id.slice(0, 8)}
            </p>
          </div>
        );
      })}
    </div>
  );
}
