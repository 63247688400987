import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupabaseApi } from "../../api/supabase"
import { CustomerLinkInterface } from "./interfaces";

export const getCustomerLinksThunk = createAsyncThunk(
  "customerLink/getCustomerLinks",
  async (commerceId: string) => {
    const response = await SupabaseApi.supabase
      .from("customer_link")
      .select("*")
      .eq("commerce_id", commerceId);

    return (response.data as any) as CustomerLinkInterface[];
  }
)

export const addCustomerLink = createAsyncThunk(
  "customerLink/addCustomerLink",
  async (data: { commerceId: string, customerId: string }) => {
    const response = await SupabaseApi.supabase
      .from("customer_link")
      .insert({
        customer_id: data.customerId,
        commerce_id: data.commerceId
      })
      .select();

    return (response.data?.at(0) as any) as CustomerLinkInterface;
  }
)
