import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupabaseApi } from "../../api/supabase";
import type { ServiceInterface } from "./interfaces";

export const getServicesThunk = createAsyncThunk(
  "service/getServices",
  async (commerce_id: string) => {
    const response = await SupabaseApi.supabase
      .from("service")
      .select()
      .eq("commerce_id", commerce_id);

    return response.data as any as ServiceInterface[];
  },
);

export const addServiceThunk = createAsyncThunk(
  "service/addService",
  async (service: Omit<ServiceInterface, "id">) => {
    const response = await SupabaseApi.supabase
      .from("service")
      .insert(service)
      .select();

    return response.data?.at(0) as any as ServiceInterface;
  },
);

export const updateServiceThunk = createAsyncThunk(
  "service/updateService",
  async (service: Omit<ServiceInterface, "commerce_id">) => {
    const { id, ...state } = service;

    await SupabaseApi.supabase.from("service").update(state).eq("id", id);

    return service;
  },
);

export const removeServiceThunk = createAsyncThunk(
  "services/removeService",
  async (serviceId: string) => {
    await SupabaseApi.supabase
      .from("capability")
      .delete()
      .eq("service_id", serviceId);

    await SupabaseApi.supabase.from("service").delete().eq("id", serviceId);

    return serviceId;
  },
);
