import Calendar from "react-calendar";
import { useSelector } from "react-redux";
import { AppointmentStatus } from "../../core/interfaces";
import { selectAppointments } from "../../store/appointment/selectors";

export const CalendarComponent = ({
  setDate,
}: {
  setDate?: (date: Date) => void;
}) => {
  const appointments = useSelector(selectAppointments);

  return (
    <div className="flex flex-col items-center">
      <style>
        {`
          .react-calendar__navigation {
            display: flex;
            padding: 5px 0px;
            margin-top: 5px;
            border-radius: 0.55rem 0.55rem 0 0;
            background: white;
            gap: 15px;
            justify-content: space-between;
            align-items: center;
          }

          .react-calendar__navigation__label {
            font-weight: 500;
          }

          .react-calendar__navigation__arrow {
            font-size: 24px;
            width: 30px;
            height: 30px;
            margin-bottom: 10px;
          }

          .react-calendar__month-view {
            margin-bottom: 10px;
          }

          .react-calendar__month-view__weekdays {
            padding: 10px 0px;
            user-select: none;
          }

          .react-calendar__month-view__weekdays__weekday {
            font-size: 10px;
            text-transform: uppercase;
            text-align: center;
          }

          .react-calendar__month-view__weekdays__weekday abbr {
            text-decoration: none;
          }

          .react-calendar__month-view__days__day {
            padding: 8px 0px;
            font-size: 0.875rem;
            font-weight: 500;
          }

          .react-calendar__month-view__days__day--neighboringMonth {
            color: rgb(156 163 175);
          }

          .react-calendar__tile--now {
            background: rgb(165 180 252);
            color: white;
            font-weight: 700;
            border-radius: 9999px;
          }
        `}
      </style>

      <Calendar
        className="w-72"
        onClickDay={setDate}
        tileContent={({ view, date }) => {
          if (view !== "month") return null;

          const dayAppointments = appointments.filter((appointment) => {
            const ad = new Date(appointment.scheduled_start_at);

            return (
              ad.getDate() === date.getDate() &&
              ad.getMonth() === date.getMonth() &&
              ad.getFullYear() === date.getFullYear()
            );
          }).filter((a) => {
            return a.status === AppointmentStatus.confirmed
          });

          if (dayAppointments.length === 0) return null;

          return (
            <div className="relative">
              <div className="bg-indigo-300 w-2 h-2 rounded-full absolute z-20 left-[17px]" />
            </div>
          );
        }}
      />
    </div>
  );
};
