import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommerceSelector } from "../../../../../components/commerce-selector/commerce-selector";
import { Modal } from "../../../../../components/modal/modal";
import { countries } from "../../../../../core/constants/countries";
import { serviceColors } from "../../../../../core/constants/service-colors";
import { selectCommerce, selectCommerceId } from "../../../../../store/commerce/selectors";
import { addServiceThunk } from "../../../../../store/service/thunks";
import { AppDispatch } from "../../../../../store/store";

export const AddServiceModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();

  const commerceId = useSelector(selectCommerceId);
  const commerce = useSelector(selectCommerce);

  const countryCurrency = countries.find(({ name }) => {
    return name === commerce?.country;
  })?.currencyCode;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [hour, setHour] = useState<number>();
  const [minute, setMinute] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [currency, setCurrency] = useState(countryCurrency ?? "");

  return (
    <Modal title="Add service" onClose={onClose}>
      <div className="w-full p-8 flex flex-col gap-2">
        <TextField
          label="Name *"
          size="small"
          sx={{
            width: "100%",
          }}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <TextField
          multiline
          label="Description *"
          size="small"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />

        <div>
          <p className="text-xs text-gray-600 mb-2">Service duration *</p>

          <div className="flex gap-2">
            <TextField
              label="Hours"
              type="number"
              size="small"
              value={hour}
              onChange={(e) => {
                setHour(+e.target.value);
              }}
            />

            <TextField
              label="Minutes"
              type="number"
              size="small"
              value={minute}
              onChange={(e) => {
                setMinute(+e.target.value);
              }}
            />
          </div>
        </div>

        <TextField
          label="Price *"
          type="number"
          size="small"
          value={price}
          onChange={(e) => {
            setPrice(+e.target.value);
          }}
        />

        <CommerceSelector
          placeholder="Currency *"
          options={[
            ...new Set(countries.map(({ currencyCode }) => currencyCode)),
          ]
            .filter((v) => !!v)
            .sort()}
          value={currency}
          setValue={(value) => {
            setCurrency(value);
          }}
        />

        <p className="text-xs text-gray-600 py-1">
          * These fields are mandatory
        </p>

        <Button
          variant="contained"
          sx={{
            width: "100%",
            background: "oklch(30% 0 0)",
            "&:hover": {
              background: "oklch(40% 0 0)",
            },
          }}
          disabled={
            !name || !description || (!hour && !minute) || !price || !currency
          }
          onClick={() => {
            commerceId &&
              dispatch(
                addServiceThunk({
                  commerce_id: commerceId,
                  name,
                  description,
                  duration: (hour ?? 0) * 60 + (minute ?? 0) || undefined,
                  price,
                  currency,
                  color:
                    serviceColors[
                      Math.floor(Math.random() * serviceColors.length)
                    ],
                }),
              )
                .unwrap()
                .then(() => onClose());
          }}
        >
          SAVE
        </Button>
      </div>
    </Modal>
  );
};
