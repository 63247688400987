import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupabaseApi } from "../../api/supabase";
import { CapabilityInterface } from "./interfaces";

export const getCapabilitiesThunk = createAsyncThunk(
  "capabilities/getCapabilities",
  async (commerceId: string) => {
    const response = await SupabaseApi.supabase
      .from("capability")
      .select()
      .eq("commerce_id", commerceId);

    return response.data as any as CapabilityInterface[];
  },
);

export const addCapabilitiesThunk = createAsyncThunk(
  "capabilities/addCapabilities",
  async (capability: Omit<CapabilityInterface, "id">) => {
    const response = await SupabaseApi.supabase
      .from("capability")
      .insert(capability)
      .select();

    return response.data?.at(0) as any as CapabilityInterface;
  },
);

export const updateCapabilityThunk = createAsyncThunk(
  "capabilities/updateCapabilities",
  async (capability: Omit<CapabilityInterface, "commerce_id">) => {
    const { id, ...state } = capability;

    await SupabaseApi.supabase.from("capability").update(state).eq("id", id);

    return capability;
  },
);

export const removeCapabilityThunk = createAsyncThunk(
  "capabilities/removeCapability",
  async (id: string) => {
    await SupabaseApi.supabase.from("capability").delete().eq("id", id);

    return id;
  },
);

export const removeCapabilitiesThunk = createAsyncThunk(
  "capabilities/removeCapabilities",
  async (ids: string[]) => {
    await SupabaseApi.supabase.from("capability").delete().in("id", ids);

    return ids;
  },
);
