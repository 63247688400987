import { Send } from "@mui/icons-material";
import { useState } from "react";
import { useLangParam } from "../../core/hooks";
import { ChooseCustomer } from "./choose-customer";
import { translations } from "../../core/constants/translations";

export const ChatInput = ({
  onSubmit,
}: {
  onSubmit: (message: string) => void;
}) => {
  const [input, setInput] = useState("");
  const lang = useLangParam();
  const translation = translations["Demo"]["Chat"];

  return (
    <div
      className="flex gap-2 items-center justify-between"
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onSubmit(input);
          setInput("");
        }
      }}
    >
      <ChooseCustomer />

      <input
        type="text"
        placeholder={translation["Placeholder"][lang]}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        className="grow p-3 px-5 rounded-full border-none focus:outline-0"
      />

      <InputButton
        onClick={() => {
          onSubmit(input);
          setInput("");
        }}
      />
    </div>
  );
};

const InputButton = ({ onClick }: { onClick: () => void }) => {
  const [hover, setHover] = useState(false);

  return (
    <button
      onClick={onClick}
      className="bg-indigo-600 rounded-full hover:indigo-500"
      style={{
        width: "40px",
        height: "40px",
        userSelect: "none",
        cursor: "pointer",
        transform: hover ? "scale(1.2)" : "scale(1.0)",
        transition: "transform 0.1s linear",
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Send
        style={{
          color: "white",
          width: "25px",
          height: "25px",
          marginLeft: "4px",
          marginBottom: "1px",
        }}
      />
    </button>
  );
};
