import { useSearchParams } from "react-router-dom";

export const useLangParam = () => {
  const [searchParams] = useSearchParams();

  const localLang = navigator.language.split("-").at(0)

  const lang = searchParams.get("lang") ?? localLang ?? "en";

  if (!["en", "es", "pt"].includes(lang)) {
    return "en";
  }

  return lang as "en" | "es" | "pt";
};
