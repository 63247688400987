import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupabaseApi } from "../../api/supabase";
import type { ResourceTimeframeInterface } from "./interfaces";

const fromUTC = (time: string) => {
  const [hour, minutes, seconds] = time.split(":");

  const validatedTime = `${(hour || "00").padStart(2, "0")}:${minutes || "00"}:${seconds || "00"}`;

  const wrappedTime = new Date(`1970-01-01T${validatedTime}Z`).getTime();
  const offset = new Date().getTimezoneOffset();
  const newTime = new Date(wrappedTime - offset * 60 * 1000);

  return `${newTime.getUTCHours().toString().padStart(2, "0")}:${newTime.getUTCMinutes().toString().padStart(2, "0")}:${newTime.getUTCSeconds().toString().padStart(2, "0")}`;
}

const toUTC = (time: string) => {
  const [hour, minutes, seconds] = time.split(":");

  const validatedTime = `${(hour || "00").padStart(2, "0")}:${minutes || "00"}:${seconds || "00"}`;

  const wrappedTime = new Date(`1970-01-01T${validatedTime}Z`).getTime();
  const offset = new Date().getTimezoneOffset();
  const newTime = new Date(wrappedTime + offset * 60 * 1000);

  return `${newTime.getUTCHours().toString().padStart(2, "0")}:${newTime.getUTCMinutes().toString().padStart(2, "0")}:${newTime.getUTCSeconds().toString().padStart(2, "0")}`;
}

const fromUTCTimeframe = ({ start, ...frame }: Partial<ResourceTimeframeInterface>) => {
  return {
    ...frame,
    start: start && fromUTC(start),
  } as ResourceTimeframeInterface;
}

const toUTCTimeframe = ({ start, ...frame }: Partial<ResourceTimeframeInterface>) => {
  return {
    ...frame,
    start: start && toUTC(start),
  } as ResourceTimeframeInterface;
}

export const getResourceTimeframesThunk = createAsyncThunk(
  "resourceTimeframe/getTimeframes",
  async (resourceIds: string[]) => {
    const response = await SupabaseApi.supabase
      .from("timeframe")
      .select()
      .in("resource_id", resourceIds);

    const UTC = response.data as any as ResourceTimeframeInterface[];

    return UTC.map(fromUTCTimeframe);
  },
);

export const addResourceTimeframesThunk = createAsyncThunk(
  "resourceTimeframe/addTimeframes",
  async (timeframe: Omit<ResourceTimeframeInterface, "id">) => {
    const response = await SupabaseApi.supabase
      .from("timeframe")
      .insert({ ...toUTCTimeframe(timeframe) })
      .select();

    const UTC = response.data?.at(0) as any as ResourceTimeframeInterface;

    return fromUTCTimeframe(UTC);
  },
);

export const updateResourceTimeframesThunk = createAsyncThunk(
  "resourceTimeframe/updateTimeframes",
  async (timeframe: Partial<ResourceTimeframeInterface>) => {
    const { id, ...state } = timeframe;

    if (!id) return;

    await SupabaseApi.supabase
      .from("timeframe")
      .update({ ...toUTCTimeframe(state) })
      .eq("id", id);

    return timeframe;
  },
);

export const removeResourceTimeframeThunk = createAsyncThunk(
  "resourceTimeframe/removeTimeframe",
  async (timeframeId: string) => {
    await SupabaseApi.supabase.from("timeframe").delete().eq("id", timeframeId);

    return timeframeId;
  },
);

export const removeResourceTimeframesThunk = createAsyncThunk(
  "resourceTimeframe/removeTimeframes",
  async (timeframeIds: string[]) => {
    await SupabaseApi.supabase
      .from("timeframe")
      .delete()
      .in("id", timeframeIds);

    return timeframeIds;
  },
);
