import { CheckOutlined } from "@mui/icons-material";
import { useState } from "react";
import { ServiceInterface } from "../../../../store/service/interfaces";

export const ServiceAssociationItem = ({
  service,
  index,
  last,
  isChecked,
  onClick,
}: {
  service: ServiceInterface;
  index: number;
  last: boolean;
  isChecked: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className={`
        grid grid-cols-[20px_2fr_1fr_1fr] gap-4 items-center py-3
        ${!last ? "border-b border-gray-300" : undefined}
      `}
    >
      <Checkbox isChecked={isChecked} onClick={onClick} />

      <p className="">{service.name ?? `Service #${index + 1}`}</p>

      <p className="text-xs text-right">{service.duration} min</p>

      <p className="font-medium text-right">
        {service.price} {service.currency}
      </p>
    </div>
  );
};

const Checkbox = ({
  isChecked,
  onClick,
}: {
  isChecked: boolean;
  onClick: () => void;
}) => {
  const bg = isChecked
    ? "bg-cyan-300 hover:bg-cyan-200"
    : "bg-white hover:bg-gray-100";

  return (
    <div
      className={`
        flex items-center justify-center
        ${isChecked ? undefined : "border border-gray-300"}
        ${bg} rounded-md w-5 h-5
        cursor-pointer
      `}
      onClick={() => {
        onClick();
      }}
    >
      {isChecked && (
        <CheckOutlined
          sx={{
            color: "white",
            height: "1.25rem",
            width: "1.25rem",
          }}
        />
      )}
    </div>
  );
};
