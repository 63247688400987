import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../store";

export const selectCustomerLinksState = createSelector(
  [selectState],
  (state) => state.customerLinks
)

export const selectCustomerLinks = createSelector(
  [selectCustomerLinksState],
  (state) => state.value
)

export const selectCustomerLinksStatus = createSelector(
  [selectCustomerLinksState],
  (state) => state.status
)
