import { useNavigate } from "react-router-dom";

export function SignInBtn() {
  const navigate = useNavigate();
  return (
    <p
      className="cursor-pointer hover:bg-slate-300 px-5 py-2 rounded-full"
      onClick={() => {
        navigate("/login");
      }}
    >
      Sign in
    </p>
  )
}