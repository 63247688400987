export class Time {
  constructor(private time: string) {}

  get hours() {
    const h = this.time.split(":").at(0);
    if (h === undefined) return undefined;
    return parseInt(h);
  }

  get minutes() {
    const m = this.time.split(":").at(1);
    if (m === undefined) return undefined;
    return parseInt(m);
  }

  get str() {
    return `${this.hours}:${this.minutes?.toString().padStart(2, "0")}`;
  }

  addMinutes(minToAdd: number) {
    const h = this.hours;
    const m = this.minutes;

    if (h === undefined || m === undefined) return;

    const maxMinutes = 24 * 60;
    const allMinutes = h * 60 + m;

    const newTime = (allMinutes + minToAdd) % maxMinutes;

    return Time.fromMinutes(newTime);
  }

  toMinutes() {
    const h = this.hours;
    const m = this.minutes;

    if (h === undefined || m === undefined) return;

    return h * 60 + m;
  }

  static fromMinutes(min: number) {
    return new Time(`${Math.floor(min / 60)}:${min % 60}`);
  }

  lte(time: string) {
    const sm = this.toMinutes();
    const tm = new Time(time).toMinutes();

    if (tm === undefined || sm === undefined) return false;

    return sm <= tm;
  }

  gte(time: string) {
    const sm = this.toMinutes();
    const tm = new Time(time).toMinutes();

    if (tm === undefined || sm === undefined) return false;

    return sm >= tm;
  }

  lt(time: string) {
    const sm = this.toMinutes();
    const tm = new Time(time).toMinutes();

    if (tm === undefined || sm === undefined) return false;

    return sm < tm;
  }

  gt(time: string) {
    const sm = this.toMinutes();
    const tm = new Time(time).toMinutes();

    if (tm === undefined || sm === undefined) return false;

    return sm > tm;
  }
}
