import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../store";

export const selectMessages = createSelector([selectState], (state) => {
  return state.messages.value;
});

export const selectMessagesStatus = createSelector([selectState], (state) => {
  return state.messages.status;
});

export const selectMessagesCurrentlyLoading = createSelector([selectState], (state) => {
  return state.messages.currentlyLoading;
});
