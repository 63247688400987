export const SwitchCheckbox = ({
  value,
  setValue,
}: {
  value: boolean;
  setValue: (v: boolean) => void;
}) => {
  return (
    <label className="flex cursor-pointer select-none items-center">
      <div className="relative">
        <input
          type="checkbox"
          checked={value}
          onChange={() => {
            setValue(!value);
          }}
          className="sr-only"
        />

        <div
          className={`box block h-6 w-11 rounded-full ${value ? "bg-purple-300" : "bg-gray-300"}`}
        />

        <div
          className={`absolute left-0.5 top-0.5 flex h-5 w-5 items-center justify-center rounded-full bg-white transition ${value ? "translate-x-full" : ""}`}
        />
      </div>
    </label>
  );
};
