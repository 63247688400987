import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupabaseApi } from "../../api/supabase";
import type { CustomerInterface } from "./interfaces";

export const getCustomerThunk = createAsyncThunk(
  "customer/getCustomer",
  async (id: string, { rejectWithValue }) => {
    const response = await SupabaseApi.supabase
      .from("customer")
      .select("*")
      .eq("id", id);

    if (response.error) {
      return rejectWithValue(response.error.message);
    }

    return response.data?.at(0) as any as CustomerInterface;
  },
);

export const getCustomersThunk = createAsyncThunk(
  "customer/getCustomers",
  async (id: string[]) => {
    const response = await SupabaseApi.supabase
      .from("customer")
      .select("*")
      .in("id", id);

    return response.data as any as CustomerInterface[];
  },
);

export const addCustomerThunk = createAsyncThunk(
  "customer/addCustomer",
  async (customer?: Omit<CustomerInterface, "id">) => {
    const response = await SupabaseApi.supabase
      .from("customer")
      .insert(customer ?? {})
      .select();

    return response.data?.at(0) as any as CustomerInterface;
  },
);
