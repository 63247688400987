import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { translations } from "../../core/constants/translations";
import { useLangParam } from "../../core/hooks";
import { AppDispatch } from "../../store/store";
import { logoutThunk } from "../../store/user/thunks";

export const LogoutBtn = () => {
    const dispatch = useDispatch<AppDispatch>();
    const lang = useLangParam();

    const translation = translations["Demo"]["CTA"];

    const navigate = useNavigate();
  
    return (
      <p
        className="cursor-pointer hover:bg-slate-300 px-5 py-2 rounded-full"
        onClick={() => {
          dispatch(logoutThunk())
            .unwrap()
            .then(() => {
              navigate("/login");
              window.location.reload();
            });
        }}
      >
        {translation["Logout"][lang]}
      </p>
    );
  };
