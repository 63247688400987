import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../store";

export const selectUserState = createSelector(
  [selectState],
  (state) => state.user,
);

export const selectUser = createSelector(
  [selectUserState],
  (state) => state.value,
);

export const selectUserErrors = createSelector(
  [selectUserState],
  (state) => state.error,
);

export const selectUserErrorCode = createSelector(
  [selectUserState],
  (state) => state.errorCode,
);

export const selectUserStatus = createSelector(
  [selectUserState],
  (state) => state.status,
);

export const selectUserId = createSelector([selectUser], (value) => value?.id);
