const liveStreamMessagesI18nData = {
  first_name: {
    es: [
      "Se ha detectado el 'nombre' del cliente: [Nombre]",
      "Nombre del cliente identificado: [Nombre]",
      "El cliente ha mencionado su nombre: [Nombre]",
    ],
    en: [
      "The client's 'name' has been detected: [Name]",
      "Client's name identified: [Name]",
      "The client mentioned their name: [Name]",
    ],
    pt: [
      "Detectado o 'nome' do cliente: [Nome]",
      "Nome do cliente identificado: [Nome]",
      "O cliente mencionou seu nome: [Nome]",
    ],
  },
  last_name: {
    es: [
      "Se ha detectado el 'apellido' del cliente: [Apellido]",
      "Apellido del cliente identificado: [Apellido]",
      "El cliente ha mencionado su apellido: [Apellido]",
    ],
    en: [
      "The client's 'surname' has been detected: [Surname]",
      "Client's surname identified: [Surname]",
      "The client mentioned their surname: [Surname]",
    ],
    pt: [
      "Detectado o 'sobrenome' do cliente: [Sobrenome]",
      "Sobrenome do cliente identificado: [Sobrenome]",
      "O cliente mencionou seu sobrenome: [Sobrenome]",
    ],
  },
  phone: {
    es: [
      "Se ha detectado el 'telefono' del cliente: [telefono]",
      "Telefono del cliente identificado: [telefono]",
      "El cliente ha mencionado su telefono: [telefono]",
    ],
    en: [
      "The client's 'phone' has been detected: [phone]",
      "Client's phone identified: [phone]",
      "The client mentioned their phone: [phone]",
    ],
    pt: [
      "Detectado o 'telefone' do cliente: [telefone]",
      "Telefone do cliente identificado: [telefone]",
      "O cliente mencionou seu telefone: [telefone]",
    ],
  },
  dni: {
    es: [
      "Se ha detectado el 'DNI' del cliente: [DNI]",
      "DNI del cliente identificado: [DNI]",
      "El cliente ha proporcionado su DNI: [DNI]",
    ],
    en: [
      "The client's 'DNI' has been detected: [DNI]",
      "Client's DNI identified: [DNI]",
      "The client provided their DNI: [DNI]",
    ],
    pt: [
      "Detectado o 'DNI' do cliente: [DNI]",
      "DNI do cliente identificado: [DNI]",
      "O cliente forneceu seu DNI: [DNI]",
    ],
  },
  email: {
    es: [
      "Se ha detectado el 'email' del cliente: [DNI]",
      "email del cliente identificado: [email]",
      "El cliente ha proporcionado su email: [email]",
    ],
    en: [
      "The client's 'email' has been detected: [email]",
      "Client's email identified: [email]",
      "The client provided their DNI: [email]",
    ],
    pt: [
      "Detectado o 'email' do cliente: [email]",
      "Email do cliente identificado: [email]",
      "O cliente forneceu seu email: [email]",
    ],
  },
  service: {
    es: [
      "Se ha detectado el 'servicio escogido' por el cliente: [Servicio escogido]",
      "Servicio seleccionado por el cliente: [Servicio escogido]",
      "El cliente ha indicado su servicio preferido: [Servicio escogido]",
    ],
    en: [
      "The client's 'chosen service' has been detected: [Chosen service]",
      "Service chosen by the client: [Chosen service]",
      "The client indicated their preferred service: [Chosen service]",
    ],
    pt: [
      "Detectado o 'serviço escolhido' pelo cliente: [Serviço escolhido]",
      "Serviço escolhido pelo cliente: [Serviço escolhido]",
      "O cliente indicou seu serviço preferido: [Serviço escolhido]",
    ],
  },
  date: {
    es: [
      "Se ha detectado la 'fecha' del servicio: [Fecha]",
      "Fecha del servicio identificada: [Fecha]",
      "El cliente ha indicado la fecha: [Fecha]",
    ],
    en: [
      "The 'service date' has been detected: [Date]",
      "Service date identified: [Date]",
      "The client chose the service date: [Date]",
    ],
    pt: [
      "Detectada a 'data' do serviço: [Data]",
      "Data do serviço identificada: [Data]",
      "O cliente escolheu a data do serviço: [Data]",
    ],
  },
  time: {
    es: [
      "Se ha detectado la 'hora' del servicio: [Hora]",
      "Hora del servicio identificada: [Hora]",
      "El cliente ha indicado la hora: [Hora]",
    ],
    en: [
      "The 'service time' has been detected: [Time]",
      "Service time identified: [Time]",
      "The client chose the service time: [Time]",
    ],
    pt: [
      "Hora do serviço detectado: [Data]",
      "Hora do serviço identificada: [Data]",
      "O cliente escolheu o horário do serviço: [Data]",
    ],
  },
  resource: {
    es: [
      "Se ha detectado el 'recurso' asignado: [Recurso]",
      "Recurso del servicio identificado: [Recurso]",
      "El cliente será atendido por: [Recurso]",
    ],
    en: [
      "The 'assigned resource' has been detected: [Resource]",
      "Service resource identified: [Resource]",
      "The client will be served by: [Resource]",
    ],
    pt: [
      "Detectado o 'recurso' atribuído: [Recurso]",
      "Recurso do serviço identificado: [Recurso]",
      "O cliente será atendido por: [Recurso]",
    ],
  },
};

export const liveStreamMessagesI18n = (() => {
  return Object.entries(liveStreamMessagesI18nData).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        [key]: Object.entries(value).reduce((acc, [vkey, vvalue]) => {
          return {
            ...acc,
            [vkey]: (v: string) => {
              return vvalue.map((t) => {
                return t.replace(/\[.*\]/g, v);
              })[Math.floor(Math.random() * vvalue.length)];
            },
          };
        }, {}),
      };
    },
    {},
  ) as { [key: string]: { [lang: string]: (v: string) => string } };
})();
