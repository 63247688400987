import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../store";

export const selectAppointmentsState = createSelector(
  [selectState],
  (state) => state.appointments,
);

export const selectAppointmentsStatus = createSelector(
  [selectAppointmentsState],
  (state) => state.status,
);

export const selectAppointments = createSelector(
  [selectAppointmentsState],
  (state) => state.value,
);
