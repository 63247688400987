import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../store";

export const selectResourceState = createSelector(
  [selectState],
  (state) => state.resources,
);

export const selectResourcesStatus = createSelector(
  [selectResourceState],
  (state) => state.status,
);

export const selectResourcesLoadingItem = createSelector(
  [selectResourceState],
  (state) => state.loadingItem,
);

export const selectResources = createSelector(
  [selectResourceState],
  (state) => state.value,
);
