import { createSlice } from "@reduxjs/toolkit";
import { defaultCases, initListState } from "../utils";
import type { ServiceInterface } from "./interfaces";
import {
  addServiceThunk,
  getServicesThunk,
  removeServiceThunk,
  updateServiceThunk,
} from "./thunks";

const serviceSlice = createSlice({
  name: "service",
  initialState: initListState<ServiceInterface>(),
  reducers: {},
  extraReducers: (builder) => {
    defaultCases(builder, getServicesThunk).addCase(
      getServicesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = action.payload;
      },
    );

    defaultCases(builder, addServiceThunk).addCase(
      addServiceThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value.push(action.payload);
      },
    );

    defaultCases(builder, updateServiceThunk).addCase(
      updateServiceThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = state.value.map((s) => {
          if (s.id === action.payload.id) {
            return {
              ...s,
              ...action.payload,
            };
          }
          return s;
        });
      },
    );

    defaultCases(builder, removeServiceThunk).addCase(
      removeServiceThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = state.value.filter(({ id }) => action.payload !== id);
      },
    );
  },
});

export const serviceReducer = serviceSlice.reducer;
