import { useEffect, useRef } from "react";

export const useHandleClickOutside = <R extends HTMLElement>(f: () => void) => {
  const ref = useRef<R>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        f();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return ref;
};
