import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../store";

export const selectServiceState = createSelector(
  [selectState],
  (state) => state.services,
);

export const selectServiceStatus = createSelector(
  [selectServiceState],
  (state) => state.status,
);

export const selectServices = createSelector(
  [selectServiceState],
  (state) => state.value,
);
