import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../store";

export const selectTimeframeState = createSelector(
  [selectState],
  (state) => state.timeframes,
);

export const selectTimeframeStatus = createSelector(
  [selectTimeframeState],
  (state) => state.status,
);

export const selectTimeframes = createSelector(
  [selectTimeframeState],
  (state) => state.value,
);
