import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Popper,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { countries } from "../../core/constants/countries";
import { translations } from "../../core/constants/translations";
import { useLangParam } from "../../core/hooks";
import { createCommerceThunk } from "../../store/commerce/thunks";
import { AppDispatch } from "../../store/store";
import { selectUserErrors } from "../../store/user/selectors";
import { registerThunk } from "../../store/user/thunks";
import { setUserError } from "../../store/user/user";

export const RegisterPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();

  const translation = translations["Get Started"]["Register"];

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const skipOnboarding = searchParams.get("skip-onboarding");

  const error = useSelector(selectUserErrors);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [tos, setTos] = useState(false);

  const onSubmit = () => {
    dispatch(
      registerThunk({
        email,
        password,
        options: {
          data: {
            phone,
            first_name: firstName,
            last_name: lastName,
          },
        },
      }),
    )
      .unwrap()
      .then(({ id }) => {
        if (skipOnboarding === "true") {
          dispatch(
            createCommerceThunk({
              userId: id,
            }),
          )
            .unwrap()
            .then(() => {
              navigate("/demo");
            });
        } else {
          navigate("/onboarding");
        }
      });
  };

  return (
    <div className="bg-slate-200 flex items-center justify-center w-screen h-screen">
      <div className="bg-white w-96 h-[32rem] rounded-lg flex flex-col items-center p-16 gap-3 justify-center">
        <p className="text-2xl font-bold mb-8">{translation[lang]}</p>

        <div className="flex gap-2">
          <TextField
            label={translation["First Name"][lang]}
            variant="outlined"
            value={firstName}
            size="small"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />

          <TextField
            label={translation["Last Name"][lang]}
            variant="outlined"
            value={lastName}
            size="small"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>

        <TextField
          label={translation["Email"][lang]}
          variant="outlined"
          value={email}
          size="small"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          sx={{
            width: "100%",
          }}
        />

        <div className="flex gap-2">
          <Autocomplete
            size="small"
            disableClearable
            onChange={(_, value) => {
              setCode(`+${value.phone}`);
            }}
            options={countries.map((c) => ({ ...c, label: c.label["en"] }))}
            getOptionLabel={(item) => `+${item.phone}`}
            PopperComponent={(props) => {
              return (
                <Popper
                  sx={{
                    width: "360px !important",
                  }}
                  {...props}
                />
              );
            }}
            getOptionKey={(option) => {
              return `${option.name} ${option.code} ${option.label}`;
            }}
            renderInput={(params) => {
              return <TextField
                {...params}
                label={translation["Code"][lang]}
              />;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <p>
                    {option.emoji} {option.name} (+{option.phone})
                  </p>
                </li>
              );
            }}
            filterOptions={(options, { inputValue }) => {
              console.log(options, inputValue);
              return options.filter((option) => {
                return `${option.name} (+${option.phone})`
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              });
            }}
          />

          <TextField
            label={translation["Phone"][lang]}
            variant="outlined"
            value={phone}
            size="small"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </div>

        <TextField
          label={translation["Password"][lang]}
          variant="outlined"
          type={showPassword ? "text" : "password"}
          value={password}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((v) => !v)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              paddingRight: "0px",
            },
          }}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          sx={{
            width: "100%",
          }}
        />

        <div className="flex items-start">
          <Checkbox
            value={tos}
            onClick={() => {
              setTos((v) => !v);
            }}
          />

          <p className="text-xs">
            <style>
              {`
                .terms {
                  color: rgb(107 114 128);
                  text-decoration-line: underline;
                }

                .policy {
                  color: rgb(107 114 128);
                  text-decoration-line: underline;
                }
              `}
            </style>

            <div dangerouslySetInnerHTML={{ __html: translation["Acceptance"][lang]
                .replace(/\[(.*?)\]/, `<a class="terms" href="https://www.magneticbunny.com/terms-of-service/">$1</a>`)
                .replace(/\[(.*?)\]/, `<a class="policy" href="https://www.magneticbunny.com/privacy-policy/" >$1</a>`)
            }} />
          </p>
        </div>

        <Button
          variant="contained"
          size="large"
          sx={{
            width: "100%",
            background: "oklch(30% 0 0)",
            "&:hover": {
              background: "oklch(40% 0 0)",
            },
          }}
          onClick={onSubmit}
          disabled={
            !email ||
            !password ||
            !code ||
            !phone ||
            !firstName ||
            !lastName ||
            !tos
          }
        >
          {translation["Button"][lang]}
        </Button>

        {error && <p className="text-red-600">{error}</p>}

        <p
          className="text-cyan-600 hover:text-cyan-500 cursor-pointer self-start ml-1 text-xs"
          onClick={() => {
            navigate("/login");
            dispatch(setUserError(undefined));
          }}
        >
          {translation["Singin"][lang]}
        </p>
      </div>
    </div>
  );
};
