import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../../components/modal/modal";
import { addCapabilitiesThunk } from "../../../../../store/capabilities/thunks";
import { selectCommerceId } from "../../../../../store/commerce/selectors";
import { addResourceThunk } from "../../../../../store/resource/thunks";
import { addResourceTimeframesThunk } from "../../../../../store/resource_timeframe/thunks";
import { selectServices } from "../../../../../store/service/selectors";
import { AppDispatch } from "../../../../../store/store";
import { selectTimeframes } from "../../../../../store/timeframe/selectors";
import { ServiceAssociationItem } from "../../../../onboarding/resource-stage/resource-screen/service-association-item";

export const AddResourceModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();

  const services = useSelector(selectServices);
  const commerceId = useSelector(selectCommerceId);
  const timeframes = useSelector(selectTimeframes);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [choosenServices, setChoosenServices] = useState<string[]>([]);

  return (
    <Modal title="Add resource" onClose={onClose}>
      <div className="w-full p-8 flex flex-col gap-2">
        <TextField
          label="Name *"
          size="small"
          sx={{
            width: "100%",
          }}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <TextField
          multiline
          label="Description *"
          size="small"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />

        <p className="text-xs text-gray-600 pt-3">
          Which services does this resource offer?
        </p>

        <div className="flex flex-col">
          {services.map((service, i, list) => {
            const isChoosen = choosenServices.includes(service.id);
            return (
              <ServiceAssociationItem
                service={service}
                index={i}
                last={list.length - 1 === i}
                isChecked={isChoosen}
                onClick={() => {
                  if (!isChoosen) {
                    setChoosenServices([...choosenServices, service.id]);
                  } else {
                    setChoosenServices(
                      choosenServices.filter((s) => s !== service.id),
                    );
                  }
                }}
              />
            );
          })}
        </div>

        <p className="text-xs text-gray-600 pb-3">
          * These fields are mandatory
        </p>

        <Button
          variant="contained"
          sx={{
            width: "100%",
            background: "oklch(30% 0 0)",
            "&:hover": {
              background: "oklch(40% 0 0)",
            },
          }}
          disabled={!name || !description}
          onClick={() => {
            commerceId &&
              dispatch(
                addResourceThunk({
                  name,
                  description,
                  commerce_id: commerceId,
                }),
              )
                .unwrap()
                .then(async ({ id }) => {
                  timeframes.forEach(({ day, start, duration }) => {
                    dispatch(
                      addResourceTimeframesThunk({
                        day,
                        start,
                        duration,
                        resource_id: id,
                      }),
                    );
                  });

                  choosenServices.forEach((s) => {
                    dispatch(
                      addCapabilitiesThunk({
                        resource_id: id,
                        service_id: s,
                        commerce_id: commerceId,
                      }),
                    );
                  });

                  onClose();
                });
          }}
        >
          SAVE
        </Button>
      </div>
    </Modal>
  );
};
