import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentsThunk } from "../../store/appointment/thunks";
import { getCapabilitiesThunk } from "../../store/capabilities/thunks";
import { selectCommerceId } from "../../store/commerce/selectors";
import { getCommerceThunk } from "../../store/commerce/thunks";
import { getBusinessTypesThunk } from "../../store/constants/business-type/thunks";
import { getIndustryTypesThunk } from "../../store/constants/industry-type/thunks";
import { selectCustomerLinks } from "../../store/customer-link/selectors";
import { getCustomerLinksThunk } from "../../store/customer-link/thunks";
import { getCustomersThunk } from "../../store/customer/thunks";
import { selectResources } from "../../store/resource/selectors";
import { getResourcesThunk } from "../../store/resource/thunks";
import { getResourceTimeframesThunk } from "../../store/resource_timeframe/thunks";
import { getServicesThunk } from "../../store/service/thunks";
import { AppDispatch } from "../../store/store";
import { getTimeframesThunk } from "../../store/timeframe/thunks";
import { selectUser } from "../../store/user/selectors";

export const useLoader = () => {
  useLoadConstants();
  useLoadCommerce();
};

export const useLoadCommerce = () => {
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector(selectUser);
  const resources = useSelector(selectResources);
  const resourceIds = resources.map((r) => r.id);
  const commerceId = useSelector(selectCommerceId);

  const [runonce, setRunonce] = useState(true);

  useEffect(() => {
    user?.id &&
      runonce &&
      dispatch(getCommerceThunk(user.id))
        .unwrap()
        .then((commerce) => {
          setRunonce(false)
        });
  }, [dispatch, user, runonce]);

  useEffect(() => {
    commerceId && dispatch(getServicesThunk(commerceId));
  }, [dispatch, commerceId]);

  useEffect(() => {
    commerceId && dispatch(getResourcesThunk(commerceId));
  }, [dispatch, commerceId]);

  useEffect(() => {
    commerceId && dispatch(getTimeframesThunk(commerceId));
  }, [dispatch, commerceId]);

  useEffect(() => {
    if (resourceIds.length > 0) {
      dispatch(getResourceTimeframesThunk(resourceIds));
    }
  }, [dispatch, resourceIds]);

  useEffect(() => {
    commerceId && dispatch(getAppointmentsThunk(commerceId));
  }, [dispatch, commerceId]);

  useEffect(() => {
    commerceId && dispatch(getCapabilitiesThunk(commerceId));
  }, [dispatch, commerceId]);
  
  useEffect(() => {
    commerceId && dispatch(getCustomerLinksThunk(commerceId));
  }, [dispatch, commerceId]);

  const customerLinks = useSelector(selectCustomerLinks);

  useEffect(() => {
    commerceId && dispatch(getCustomersThunk(customerLinks.map(({ customer_id }) => customer_id)));
  }, [dispatch, commerceId, customerLinks]);
};

export const useLoadConstants = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getIndustryTypesThunk());
    dispatch(getBusinessTypesThunk());
  }, [dispatch]);
};
