import { useDispatch } from "react-redux";
import { serviceColors } from "../../../../../core/constants/service-colors";
import { ServiceInterface } from "../../../../../store/service/interfaces";
import { updateServiceThunk } from "../../../../../store/service/thunks";
import { AppDispatch } from "../../../../../store/store";

export const ColorDropdown = ({
  onClose,
  service,
}: {
  service: ServiceInterface;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="relative h-0 w-0">
      <div
        className="absolute w-56 p-3 bg-white top-8 -right-12 z-10 rounded-lg shadow-md"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex gap-1 flex-wrap">
          {serviceColors.map((color) => {
            return (
              <div
                className={`${color} h-6 w-6 rounded-full border border-white hover:border-gray-600`}
                onClick={() => {
                  dispatch(
                    updateServiceThunk({
                      ...service,
                      color,
                    }),
                  );

                  onClose();
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
