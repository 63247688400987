import { translations } from "../../core/constants/translations";
import { useLangParam } from "../../core/hooks"

export function UpgradeBtn() {
  const lang = useLangParam();

  const translation = translations["Demo"]["CTA"];

  return (
    <p className="cursor-pointer text-white bg-indigo-600 hover:bg-indigo-500 px-5 py-2 rounded-full">
      {translation["Upgrade"][lang]}
    </p>
  )
}
