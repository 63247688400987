import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import type { MessageInterface } from "./interfaces";

export const getResponseThunk = createAsyncThunk(
  "messages/getResponse",
  async ({
    text,
    customerId,
    commerceId,
  }: {
    text: string;
    customerId: string;
    commerceId: string;
  }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_CHANNELS_URL}/messages`,
      {
        message: {
          from: customerId,
          to: commerceId,
          body: {
            text: [text],
          },
        },
      },
    );

    if (!(response.data as any)?.message) {
      return null
    }

    return {
      body: (response.data as any).message.body.text,
      sender: "bot" as MessageInterface["sender"],
      customerId,
      commerceId,
    };
  },
);

export const getMessages = createAsyncThunk(
  "messages/getMessages",
  async ({
    customerId,
    commerceId,
  }: {
    customerId: string;
    commerceId: string;
  }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_CHANNELS_URL}/messages/chats/last`,
      {
        params: {
          customer_id: customerId,
          commerce_id: commerceId,
        }
      },
    );

    return (response.data as any[])
      .map(({ body, ...acc }) => ({ ...acc, customerId: acc.customer_id, commerceId: acc.commerce_id, body: [body] })) as MessageInterface[];
  },
);

export const startNewConversationThunk = createAsyncThunk(
  "messages/startNewConversation",
  async ({
    customerId,
    commerceId,
  }: {
    customerId: string;
    commerceId: string;
  }) => {
    await axios.post(
      `${process.env.REACT_APP_CHANNELS_URL}/messages/end-conversation`,
      {
        customer_id: customerId,
        commerce_id: commerceId,
      },
    );
  },
);
