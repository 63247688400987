import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../store";

export const selectCommerceState = createSelector(
  [selectState],
  (state) => state.commerce,
);

export const selectCommerce = createSelector(
  [selectCommerceState],
  (state) => state.value,
);

export const selectCommerceStatus = createSelector(
  [selectCommerceState],
  (state) => state.status,
);

export const selectCommerceId = createSelector(
  [selectCommerce],
  (commerce) => commerce?.id,
);
