import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { SupabaseApi } from "./api/supabase";
import { DemoPage } from "./pages/demo/demo.page";
import { LoginPage } from "./pages/login/login";
import { Onboarding } from "./pages/onboarding/onboarding";
import { RegisterPage } from "./pages/register/register";
import { AppDispatch } from "./store/store";
import { selectUserId, selectUserStatus } from "./store/user/selectors";
import { setUser } from "./store/user/user";

function App() {
  const dispatch = useDispatch<AppDispatch>();

  const userId = useSelector(selectUserId);
  const userStatus = useSelector(selectUserStatus);

  useEffect(() => {
    SupabaseApi.supabase.auth.getSession().then(({ data: { session } }) => {
      dispatch(setUser(session?.user));
    });

    SupabaseApi.supabase.auth.onAuthStateChange((_, session) => {
      dispatch(setUser(session?.user));
    });
  }, [dispatch]);


  return (
    <>
      <Helmet>
        <title>
         Magnetic Bunny - CRM and AI Agents for Sales on WhatsApp and Social Networks
        </title>
      </Helmet>

      <Routes>
        <Route path="/" element={userStatus === "fulfilled" ? <Navigate to={!userId? "/login" : "/demo"} /> : <></>} />
        <Route path="/demo" element={<DemoPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/onboarding" element={<Onboarding />} />
      </Routes>
    </>
  );
}

export default App;
