import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../store";

export const selectCapabilitiesState = createSelector(
  [selectState],
  (state) => state.capabilities,
);

export const selectCapabilitiesStatus = createSelector(
  [selectCapabilitiesState],
  (state) => state.status,
);

export const selectCapabilities = createSelector(
  [selectCapabilitiesState],
  (state) => state.value,
);
