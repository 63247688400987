import { AddOutlined, ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translations } from "../../../core/constants/translations";
import { useLangParam } from "../../../core/hooks";
import { selectResources } from "../../../store/resource/selectors";
import {
  removeResourceThunk,
} from "../../../store/resource/thunks";
import { AppDispatch } from "../../../store/store";
import { ResourceItem } from "./resource-item";
import { ChangeResourceScreen } from "./resource-screen/change-resource-screen";

export const ResourceStage = ({
  onContinue,
  onBack,
}: {
  onContinue: () => void;
  onBack: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();

  const translation = translations["Onboarding"]["Resources"];

  const resources = useSelector(selectResources);

  const [choosenResource, setChoosenResource] = useState<string>();
  const [createResource, setCreateResource] = useState(true);

  const resource = resources.find((r) => r.id === choosenResource);

  return (
    <div className="bg-white min-w-96 h-max rounded-lg flex flex-col items-center">
      <div className="border-b border-gray-300 w-full flex items-center justify-center py-6">
        <div className="relative w-0 -left-64">
          <div
            className="cursor-pointer"
            onClick={() => {
              onBack();
            }}
          >
            <ArrowBack />
          </div>
        </div>

        <div className="w-[200px] h-3 overflow-hidden rounded-full bg-gray-100">
          <div className="w-[160px] h-3 rounded-full bg-purple-300" />
        </div>
      </div>

      <p className="text-2xl mt-4 mb-4 font-bold">
        {translation["Start adding resources"][lang]}
      </p>

      <p className="text-sm mb-4 text-gray-500 w-[500px] text-center">
        {translation["Description"][lang]}
      </p>

      <div className="flex">
        <div className="w-96 p-8 flex border-r border-r-gray-300 flex-col">
          {resources.map(({ id, name }, i, list) => {
            return (
              <ResourceItem
                last={list.length - 1 === i}
                name={name ?? `Resource #${i + 1}`}
                onDelete={() => {
                  dispatch(removeResourceThunk(id));
                }}
                onOpen={() => {
                  setChoosenResource(id);
                  setCreateResource(false);
                }}
                choosed={choosenResource === id}
              />
            );
          })}

          <div
            className={`flex gap-4 w-full cursor-pointer py-2 px-2 mt-2 mb-4 rounded-xl hover:bg-gray-50 ${createResource ? "bg-gray-100" : ""}`}
            onClick={() => {
              setCreateResource(true);
              setChoosenResource(undefined);
            }}
          >
            <AddOutlined />

            <p className="">{translation["Add new Resource"][lang]}</p>
          </div>

          <Button
            variant="contained"
            sx={{
              width: "100%",
              background: "rgb(192 132 252)",
              "&:hover": {
                background: "rgb(216 180 254)",
              },
            }}
            onClick={() => {
              onContinue();
            }}
            disabled={resources.length === 0}
          >
            {translation["Continue"][lang]}
          </Button>
        </div>

        <ChangeResourceScreen
          key={resource?.id}
          resource={resource}
          onBack={(id) => {
            setChoosenResource(id);
            setCreateResource(false);
          }}
        />
      </div>
    </div>
  );
};
