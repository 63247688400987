import { createSlice } from "@reduxjs/toolkit";
import { defaultCases, initListState } from "../utils";
import { CapabilityInterface } from "./interfaces";
import {
  addCapabilitiesThunk,
  getCapabilitiesThunk,
  removeCapabilitiesThunk,
  removeCapabilityThunk,
  updateCapabilityThunk,
} from "./thunks";

const capabiliesSlice = createSlice({
  name: "capabilities",
  initialState: initListState<CapabilityInterface>(),
  reducers: {},
  extraReducers: (builder) => {
    defaultCases(builder, getCapabilitiesThunk).addCase(
      getCapabilitiesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = action.payload;
      },
    );

    defaultCases(builder, addCapabilitiesThunk).addCase(
      addCapabilitiesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value.push(action.payload);
      },
    );

    defaultCases(builder, updateCapabilityThunk).addCase(
      updateCapabilityThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = state.value.map((c) => {
          if (c.id === action.payload.id) {
            return {
              ...c,
              ...action.payload,
            };
          }
          return c;
        });
      },
    );

    defaultCases(builder, removeCapabilityThunk).addCase(
      removeCapabilityThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = state.value.filter(({ id }) => action.payload !== id);
      },
    );

    defaultCases(builder, removeCapabilitiesThunk).addCase(
      removeCapabilitiesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = state.value.filter(({ id }) =>
          action.payload.includes(id),
        );
      },
    );
  },
});

export const capabilitiesReducer = capabiliesSlice.reducer;
