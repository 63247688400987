import { createSlice } from "@reduxjs/toolkit";
import { defaultCases, initListState } from "../utils";
import type { ResourceTimeframeInterface } from "./interfaces";
import {
  addResourceTimeframesThunk,
  getResourceTimeframesThunk,
  removeResourceTimeframesThunk,
  removeResourceTimeframeThunk,
  updateResourceTimeframesThunk,
} from "./thunks";

const resourceTimeframeSlice = createSlice({
  name: "timeframe",
  initialState: initListState<ResourceTimeframeInterface>(),
  reducers: {},
  extraReducers: (builder) => {
    defaultCases(builder, getResourceTimeframesThunk).addCase(
      getResourceTimeframesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = action.payload;
      },
    );

    defaultCases(builder, addResourceTimeframesThunk).addCase(
      addResourceTimeframesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value.push(action.payload);
      },
    );

    defaultCases(builder, updateResourceTimeframesThunk).addCase(
      updateResourceTimeframesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";

        state.value = state.value.map((t) => {
          if (t.id === action.payload?.id) {
            return {
              ...t,
              ...action.payload,
            };
          }

          return t;
        });
      },
    );

    defaultCases(builder, removeResourceTimeframeThunk).addCase(
      removeResourceTimeframeThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = state.value.filter(({ id }) => action.payload !== id);
      },
    );

    defaultCases(builder, removeResourceTimeframesThunk).addCase(
      removeResourceTimeframesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = state.value.filter(
          ({ id }) => !action.payload.includes(id),
        );
      },
    );
  },
});

export const resourceTimeframeReducer = resourceTimeframeSlice.reducer;
