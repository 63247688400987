import type { ChatMessageInterface } from "../chat.component";

export const MessageBox = ({
  message,
  hasPointer,
}: {
  message: ChatMessageInterface;
  hasPointer?: boolean;
}) => {
  const isUser = message.sender === "user";
  const bgColor = isUser ? "bg-indigo-300" : "bg-gray-100";
  const borderColor = isUser ? "border-b-indigo-300" : "border-b-gray-100";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isUser ? "row-reverse" : "row",
      }}
    >
      {hasPointer && (
        <div
          className="relative"
        >
          <div
            className={`w-10 h-10 border-[15px] border-t-transparent border-x-transparent ${borderColor} absolute`}
            style={{
              left: isUser ? undefined : "-10px",
              right: isUser ? "-10px" : undefined,
              zIndex: 0,
              bottom: "0px",
            }}
          ></div>
        </div>
      )}

      <div
        className={`${bgColor} w-fit min-h-10 max-w-[500px] py-2 px-4 flex flex-col rounded-xl items-left z-10`}
      >
        {message.body.map((line, i) => {
          return (
            <p key={i}>
              {line}
            </p>
          );
        })}
      </div>
    </div>
  );
};
