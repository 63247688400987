import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../core/loader/loader";
import { BusinessStage } from "./business-stage/business-stage";
import { CatalogStage } from "./catalog-stage/catalog-stage";
import { ResourceStage } from "./resource-stage/resource-stage";
import { ScheduleStage } from "./schedule-stage/schedule-stage";

export const Onboarding = () => {
  useLoader();

  return (
    <div className="bg-slate-200 flex items-center justify-center w-screen h-screen">
      <Stages />
    </div>
  );
};

const Stages = () => {
  const navigate = useNavigate();

  const [stage, setStage] = useState<
    "business-stage" | "catalog-stage" | "resource-stage" | "schedule-stage"
  >("business-stage");

  switch (stage) {
    case "business-stage":
      return (
        <BusinessStage
          onContinue={() => {
            setStage("schedule-stage");
          }}
        />
      );
    case "schedule-stage":
      return (
        <ScheduleStage
          onBack={() => {
            setStage("business-stage");
          }}
          onContinue={() => {
            setStage("catalog-stage");
          }}
        />
      );
    case "catalog-stage":
      return (
        <CatalogStage
          onBack={() => {
            setStage("schedule-stage");
          }}
          onContinue={() => {
            setStage("resource-stage");
          }}
        />
      );
    case "resource-stage":
      return (
        <ResourceStage
          onBack={() => {
            setStage("catalog-stage");
          }}
          onContinue={() => {
            navigate("/demo");
          }}
        />
      );
  }
};
