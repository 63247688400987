import { translations } from "../../core/constants/translations";
import { useLangParam } from "../../core/hooks";
import { Modal } from "../modal/modal";
import { WeekScheduleTimeframeInterface } from "./interfaces";
import { WeekScheduleTable } from "./week-schedule-table";

export const WeekScheduleModal = <T extends WeekScheduleTimeframeInterface>({
  onClose,
  timeframes,
  onAddTimeframe,
  onRemoveTimeframes,
  onUpdateTimeframes,
}: {
  onClose: () => void;
  timeframes: T[];
  onAddTimeframe: (t: Omit<T, "id">) => void;
  onRemoveTimeframes: (ids: string[]) => void;
  onUpdateTimeframes: (t: Partial<T>) => void;
}) => {
  const lang = useLangParam();

  const translation = translations["Demo"]["Configuration"];

  return (
    <Modal title={translation["Edit Business Hours"][lang]} onClose={onClose}>
      <WeekScheduleTable
        timeframes={timeframes}
        onAddTimeframe={onAddTimeframe}
        onRemoveTimeframes={onRemoveTimeframes}
        onUpdateTimeframes={onUpdateTimeframes}
      />
    </Modal>
  );
};
