import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../store/store";
import { loginThunk } from "../../store/user/thunks";
import { ChangePasswordStage } from "./stages/change-password-stage";
import { LoginStage } from "./stages/email-stage";
import { ResetPasswordStage } from "./stages/reset-password";
import { VerifyOtpStage } from "./stages/verify-otp-stage";

export const LoginPage = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const [stage, setStage] = useState<
    "login" | "recovery" | "verify" | "change-password"
  >("login");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="bg-slate-200 flex items-center justify-center w-screen h-screen">
      {stage === "login" ? (
        <LoginStage
          onPasswordReset={() => {
            setStage("recovery");
          }}
          onSubmit={() => {
            dispatch(
              loginThunk({
                email,
                password,
              }),
            )
              .unwrap()
              .then(() => {
                navigate("/demo");
              });
          }}
          {...{
            email,
            setEmail,
            password,
            setPassword,
          }}
        />
      ) : stage === "recovery" ? (
        <ResetPasswordStage
          onReturn={() => {
            setStage("login");
          }}
          onVerify={() => {
            setStage("verify");
          }}
          {...{
            email,
            setEmail,
          }}
        />
      ) : stage === "verify" ? (
        <VerifyOtpStage
          onSuccess={() => {
            setStage("change-password");
          }}
          {...{
            email,
          }}
        />
      ) : (
        <ChangePasswordStage
          onSuccess={() => {
            navigate("/demo");
          }}
        />
      )}
    </div>
  );
};
