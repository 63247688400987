import type { ActionReducerMapBuilder, AsyncThunk } from "@reduxjs/toolkit";
import type { AsyncThunkConfig } from "@reduxjs/toolkit/dist/createAsyncThunk";
import type { ListState, MaybeState, StateStatus } from "./interfaces";

export const defaultCases = <T extends StateStatus, K, Q>(
  builder: ActionReducerMapBuilder<T>,
  thunk: AsyncThunk<K, Q, AsyncThunkConfig>,
) => {
  return builder
    .addCase(thunk.pending, (state) => {
      state.status = "pending";
    })
    .addCase(thunk.rejected, (state) => {
      state.status = "rejected";
    });
};

export const defaultMaybeBuilder =
  <S, T extends MaybeState<S>, Q>(thunk: AsyncThunk<S, Q, AsyncThunkConfig>) =>
  (builder: ActionReducerMapBuilder<T>) => {
    return defaultCases(builder, thunk).addCase(
      thunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = action.payload as any;
      },
    );
  };

export const initMaybeState = <T>(): MaybeState<T> => {
  return {
    status: "idle",
  };
};

export const defaultListBuilder =
  <S, T extends ListState<S>, Q>(thunk: AsyncThunk<S, Q, AsyncThunkConfig>) =>
  (builder: ActionReducerMapBuilder<T>) => {
    return defaultCases(builder, thunk).addCase(
      thunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value.push(action.payload as any);
      },
    );
  };

export const initListState = <T>(): ListState<T> => {
  return {
    status: "idle",
    value: [],
  };
};
