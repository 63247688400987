export const serviceColors = [
  "bg-lime-300",
  "bg-green-300",
  "bg-cyan-300",
  "bg-teal-300",
  "bg-blue-300",
  "bg-purple-300",
  "bg-fuchsia-300",
  "bg-pink-300",
  "bg-red-300",
  "bg-orange-300",
  "bg-amber-300",
];
