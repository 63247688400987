import type { CustomerInterface } from "./interfaces";
import { createSlice } from "@reduxjs/toolkit";
import { addCustomerThunk, getCustomersThunk, getCustomerThunk } from "./thunks";
import { defaultCases, defaultListBuilder, initListState } from "../utils";

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    ...initListState<CustomerInterface>(),
    current: "",
  },
  reducers: {
    setCurrentCustomer: (state, action) => {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    defaultListBuilder(addCustomerThunk)(builder);
    builder
      .addCase(getCustomerThunk.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getCustomerThunk.rejected, (state, action) => {
        state.error = action.payload as string;
        state.status = "rejected";
      })
      .addCase(getCustomerThunk.fulfilled, (state, action) => {
        state.status = "fulfilled";
        if (state.value.some(({ id }) => action.payload.id === id)) {
          state.value = state.value.filter(({ id }) => {
            return action.payload.id !== id;
          })
          .concat(action.payload);
        } else {
          state.value.push(action.payload);
        }
      });

    defaultCases(builder, getCustomersThunk)
      .addCase(getCustomersThunk.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = "fulfilled";
      })
  },
});

export const { setCurrentCustomer } = customerSlice.actions;

export const customerReducer = customerSlice.reducer;
