import { Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { translations } from "../../../core/constants/translations";
import { useLangParam } from "../../../core/hooks";
import { AppDispatch } from "../../../store/store";
import { sendRecoveryEmailThunk } from "../../../store/user/thunks";

export const ResetPasswordStage = ({
  email,
  setEmail,
  onReturn,
  onVerify,
}: {
  email: string;
  setEmail: (e: string) => void;
  onReturn: () => void;
  onVerify: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();

  const translation = translations["Get Started"]["Password recovery"];

  return (
    <div className="bg-white w-96 h-96 rounded-lg flex flex-col items-center p-16 gap-3 justify-center">
      <p className="text-2xl font-bold text-center">{translation[lang]}</p>

      <p className="text-xs mb-6 text-center text-gray-500">
        {translation["Message"][lang]}
      </p>

      <TextField
        label={translation["Email"][lang]}
        variant="outlined"
        type="email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        size="small"
        sx={{
          width: "250px",
        }}
      />

      <Button
        variant="contained"
        size="large"
        sx={{
          width: "250px",
          background: "oklch(30% 0 0)",
          "&:hover": {
            background: "oklch(40% 0 0)",
          },
        }}
        onClick={() => {
          email &&
            dispatch(sendRecoveryEmailThunk(email))
              .unwrap()
              .then(() => {
                onVerify();
              });
        }}
        disabled={!email}
      >
        {translation["Send Email"][lang]}
      </Button>

      <Button
        variant="text"
        size="large"
        sx={{
          width: "250px",
        }}
        onClick={onReturn}
      >
        {translation["Back"][lang]}
      </Button>
    </div>
  );
};
