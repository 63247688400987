import { useState } from "react";
import { CalendarComponent } from "../../../../components/calendar/calendar";
import { DaySchedule } from "../../../../components/calendar/schedule";

export const LiveViewCalendar = () => {
  const [date, setDate] = useState<Date>();

  if (date) {
    return (
      <div className="p-2">
        <DaySchedule
          date={date}
          onBack={() => {
            setDate(undefined);
          }}
        />
      </div>
    );
  }

  return <CalendarComponent setDate={setDate} />;
};
