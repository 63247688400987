import { ArrowForwardIosOutlined, CloseOutlined } from "@mui/icons-material";

export const ResourceItem = ({
  name,
  last,
  onOpen,
  onDelete,
  choosed,
}: {
  name: string;
  last: boolean;
  onOpen: () => void;
  onDelete: () => void;
  choosed: boolean,
}) => {
  const border = "border-b border-gray-300";

  return (
    <div
      className={`${!last ? border : undefined}`}
    >
      <div
        className={`my-1 flex cursor-pointer items-center gap-4 py-3 px-2 hover:bg-gray-50 ${choosed ? "bg-gray-100" : ""} rounded-xl`}
        onClick={() => onOpen()}
      >
        <CloseOutlined
          sx={{
            height: "22px",
            width: "22px",
            ml: "2px",
            color: "grey",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        />

        <p className="">{name}</p>

        <ArrowForwardIosOutlined
          sx={{
            ml: "auto",
            height: "22px",
            width: "22px",
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};
