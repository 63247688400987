import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getMessages, getResponseThunk, startNewConversationThunk } from "./thunks";
import type { MessageInterface } from "./interfaces";
import { initListState } from "../utils";

const messagesSlice = createSlice({
  name: "messages",
  initialState: {
    ...initListState<MessageInterface>(),
    currentlyLoading: [] as string[],
  },
  reducers: {
    addMessage: (state, action: PayloadAction<{ body: string[], customerId: string, commerceId: string }>) => {
      state.value.push({
        body: action.payload.body,
        customerId: action.payload.customerId,
        commerceId: action.payload.commerceId,
        sender: "user",
      });
    },
    removeMessages: (state) => {
      state.value = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResponseThunk.pending, (state, action) => {
        state.status = "pending";
        state.currentlyLoading.push(action.meta.arg.customerId);
      })
      .addCase(getResponseThunk.rejected, (state, action) => {
        state.status = "rejected";
        state.currentlyLoading = state.currentlyLoading.filter((id) => {
          return id !== action.meta.arg.customerId;
        })
      }).addCase(getResponseThunk.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.currentlyLoading = state.currentlyLoading.filter((id) => {
          return id !== action.meta.arg.customerId;
        })
        if (action.payload !== null)
          state.value.push(action.payload as any);
      });

    builder
      .addCase(getMessages.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = "fulfilled";
      })

    builder
      .addCase(startNewConversationThunk.fulfilled, (state) => {
        state.value = [];
        state.status = "fulfilled";
      })
  },
});

export const { addMessage, removeMessages } = messagesSlice.actions;

export const messagesReducer = messagesSlice.reducer;
