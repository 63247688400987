import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommerceSelector } from "../../../../../components/commerce-selector/commerce-selector";
import { WeekSchedule } from "../../../../../components/week-schedule/week-schedule";
import { translations } from "../../../../../core/constants/translations";
import { useLangParam } from "../../../../../core/hooks";
import { CommerceInterface } from "../../../../../store/commerce/interfaces";
import {
  selectCommerce,
  selectCommerceId,
} from "../../../../../store/commerce/selectors";
import { setCommerceThunk } from "../../../../../store/commerce/thunks";
import { selectBusinessTypes } from "../../../../../store/constants/business-type/selectors";
import { selectIndustryTypes } from "../../../../../store/constants/industry-type/selectors";
import { AppDispatch } from "../../../../../store/store";
import { selectTimeframes } from "../../../../../store/timeframe/selectors";
import {
  addTimeframesThunk,
  removeTimeframesThunk,
  updateTimeframesThunk,
} from "../../../../../store/timeframe/thunks";
import { selectUser } from "../../../../../store/user/selectors";

export const CommerceConfig = ({
  onClose,
}: {
  onClose: () => void,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();

  const translation = translations["Demo"]["Configuration"];

  const commerceId = useSelector(selectCommerceId);

  const storeCommerce = useSelector(selectCommerce);
  const [commerce, setCommerce] = useState<CommerceInterface>({});
  const industryTypes = useSelector(selectIndustryTypes);
  const businessTypes = useSelector(selectBusinessTypes);
  const user = useSelector(selectUser);

  const timeframes = useSelector(selectTimeframes);

  const allowedBusinessTypes = businessTypes.filter(
    ({ industry_id }) =>
      industryTypes.find((it) => it.name === commerce.industry_type)?.id ===
      industry_id,
  );

  const onSubmit = () => {
    commerce &&
      user?.id &&
      dispatch(
        setCommerceThunk({
          userId: user?.id,
          ...commerce,
        }),
      );
  };

  useEffect(() => {
    storeCommerce && setCommerce(storeCommerce);
  }, [storeCommerce]);

  return (
    <div className="flex flex-col p-3 gap-2">
      <TextField
        size="small"
        placeholder={translation["Name"][lang]}
        value={commerce.name}
        onChange={(e) => {
          setCommerce({
            ...commerce,
            name: e.target.value,
          });
        }}
      />

      <CommerceSelector
        placeholder={translation["Industry"][lang]}
        options={industryTypes.map(({ name }) => name)}
        value={commerce.industry_type}
        setValue={(value) => {
          setCommerce({
            ...commerce,
            industry_type: value,
            business_type: undefined,
          });
        }}
      />

      <CommerceSelector
        disabled={allowedBusinessTypes.length === 0}
        placeholder={translation["Business"][lang]}
        options={allowedBusinessTypes.map(({ name }) => name)}
        value={commerce.business_type}
        setValue={(value) => {
          setCommerce({
            ...commerce,
            business_type: value,
          });
        }}
      />

      <WeekSchedule
        timeframes={timeframes}
        onAddTimeframes={(t) => {
          commerceId &&
            dispatch(
              addTimeframesThunk({
                ...t,
                commerce_id: commerceId,
              }),
            );
        }}
        onRemoveTimeframes={(ids) => {
          dispatch(removeTimeframesThunk(ids));
        }}
        onUpdateTimeframes={(t) => {
          dispatch(updateTimeframesThunk(t));
        }}
      />

      <p
        className="text-white cursor-pointer w-max ml-auto hover:bg-indigo-400 bg-indigo-500 px-4 py-1 rounded-full"
        onClick={() => {
          onSubmit();
          onClose();
        }}
      >
        {translation["Button"][lang]}
      </p>
    </div>
  );
};
