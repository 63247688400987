import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { messagesReducer } from "./agents";
import { appointmentReducer } from "./appointment/appointment";
import { capabilitiesReducer } from "./capabilities/capabilities";
import { commerceReducer } from "./commerce/commerce";
import { constantsReducer } from "./constants";
import { customerReducer } from "./customer";
import { customerLinksReducer } from "./customer-link/customer-link";
import { resourceReducer } from "./resource";
import { resourceTimeframeReducer } from "./resource_timeframe/timeframe";
import { serviceReducer } from "./service";
import { timeframeReducer } from "./timeframe/timeframe";
import { userReducer } from "./user/user";

const rootReducer = combineReducers({
  messages: messagesReducer,
  commerce: commerceReducer,
  customer: customerReducer,
  customerLinks: customerLinksReducer,
  constants: constantsReducer,
  resources: resourceReducer,
  services: serviceReducer,
  timeframes: timeframeReducer,
  resourceTimeframes: resourceTimeframeReducer,
  capabilities: capabilitiesReducer,
  appointments: appointmentReducer,
  user: userReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const selectState = (state: RootState) => state;
