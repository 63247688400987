import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupabaseApi } from "../../api/supabase";
import type { ResourceInterface } from "./interfaces";

export const getResourcesThunk = createAsyncThunk(
  "resources/getResources",
  async (commerceId: string) => {
    const response = await SupabaseApi.supabase
      .from("resource")
      .select("*")
      .eq("commerce_id", commerceId);

    return response.data as any as ResourceInterface[];
  },
);

export const addResourceThunk = createAsyncThunk(
  "resources/addResources",
  async (resource: Omit<ResourceInterface, "id">) => {
    const response = await SupabaseApi.supabase
      .from("resource")
      .insert(resource)
      .select();

    return response.data?.at(0) as any as ResourceInterface;
  },
);

export const updateResourceThunk = createAsyncThunk(
  "resources/updateResource",
  async (resource: ResourceInterface) => {
    await SupabaseApi.supabase
      .from("resource")
      .update({
        name: resource.name,
        description: resource.description,
      })
      .eq("id", resource.id);

    return resource;
  },
);

export const removeResourceThunk = createAsyncThunk(
  "resources/removeResource",
  async (resourceId: string) => {
    await SupabaseApi.supabase
      .from("timeframe")
      .delete()
      .eq("resource_id", resourceId);

    await SupabaseApi.supabase
      .from("capability")
      .delete()
      .eq("resource_id", resourceId);

    await SupabaseApi.supabase.from("resource").delete().eq("id", resourceId);

    return resourceId;
  },
);
