import type { ScheduleInterface } from "./interfaces";

export const dayObject: { [day: string]: string } = {
  Sunday: "SUN",
  Monday: "MON",
  Tuesday: "TUE",
  Wednesday: "WED",
  Thursday: "THU",
  Friday: "FRI",
  Saturday: "SAT",
};

export const dayAbbvToTag = {
  SUN: "SUNDAY",
  MON: "MONDAY",
  TUE: "TUESDAY",
  WED: "WEDNESDAY",
  THU: "THURSDAY",
  FRI: "FRIDAY",
  SAT: "SATURDAY",
} as const;

export const abbreviatedDays = Object.values(dayObject);
export const days = Object.keys(dayObject);

export const timeFramesDefault = abbreviatedDays.reduce((acc, day) => {
  return {
    ...acc,
    [day]: [],
  };
}, {} as ScheduleInterface);

export const timeOptions = [
  ...(function* () {
    for (let h = 0; h < 24; h++)
      for (let m = 0; m < 60; m += 15)
        yield `${h}:${m.toString().padStart(2, "0")}`;
  })(),
];
