import { Modal } from "../modal/modal";

export const ConfirmDeleteModal = ({
  title = "Confirm delete",
  onClose,
  onDelete,
}: {
  title: string,
  onClose: () => void,
  onDelete: () => void,
}) => {
  return (
    <Modal title={title} onClose={onClose}>
      <div className="flex w-full items-center justify-center py-3 gap-3">
        <p
          className={`
            px-4 py-1 border-2 border-indigo-500 rounded-full font-medium
            text-indigo-500 cursor-pointer
            hover:border-indigo-400 hover:text-indigo-400
          `}
          onClick={onDelete}
        >
          Delete
        </p>

        <p
          onClick={onClose}
          className={`
            px-4 py-1 border-2 border-indigo-500 rounded-full bg-indigo-500
            text-white font-medium cursor-pointer
            hover:bg-indigo-400 hover:border-indigo-400
          `}
        >
          Cancel
        </p>
      </div>
    </Modal>
  );
}
