export const currencies = [
  {"emoji":"🇺🇸", "name":"USD"},
  {"emoji":"🇨🇦", "name":"CAD"},
  {"emoji":"🇪🇺", "name":"EUR"},
  {"emoji":"🇲🇽", "name":"MXN"},
  {"emoji":"🇧🇷", "name":"BRL"},
  {"emoji":"🇨🇱", "name":"CLP"},
  {"emoji":"🇵🇪", "name":"PEN"},
  {"emoji":"🇨🇴", "name":"COP"},
  {"name":"AED"},
  {"name":"AFN"},
  {"name":"ALL"},
  {"name":"AMD"},
  {"name":"ANG"},
  {"name":"AOA"},
  {"name":"ARS"},
  {"name":"AUD"},
  {"name":"AWG"},
  {"name":"AZN"},
  {"name":"BAM"},
  {"name":"BBD"},
  {"name":"BDT"},
  {"name":"BGN"},
  {"name":"BHD"},
  {"name":"BIF"},
  {"name":"BMD"},
  {"name":"BND"},
  {"name":"BOB"},
  {"name":"BSD"},
  {"name":"BTN"},
  {"name":"BWP"},
  {"name":"BYN"},
  {"name":"BZD"},
  {"name":"CDF"},
  {"name":"CHF"},
  {"name":"CNY"},
  {"name":"CRC"},
  {"name":"CUP"},
  {"name":"CVE"},
  {"name":"CZK"},
  {"name":"DJF"},
  {"name":"DKK"},
  {"name":"DOP"},
  {"name":"DZD"},
  {"name":"EGP"},
  {"name":"ERN"},
  {"name":"ETB"},
  {"name":"FJD"},
  {"name":"FKP"},
  {"name":"GBP"},
  {"name":"GEL"},
  {"name":"GHS"},
  {"name":"GIP"},
  {"name":"GMD"},
  {"name":"GNF"},
  {"name":"GTQ"},
  {"name":"GYD"},
  {"name":"HKD"},
  {"name":"HNL"},
  {"name":"HRK"},
  {"name":"HTG"},
  {"name":"HUF"},
  {"name":"IDR"},
  {"name":"ILS"},
  {"name":"IMP"},
  {"name":"INR"},
  {"name":"IQD"},
  {"name":"IRR"},
  {"name":"ISK"},
  {"name":"JMD"},
  {"name":"JOD"},
  {"name":"JPY"},
  {"name":"KES"},
  {"name":"KGS"},
  {"name":"KHR"},
  {"name":"KID"},
  {"name":"KMF"},
  {"name":"KPW"},
  {"name":"KRW"},
  {"name":"KWD"},
  {"name":"KYD"},
  {"name":"KZT"},
  {"name":"LAK"},
  {"name":"LBP"},
  {"name":"LKR"},
  {"name":"LRD"},
  {"name":"LSL"},
  {"name":"LYD"},
  {"name":"MAD"},
  {"name":"MDL"},
  {"name":"MGA"},
  {"name":"MKD"},
  {"name":"MMK"},
  {"name":"MNT"},
  {"name":"MOP"},
  {"name":"MRU"},
  {"name":"MUR"},
  {"name":"MVR"},
  {"name":"MWK"},
  {"name":"MYR"},
  {"name":"MZN"},
  {"name":"NAD"},
  {"name":"NGN"},
  {"name":"NIO"},
  {"name":"NOK"},
  {"name":"NPR"},
  {"name":"NZD"},
  {"name":"OMR"},
  {"name":"PAB"},
  {"name":"PGK"},
  {"name":"PHP"},
  {"name":"PKR"},
  {"name":"PLN"},
  {"name":"PYG"},
  {"name":"QAR"},
  {"name":"RON"},
  {"name":"RSD"},
  {"name":"RUB"},
  {"name":"RWF"},
  {"name":"SAR"},
  {"name":"SBD"},
  {"name":"SCR"},
  {"name":"SDG"},
  {"name":"SEK"},
  {"name":"SGD"},
  {"name":"SHP"},
  {"name":"SLL"},
  {"name":"SOS"},
  {"name":"SRD"},
  {"name":"SSP"},
  {"name":"STN"},
  {"name":"SYP"},
  {"name":"SZL"},
  {"name":"THB"},
  {"name":"TJS"},
  {"name":"TMT"},
  {"name":"TND"},
  {"name":"TOP"},
  {"name":"TRY"},
  {"name":"TTD"},
  {"name":"TWD"},
  {"name":"TZS"},
  {"name":"UAH"},
  {"name":"UGX"},
  {"name":"UYU"},
  {"name":"UZS"},
  {"name":"VES"},
  {"name":"VND"},
  {"name":"VUV"},
  {"name":"WST"},
  {"name":"XAF"},
  {"name":"XCD"},
  {"name":"XOF"},
  {"name":"XPF"},
  {"name":"YER"},
  {"name":"ZAR"},
  {"name":"ZMW"},
  {"name":"ZWL"},
]
