import { Autocomplete, Popper, TextField } from "@mui/material";
import { currencies } from "../../core/constants/prioritized-currencies";

export const CurrencySelector = ({
  placeholder = "Currency",
  currency,
  setCurrency,
}: {
  placeholder?: string,
  currency?: string,
  setCurrency: (c: string) => void,
}) => {
  return (
    <Autocomplete
      size="small"
      disableClearable
      value={{ name: currency ?? "" }}
      onChange={(_, value) => {
        setCurrency(value.name);
      }}
      options={currencies.map((c) => ({ ...c, name: c.name }))}
      getOptionLabel={(item) => item.name}
      PopperComponent={(props) => {
        return (
          <Popper
            sx={{
              width: "360px !important",
            }}
            {...props}
          />
        );
      }}
      getOptionKey={(option) => {
        return `${option.name}`;
      }}
      renderInput={(params) => {
        return <TextField {...params} label={placeholder} />;
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <p className={`${option.name === "COP" ? "mb-10" : ""}`}>
              {option.emoji} {option.name}
            </p>
          </li>
        );
      }}
      filterOptions={(options, { inputValue }) => {
        console.log(options, inputValue);
        return options.filter((option) => {
          return `${option.name}`
            .toLowerCase()
            .includes(inputValue.toLowerCase());
        });
      }}
    />
  );
}
