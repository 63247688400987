import { createSlice } from "@reduxjs/toolkit";
import { defaultCases, defaultListBuilder } from "../utils";
import {
  addResourceThunk,
  getResourcesThunk,
  removeResourceThunk,
  updateResourceThunk,
} from "./thunks";
import type { ResourceInterface } from "./interfaces";
import type { ListState } from "../interfaces";

const initialState: ListState<ResourceInterface> & { loadingItem?: string } = {
  status: "idle",
  value: [],
};

const resourceSlice = createSlice({
  name: "resource",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    defaultCases(builder, getResourcesThunk).addCase(
      getResourcesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = action.payload;
      },
    );

    defaultListBuilder(addResourceThunk)(builder);

    builder
      .addCase(updateResourceThunk.pending, (state, action) => {
        state.status = "pending";
        state.loadingItem = action.meta.arg.id;
      })
      .addCase(updateResourceThunk.rejected, (state) => {
        state.status = "rejected";
        state.loadingItem = undefined;
      })
      .addCase(updateResourceThunk.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.value = state.value.map((r) => {
          if (r.id === action.payload.id) {
            return action.payload;
          }
          return r;
        });
        state.loadingItem = undefined;
      });

    defaultCases(builder, removeResourceThunk).addCase(
      removeResourceThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = state.value.filter(({ id }) => id !== action.payload);
      },
    );
  },
});

export const resourceReducer = resourceSlice.reducer;
