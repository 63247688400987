import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ChatComponent } from "../../components/chat";
import { useLoader } from "../../core/loader/loader";
import { selectUserId, selectUserStatus } from "../../store/user/selectors";
import { Configuration } from "./components/configuration/configuration";
import { LiveView } from "./components/live-view/live-view";
import { Topbar } from "../../components/topbar/topbar.component"

export function DemoPage() {
  useLoader();

  const userId = useSelector(selectUserId);
  const userStatus = useSelector(selectUserStatus);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId && userStatus === "fulfilled") {
      navigate("/register/?skip-onboarding=true");
    }
  }, [userId, userStatus, navigate]);

  return (
    <div className="h-screen flex flex-col text-slate-700 bg-gradient-to-b from-gray-200 to-indigo-200">
      <div>
        <Topbar />
      </div>

      <div className="flex h-[calc(100%-80px)] w-full">
        <div className="flex-1 overflow-hidden p-4 rounded-xl flex w-full">
          <div className={`
            w-[40%] overflow-y-scroll col-span-2 bg-white/40 rounded-l-3xl
            scrollbar scrollbar-thumb-rounded-full scrollbar-thumb-indigo-400/25
            scrollbar-w-1.5
          `}>
            <Configuration />
          </div>

          <div className="w-[60%] h-full bg-white/30 rounded-r-3xl overflow-hidden">
            <ChatComponent />
          </div>
        </div>

        <div className={`
          w-[312px] mr-4 h-full overflow-y-scroll
          scrollbar scrollbar-thumb-rounded-full scrollbar-thumb-indigo-400/25
          scrollbar-w-1.5 pr-2
        `}>
          <div className="w-full h-full">
            <LiveView />
          </div>
        </div>
      </div>
    </div>
  );
}
