import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { updateUserThunk } from "../../../store/user/thunks";

export const ChangePasswordStage = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);

  const onSubmit = () => {
    dispatch(
      updateUserThunk({
        password,
      }),
    )
      .unwrap()
      .then(() => {
        onSuccess();
      });
  };

  return (
    <div className="bg-white w-96 h-96 rounded-lg flex flex-col items-center p-16 gap-3 justify-center">
      <p className="text-2xl font-bold mb-8">Please input your new password</p>

      <TextField
        label="Password"
        variant="outlined"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        size="small"
        sx={{
          width: "250px",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setShowPassword((v) => !v);
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          style: {
            paddingRight: "0px",
          },
        }}
      />

      <TextField
        label="Confirm password"
        variant="outlined"
        type={showRepeatedPassword ? "text" : "password"}
        value={repeatedPassword}
        onChange={(e) => {
          setRepeatedPassword(e.target.value);
        }}
        size="small"
        sx={{
          width: "250px",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setShowRepeatedPassword((v) => !v);
                }}
              >
                {showRepeatedPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          style: {
            paddingRight: "0px",
          },
        }}
      />

      <Button
        variant="contained"
        size="large"
        sx={{
          width: "250px",
          background: "oklch(30% 0 0)",
          "&:hover": {
            background: "oklch(40% 0 0)",
          },
        }}
        onClick={onSubmit}
        disabled={
          !password || !repeatedPassword || password !== repeatedPassword
        }
      >
        CHANGE PASSWORD
      </Button>
    </div>
  );
};
