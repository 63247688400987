import { createSlice } from "@reduxjs/toolkit";
import { User } from "@supabase/supabase-js";
import { initMaybeState } from "../utils";
import {
  loginAnonThunk,
  loginThunk,
  logoutThunk,
  registerThunk,
  updateUserThunk,
  verifyOtpThunk,
} from "./thunks";

const userSlice = createSlice({
  name: "user",
  initialState: initMaybeState<User>(),
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
      state.status = "fulfilled";
    },
    setUserStatus: (state, action) => {
      state.status = action.payload;
    },
    setUserError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = "fulfilled";
        state.error = undefined;
      })
      .addCase(loginThunk.pending, (state) => {
        state.status = "pending";
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.status = "rejected";
        state.error = (action.payload as any).message;
      });

    builder
      .addCase(loginAnonThunk.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = "fulfilled";
        state.error = undefined;
      })
      .addCase(loginAnonThunk.pending, (state) => {
        state.status = "pending";
      })
      .addCase(loginAnonThunk.rejected, (state, action) => {
        state.status = "rejected";
        state.error = (action.payload as any).message;
      });

    builder
      .addCase(registerThunk.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = "fulfilled";
        state.error = undefined;
      })
      .addCase(registerThunk.pending, (state) => {
        state.status = "pending";
      })
      .addCase(registerThunk.rejected, (state, action) => {
        state.status = "rejected";
        state.error = (action.payload as any).message;
      });

    builder
      .addCase(verifyOtpThunk.fulfilled, (state) => {
        state.status = "fulfilled";
        state.error = undefined;
      })
      .addCase(verifyOtpThunk.pending, (state) => {
        state.status = "pending";
      })
      .addCase(verifyOtpThunk.rejected, (state, action) => {
        state.status = "rejected";
        state.error = (action.payload as any).message;
      });

    builder
      .addCase(updateUserThunk.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = "fulfilled";
        state.error = undefined;
      })
      .addCase(updateUserThunk.pending, (state) => {
        state.status = "pending";
      })
      .addCase(updateUserThunk.rejected, (state, action) => {
        state.status = "rejected";
        state.error = (action.payload as any).message;
        state.errorCode = (action.payload as any).code;
      });

    builder
      .addCase(logoutThunk.fulfilled, (state) => {
        state.status = "fulfilled";
        state.value = undefined;
      })
      .addCase(logoutThunk.pending, (state) => {
        state.status = "pending";
      })
      .addCase(logoutThunk.rejected, (state, action) => {
        state.status = "rejected";
        state.error = (action.payload as any).message;
      });
  },
});

export const userReducer = userSlice.reducer;

export const { setUser, setUserStatus, setUserError } = userSlice.actions;
