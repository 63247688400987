import { createSlice } from "@reduxjs/toolkit";
import { defaultCases, initListState } from "../utils";
import type { TimeframeInterface } from "./interfaces";
import {
  addTimeframesThunk,
  getTimeframesThunk,
  removeTimeframesThunk,
  removeTimeframeThunk,
  updateTimeframesThunk,
} from "./thunks";

const timeframeSlice = createSlice({
  name: "timeframe",
  initialState: initListState<TimeframeInterface>(),
  reducers: {},
  extraReducers: (builder) => {
    defaultCases(builder, getTimeframesThunk).addCase(
      getTimeframesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = action.payload;
      },
    );

    defaultCases(builder, addTimeframesThunk).addCase(
      addTimeframesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value.push(action.payload);
      },
    );

    defaultCases(builder, updateTimeframesThunk).addCase(
      updateTimeframesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";

        state.value = state.value.map((t) => {
          if (t.id === action.payload?.id) {
            return {
              ...t,
              ...action.payload,
            };
          }

          return t;
        });
      },
    );

    defaultCases(builder, removeTimeframeThunk).addCase(
      removeTimeframeThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = state.value.filter(({ id }) => action.payload !== id);
      },
    );

    defaultCases(builder, removeTimeframesThunk).addCase(
      removeTimeframesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = state.value.filter(
          ({ id }) => !action.payload.includes(id),
        );
      },
    );
  },
});

export const timeframeReducer = timeframeSlice.reducer;
