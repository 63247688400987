import { useEffect, useState } from "react";
import AIUser from "../../assets/ai-user.png";

export const TypingComponent = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const shower = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => {
      clearTimeout(shower);
    }
  }, []);

  if (!show) return null;

  return (
    <div className="flex gap-5 flex items-end">
      <div
        className={`
          w-10 h-10 rounded-full
        `}
      >
        <img alt="ai-user" src={AIUser} />
      </div>

      <style>
        {`  
          .dot-flashing {
            position: relative;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #9880ff;
            color: #9880ff;
            animation: dot-flashing 1s infinite linear alternate;
            animation-delay: 0.5s;
          }
          .dot-flashing::before, .dot-flashing::after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
          }
          .dot-flashing::before {
            left: -15px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #9880ff;
            color: #9880ff;
            animation: dot-flashing 1s infinite alternate;
            animation-delay: 0s;
          }
          .dot-flashing::after {
            left: 15px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #9880ff;
            color: #9880ff;
            animation: dot-flashing 1s infinite alternate;
            animation-delay: 1s;
          }

          @keyframes dot-flashing {
            0% {
              background-color: #9880ff;
            }
            50%, 100% {
              background-color: rgba(152, 128, 255, 0.2);
            }
          }
        `}
      </style>

      <div className="flex">
        <div
          className="relative w-0 h-0"
        >
          <div
            className={`w-10 h-10 border-[15px] border-t-transparent border-x-transparent border-b-gray-100 absolute z-0`}
            style={{
              left: "-10px",
              bottom: "-40px",
            }}
          ></div>
        </div>

        <div className="bg-gray-100 w-20 h-10 flex items-center justify-center rounded-xl">
          <div className="dot-flashing z-10" />
        </div>
      </div>
    </div>
  );
}
