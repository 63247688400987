import { createSlice } from "@reduxjs/toolkit";
import { defaultCases, initListState } from "../../utils";
import type { IndustryTypeInterface } from "./interfaces";
import { getIndustryTypesThunk } from "./thunks";

const industryTypesSlice = createSlice({
  name: "industryType",
  initialState: initListState<IndustryTypeInterface>(),
  reducers: {},
  extraReducers: (builder) => {
    defaultCases(builder, getIndustryTypesThunk).addCase(
      getIndustryTypesThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = action.payload;
      },
    );
  },
});

export const industryTypeReducer = industryTypesSlice.reducer;
