import { liveStreamMessagesI18n } from "./live-stream-messages-i18n";
import { useLangParam } from "../../core/hooks";
import { useSelector } from "react-redux";
import { selectAppointments } from "../../store/appointment/selectors";
import { selectCurrentCustomer } from "../../store/customer/selectors";
import { selectServices } from "../../store/service/selectors";
import { selectResources } from "../../store/resource/selectors";

export const LiveStreamMessages = () => {
  const lang = useLangParam();

  const customer = useSelector(selectCurrentCustomer);
  const appointments = useSelector(selectAppointments);
  const services = useSelector(selectServices);
  const resources = useSelector(selectResources);

  if (!customer?.id) {
    return null;
  }

  const customerAppointments = appointments.filter(({ customer_id }) => {
    return customer_id === customer?.id;
  });

  const changes = Object.keys(customer).map((change) => {
    if (!(customer as any)[change]) return null;

    let msg = liveStreamMessagesI18n[change];

    if (!msg) {
      return null;
    }

    return (
      <LiveStreamMessage
        message={msg[lang]("")}
        payload={(customer as any)[change]}
      />
    );
  }).filter((v) => !!v);

  const appointmentChanges = customerAppointments.map(({ service_id, scheduled_start_at, is_date_setted, is_hour_setted, resource_id }) => {
    const result = [];

    if (!!service_id) {
      const serviceName = services.find(({ id }) => service_id === id)?.name;
      if (!!serviceName) {
        result.push(
          <LiveStreamMessage
            message={liveStreamMessagesI18n["service"][lang]("")}
            payload={serviceName}
          />
        );
      }
    };

    if (!!resource_id) {
      const resourceName = resources.find(({ id }) => resource_id === id)?.name;
      if (!!resourceName) {
        result.push(
          <LiveStreamMessage
            message={liveStreamMessagesI18n["resource"][lang]("")}
            payload={resourceName}
          />
        );
      }
    }

    const date = new Date(scheduled_start_at)
    if (!!scheduled_start_at && is_date_setted) {
      result.push(
        <LiveStreamMessage
          message={liveStreamMessagesI18n["date"][lang]("")}
          payload={`${date.toLocaleDateString(lang, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}
        />
      );
    }

    if (!!scheduled_start_at && is_hour_setted) {
      result.push(
        <LiveStreamMessage
          message={liveStreamMessagesI18n["time"][lang]("")}
          payload={`${date.toLocaleTimeString(lang, { hour: "2-digit", minute: "2-digit" })}`}
        />
      );
    }

    return result;
  }).flat();

  if (changes.length === 0 && appointmentChanges.length === 0) return null;

  return (
    <div className="mt-4 w-full bg-white/60 rounded-xl">
      <div className="flex flex-col-reverse p-5 gap-5 w-full">
        {changes}
        {appointmentChanges}
      </div>
    </div>
  );
};

const LiveStreamMessage = ({
  message,
  payload,
}: {
  message: string;
  payload: string;
}) => {
  return (
    <div className="flex flex-col gap-1">
      <p className="font-medium">{message}</p>

      <p className="text-white bg-indigo-400 w-max rounded-full px-3">
        {payload ?? "Something"}
      </p>
    </div>
  );
};
