import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupabaseApi } from "../../../api/supabase";
import type { IndustryTypeInterface } from "./interfaces";

export const getIndustryTypesThunk = createAsyncThunk(
  "industryType/getIndustryTypes",
  async () => {
    const response = await SupabaseApi.supabase
      .from("industry_type")
      .select("*");

    return ((response.data as any) ?? []) as IndustryTypeInterface[];
  },
);
