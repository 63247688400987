import { Autocomplete, Button, Popper, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommerceSelector } from "../../../components/commerce-selector/commerce-selector";
import { countries } from "../../../core/constants/countries";
import { defaultConfigs } from "../../../core/constants/default-config";
import { serviceColors } from "../../../core/constants/service-colors";
import { translations } from "../../../core/constants/translations";
import { useLangParam } from "../../../core/hooks";
import { addCapabilitiesThunk } from "../../../store/capabilities/thunks";
import {
  selectCommerce,
  selectCommerceId,
} from "../../../store/commerce/selectors";
import {
  createCommerceThunk,
  setCommerceThunk,
} from "../../../store/commerce/thunks";
import { selectBusinessTypes } from "../../../store/constants/business-type/selectors";
import { selectIndustryTypes } from "../../../store/constants/industry-type/selectors";
import { addResourceThunk } from "../../../store/resource/thunks";
import { addResourceTimeframesThunk } from "../../../store/resource_timeframe/thunks";
import { addServiceThunk } from "../../../store/service/thunks";
import { AppDispatch } from "../../../store/store";
import { addTimeframesThunk } from "../../../store/timeframe/thunks";
import { selectUserId } from "../../../store/user/selectors";

export const BusinessStage = ({ onContinue }: { onContinue: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();

  const translation = translations["Onboarding"]["About your business"];

  const userId = useSelector(selectUserId);

  const industryTypes = useSelector(selectIndustryTypes);
  const businessTypes = useSelector(selectBusinessTypes);

  const commerce = useSelector(selectCommerce);

  const [companyName, setCompanyName] = useState(commerce?.name);
  const [code, setCode] = useState(commerce?.phone_country_code);
  const [phone, setPhone] = useState(commerce?.phone);
  const [country, setCountry] = useState(commerce?.country);
  const [industryType, setIndustryType] = useState(commerce?.industry_type);
  const [businessType, setBusinessType] = useState(commerce?.business_type);

  const allowedBusinessTypes = businessTypes.filter(
    ({ industry_id }) =>
      industryTypes.find((it) => it.name === industryType)?.id === industry_id,
  );

  const commerceId = useSelector(selectCommerceId);

  return (
    <div className="bg-white w-96 h-max rounded-lg flex flex-col items-center">
      <div className="border-b border-gray-300 w-full flex items-center justify-center py-6">
        <div className="w-[200px] h-3 overflow-hidden rounded-full bg-gray-100">
          <div className="w-[40px] h-3 rounded-full bg-purple-300" />
        </div>
      </div>

      <p className="text-2xl mt-4 font-bold">{translation[lang]}</p>

      <div className="p-8 flex flex-col gap-2">
        <TextField
          label={translation["Company name"][lang]}
          size="small"
          sx={{
            width: "100%",
          }}
          value={companyName}
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
        />

        <div className="flex gap-2">
          <Autocomplete
            size="small"
            disableClearable
            onChange={(_, value) => {
              setCode(`+${value.phone}`);
            }}
            options={countries.map((c) => ({ ...c, label: c.label["en"] }))}
            getOptionLabel={(item) => `+${item.phone}`}
            PopperComponent={(props) => {
              return (
                <Popper
                  sx={{
                    width: "360px !important",
                  }}
                  {...props}
                />
              );
            }}
            getOptionKey={(option) => {
              return `${option.name} ${option.code} ${option.label}`;
            }}
            renderInput={(params) => {
              return <TextField
                {...params}
                label={translation["Code"][lang]}
              />;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <p>
                    {option.emoji} {option.name} (+{option.phone})
                  </p>
                </li>
              );
            }}
            filterOptions={(options, { inputValue }) => {
              console.log(options, inputValue);
              return options.filter((option) => {
                return `${option.name} (+${option.phone})`
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              });
            }}
          />

          <TextField
            label={translation["Phone"][lang]}
            variant="outlined"
            value={phone}
            size="small"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </div>

        <Autocomplete
          size="small"
          disableClearable
          onChange={(_, value) => {
            setCountry(value.name);
          }}
          options={countries.map((c) => ({ ...c, label: c.label["en"] }))}
          getOptionLabel={(item) => item.name}
          PopperComponent={(props) => {
            return (
              <Popper
                sx={{
                  width: "360px !important",
                }}
                {...props}
              />
            );
          }}
          renderInput={(params) => {
            return <TextField
              {...params}
              label={translation["Country"][lang]}
            />;
          }}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <p>
                  {option.emoji} {option.name}
                </p>
              </li>
            );
          }}
        />

        <CommerceSelector
          placeholder={translation["Industry"][lang]}
          options={industryTypes.map(({ name }) => name).sort()}
          value={industryType}
          setValue={(value) => {
            setIndustryType(value);
          }}
        />

        <CommerceSelector
          onTop
          maxH="max-h-48"
          disabled={allowedBusinessTypes.length === 0}
          placeholder={translation["Business"][lang]}
          options={allowedBusinessTypes.map(({ name }) => name).sort()}
          value={businessType}
          setValue={(value) => {
            setBusinessType(value);
          }}
        />

        <Button
          variant="contained"
          sx={{
            width: "100%",
            background: "rgb(192 132 252)",
            "&:hover": {
              background: "rgb(216 180 254)",
            },
          }}
          onClick={() => {
            if (commerceId) {
              userId &&
                dispatch(
                  setCommerceThunk({
                    userId,
                    phone: phone,
                    phone_country_code: code,
                    name: companyName,
                    business_type: businessType,
                    industry_type: industryType,
                    country,
                  }),
                ).unwrap().then(() => {
                  onContinue();
                });
            } else {
              userId &&
                dispatch(
                  createCommerceThunk({
                    name: companyName,
                    business_type: businessType,
                    industry_type: industryType,
                    userId,
                    phone: `${code}${phone}`,
                    country,
                  }),
                )
                  .unwrap()
                  .then(async ({ id }) => {
                    const config = defaultConfigs.filter(({ industry_type }) => {
                      const it = industryTypes.find(({ name }) => {
                        return name === industryType;
                      });
                      return industry_type === it?.es;
                    }).filter(({ business_type }) => {
                      const bt = businessTypes.find(({ name }) => {
                        return name === businessType;
                      });
                      return business_type === bt?.es;
                    });

                    if (config.at(0)?.commerce_timeframes) {
                      await Promise.all(
                        config.at(0)?.commerce_timeframes.map((ct) => {
                          return new Promise<void>((resolve) => 
                           id &&
                             dispatch(
                               addTimeframesThunk({
                                 commerce_id: id,
                                 day: ct.day as any,
                                 start: ct.start,
                                 duration: ct.duration,
                               })
                           ).unwrap().then(() => {
                             resolve();
                           })
                          )
                        }) || [])
                    } else {
                      [
                        "MONDAY",
                        "TUESDAY",
                        "WEDNESDAY",
                        "THURSDAY",
                        "FRIDAY",
                      ].forEach((day) => {
                        id &&
                          dispatch(
                            addTimeframesThunk({
                              commerce_id: id,
                              day: day as any,
                              start: "9:00",
                              duration: 60 * 9,
                            }),
                          );
                      });
                    }

                    const services = await Promise.all(config.map(async ({ service }) => {
                      if (!id) return;

                      return await dispatch(addServiceThunk({
                        name: service.name,
                        description: service.description,
                        details: service.details,
                        currency: "USD",
                        price: service.price,
                        color:
                          serviceColors[
                            Math.floor(Math.random() * serviceColors.length)
                          ],
                        duration: service.duration,
                        commerce_id: id,
                      })).unwrap();
                    }))

                    config
                      .map(({ resources }) => resources)
                      .flat()
                      .reduce((acc, resource) => {
                        if (acc.some(({ name }) => name === resource.name)) {
                          return acc.map((data) => {
                            if (data.name === resource.name) {
                              return {
                                ...data,
                                services: [
                                  ...data.services,
                                  ...resource.services,
                                ],
                              };
                            }

                            return data;
                          })
                        }

                        return [...acc, resource];
                      }, [] as (typeof defaultConfigs)[number]["resources"])
                      .forEach((resource) => {
                        id && dispatch(addResourceThunk({
                          commerce_id: id,
                          name: resource.name,
                          description: resource.description,
                        })).unwrap().then(({ id: resourceId }) => {
                          resource.timeframes.forEach((frame) => {
                            dispatch(addResourceTimeframesThunk({
                              day: frame.day as any,
                              start: frame.start,
                              duration: frame.duration,
                              resource_id: resourceId,
                            }));
                          });

                          resource.services.forEach((name) => {
                            const service = services.find((service) => service?.name === name);

                            service?.id && dispatch(addCapabilitiesThunk({
                              resource_id: resourceId,
                              service_id: service.id,
                              commerce_id: id,
                            }));
                          });
                        });
                      })
            
                    onContinue();
                  });
            }
          }}
          disabled={
            !companyName ||
            !code ||
            !phone ||
            !country ||
            !industryType ||
            !businessType
          }
        >
          {translation["Continue"][lang]}
        </Button>
      </div>
    </div>
  );
};
