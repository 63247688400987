import type { ChatMessageInterface } from "../chat.component";
import { MessageBox } from "./message-box";
import AIUser from "../../../assets/ai-user.png";
import AnonUser from "../../../assets/anon-user.png";

export const MessagesGroupBox = ({
  messages,
}: {
  messages: ChatMessageInterface[];
}) => {
  const isUser = messages[0].sender === "user";

  return (
    <div
      style={{
        display: "flex",
        gap: "15px",
        flexDirection: isUser ? "row-reverse" : "row",
        alignItems: "end",
      }}
    >
      <div
        className={`
          w-10 h-10 rounded-full
        `}
      >
        {isUser ?
          <img alt="anon-user" src={AnonUser} />
          : <img alt="ai-user" src={AIUser} />
        }
      </div>

      <div
        style={{
          alignSelf: isUser ? "end" : "start",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        {messages.map((message, i) => {
          return (
            <MessageBox
              key={i}
              message={message}
              hasPointer={i === messages.length - 1}
            />
          );
        })}
      </div>
    </div>
  );
};
