import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupabaseApi } from "../../../api/supabase";
import type { BusinessTypeInterface } from "./interfaces";

export const getBusinessTypesThunk = createAsyncThunk(
  "businessTypes/getBusinessTypes",
  async () => {
    const response = await SupabaseApi.supabase
      .from("business_type")
      .select("*");

    return ((response.data as any) ?? []) as BusinessTypeInterface[];
  },
);
