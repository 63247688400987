import type { CommerceInterface } from "./interfaces";
import { createSlice } from "@reduxjs/toolkit";
import {
  createCommerceThunk,
  getCommerceThunk,
  setCommerceThunk,
} from "./thunks";
import { initMaybeState } from "../utils";

const commerceSlice = createSlice({
  name: "commerce",
  initialState: initMaybeState<CommerceInterface>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCommerceThunk.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getCommerceThunk.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(getCommerceThunk.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.value = action.payload;
      })

    builder
      .addCase(setCommerceThunk.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = "fulfilled";
      })
      .addCase(setCommerceThunk.pending, (state) => {
        state.status = "pending";
      })
      .addCase(setCommerceThunk.rejected, (state) => {
        state.status = "rejected";
      });

    builder
      .addCase(createCommerceThunk.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = "fulfilled";
      })
      .addCase(createCommerceThunk.pending, (state) => {
        state.status = "pending";
      })
      .addCase(createCommerceThunk.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export const commerceReducer = commerceSlice.reducer;
