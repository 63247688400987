import { createClient } from "@supabase/supabase-js";

export class SupabaseApi {
  static get key() {
    return process.env.REACT_APP_SUPABASE_KEY ?? "";
  }

  static get url() {
    return process.env.REACT_APP_SUPABASE_URL ?? "";
  }

  private static _supabase: ReturnType<typeof createClient>;
  static get supabase() {
    if (!SupabaseApi._supabase)
      SupabaseApi._supabase = createClient(SupabaseApi.url, SupabaseApi.key);
    return SupabaseApi._supabase;
  }
}
