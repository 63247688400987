export const defaultConfigs = [
  {
    "industry_type": "Salud",
    "business_type": "Centro Médico",
    "commerce_name": "Vida Saludable",
    "service": {
      "name": "Consulta general",
      "description": "Evaluación médica básica para diagnóstico y tratamiento.",
      "price": 50,
      "details": "Se recomienda llevar historial médico.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Juan Velazquez",
        "description": "Médico General",
        "services": [
          "Consulta general"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ana López",
        "description": "Médico General",
        "services": [
          "Consulta general"
        ],
        "timeframes": [
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Centro Médico",
    "commerce_name": "Vida Saludable",
    "service": {
      "name": "Consulta con especialista",
      "description": "Evaluación médica por un especialista en un área específica.",
      "price": 100,
      "details": "Llevar estudios o exámenes anteriores relacionados.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "Pedro Jimenez",
        "description": "Traumatólogo",
        "services": [
          "Consulta con especialista"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Sebastián Medina",
        "description": "Pediatra",
        "services": [
          "Consulta con especialista"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Centro Médico",
    "commerce_name": "Vida Saludable",
    "service": {
      "name": "Examen de laboratorio",
      "description": "Análisis de sangre u orina para diagnosticar enfermedades.",
      "price": 30,
      "details": "Ayuno previo de 8 horas necesario.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Juan Pérez",
        "description": "Tecnólogo Médico",
        "services": [
          "Examen de laboratorio"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Camila Fernández",
        "description": "Tecnólogo Médico",
        "services": [
          "Examen de laboratorio"
        ],
        "timeframes": [
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Clínica",
    "commerce_name": "Bienestar Total",
    "service": {
      "name": "Cirugía ambulatoria",
      "description": "Procedimiento quirúrgico menor que no requiere hospitalización.",
      "price": 700,
      "details": "Es necesario estar en ayunas.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Juan Velazquez",
        "description": "Médico Cirujano",
        "services": [
          "Cirugía ambulatoria"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Sebastián Medina",
        "description": "Médico Cirujano",
        "services": [
          "Cirugía ambulatoria"
        ],
        "timeframes": [
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Clínica",
    "commerce_name": "Bienestar Total",
    "service": {
      "name": "Consulta especializada",
      "description": "Consulta con un médico especialista para tratar enfermedades específicas.",
      "price": 120,
      "details": "Incluir estudios previos y lista de medicamentos actuales.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 35
    },
    "resources": [
      {
        "name": "Pedro Jimenez",
        "description": "Pediatra",
        "services": [
          "Consulta especializada"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Camila Fernández",
        "description": "Traumatólogo",
        "services": [
          "Consulta especializada"
        ],
        "timeframes": [
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Clínica",
    "commerce_name": "Bienestar Total",
    "service": {
      "name": "Rehabilitación postoperatoria",
      "description": "Sesiones de fisioterapia y cuidados postoperatorios.",
      "price": 150,
      "details": "Llevar ropa cómoda y acompañante.",
      "needs_confirmation": "1",
      "capacity": "3",
      "duration": 60
    },
    "resources": [
      {
        "name": "Juan Pérez",
        "description": "Fisioterapeuta",
        "services": [
          "Rehabilitación postoperatoria"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ana López",
        "description": "Fisioterapeuta",
        "services": [
          "Rehabilitación postoperatoria"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Centros de fisioterapia",
    "commerce_name": "Movilidad Perfecta",
    "service": {
      "name": "Sesión de fisioterapia",
      "description": "Tratamiento terapéutico para mejorar la movilidad y reducir el dolor.",
      "price": 70,
      "details": "Usar ropa cómoda y avisar sobre cualquier condición previa.",
      "needs_confirmation": "1",
      "capacity": "5",
      "duration": 60
    },
    "resources": [
      {
        "name": "Ana López",
        "description": "Fisioterapeuta",
        "services": [
          "Sesión de fisioterapia"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Juan Pérez",
        "description": "Fisioterapeuta",
        "services": [
          "Sesión de fisioterapia"
        ],
        "timeframes": [
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Centros de fisioterapia",
    "commerce_name": "Movilidad Perfecta",
    "service": {
      "name": "Masoterapia",
      "description": "Técnica de tratamiento para aliviar el dolor y mejorar la movilidad articular.",
      "price": 50,
      "details": "Se recomienda haber comido ligero antes de la sesión.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "Ana López",
        "description": "Fisioterapeuta",
        "services": [
          "Masoterapia"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Juan Pérez",
        "description": "Fisioterapeuta",
        "services": [
          "Masoterapia"
        ],
        "timeframes": [
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Centros de fisioterapia",
    "commerce_name": "Movilidad Perfecta",
    "service": {
      "name": "Rehabilitación deportiva",
      "description": "Programa de recuperación diseñado para atletas o personas activas.",
      "price": 90,
      "details": "Llevar ropa deportiva y avisar sobre lesiones.",
      "needs_confirmation": "1",
      "capacity": "5",
      "duration": 90
    },
    "resources": [
      {
        "name": "Ana López",
        "description": "Fisioterapeuta",
        "services": [
          "Rehabilitación deportiva"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Juan Pérez",
        "description": "Fisioterapeuta",
        "services": [
          "Rehabilitación deportiva"
        ],
        "timeframes": [
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Consulta Médica",
    "commerce_name": "Atención Integral",
    "service": {
      "name": "Consulta médica",
      "description": "Evaluación médica general para diagnóstico y tratamiento.",
      "price": 90,
      "details": "Traer historial médico y resultados de exámenes previos.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Dr. Manuel Rivas",
        "description": "Médico General",
        "services": [
          "Consulta médica"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Dr. Sofia Castro",
        "description": "Médico General",
        "services": [
          "Consulta médica"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Consulta Médica",
    "commerce_name": "Atención Integral",
    "service": {
      "name": "Chequeo preventivo",
      "description": "Examen físico y pruebas para prevención de enfermedades.",
      "price": 70,
      "details": "Ayuno necesario para algunas pruebas.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "Dr. Manuel Rivas",
        "description": "Médico General",
        "services": [
          "Chequeo preventivo"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Dr. Sofia Castro",
        "description": "Médico General",
        "services": [
          "Chequeo preventivo"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Consulta Médica",
    "commerce_name": "Atención Integral",
    "service": {
      "name": "Teleconsulta",
      "description": "Consulta médica a través de videollamada.",
      "price": 50,
      "details": "Tener conexión a internet estable y lista de síntomas.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Dr. Manuel Rivas",
        "description": "Médico General",
        "services": [
          "Teleconsulta"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Dr. Sofia Castro",
        "description": "Médico General",
        "services": [
          "Teleconsulta"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Medicina Alternativa",
    "commerce_name": "Naturaleza Sana",
    "service": {
      "name": "Sesión de acupuntura",
      "description": "Tratamiento con agujas para aliviar el dolor o tratar diversas afecciones.",
      "price": 60,
      "details": "Usar ropa cómoda que permita el acceso a los brazos y piernas.",
      "needs_confirmation": "1",
      "capacity": "2",
      "duration": 60
    },
    "resources": [
      {
        "name": "Laura Guzmán",
        "description": "Especialista Medicina Alternativa",
        "services": [
          "Sesión de acupuntura"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ernesto Fernández",
        "description": "Especialista Medicina Alternativa",
        "services": [
          "Sesión de acupuntura"
        ],
        "timeframes": [
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Medicina Alternativa",
    "commerce_name": "Naturaleza Sana",
    "service": {
      "name": "Terapia de reiki",
      "description": "Terapia energética para equilibrar el cuerpo y la mente.",
      "price": 50,
      "details": "Traer historial de tratamientos previos.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "Laura Guzmán",
        "description": "Especialista Medicina Alternativa",
        "services": [
          "Terapia de reiki"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ernesto Fernández",
        "description": "Especialista Medicina Alternativa",
        "services": [
          "Terapia de reiki"
        ],
        "timeframes": [
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Medicina Alternativa",
    "commerce_name": "Naturaleza Sana",
    "service": {
      "name": "Consulta homeopática",
      "description": "Evaluación y tratamiento basado en la homeopatía.",
      "price": 45,
      "details": "No es necesario traer ropa especial.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Laura Guzmán",
        "description": "Especialista Medicina Alternativa",
        "services": [
          "Consulta homeopática"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ernesto Fernández",
        "description": "Especialista Medicina Alternativa",
        "services": [
          "Consulta homeopática"
        ],
        "timeframes": [
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Centro de Podología",
    "commerce_name": "Pies Saludables",
    "service": {
      "name": "Tratamiento de uñas encarnadas",
      "description": "Procedimiento para aliviar el dolor y corregir uñas encarnadas.",
      "price": 40,
      "details": "Evitar el uso de esmaltes durante el tratamiento.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "Hugo Martínez",
        "description": "Podólogo",
        "services": [
          "Tratamiento de uñas encarnadas"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Clara Rivas",
        "description": "Podólogo",
        "services": [
          "Tratamiento de uñas encarnadas"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Centro de Podología",
    "commerce_name": "Pies Saludables",
    "service": {
      "name": "Consulta Podológica",
      "description": "Tratamiento especializado para el cuidado y salud de los pies.",
      "price": 50,
      "details": "Avisar sobre alergias a productos específicos.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Hugo Martínez",
        "description": "Podólogo",
        "services": [
          "Consulta Podológica"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Clara Rivas",
        "description": "Podólogo",
        "services": [
          "Consulta Podológica"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Centro de Podología",
    "commerce_name": "Pies Saludables",
    "service": {
      "name": "Tratamiento de callosidades ",
      "description": "Eliminación de callosidades",
      "price": 55,
      "details": "Usar zapatos cómodos después del tratamiento.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "Hugo Martínez",
        "description": "Podólogo",
        "services": [
          "Tratamiento de callosidades "
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Clara Rivas",
        "description": "Podólogo",
        "services": [
          "Tratamiento de callosidades "
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Clínica Odontológica",
    "commerce_name": "Sonrisa Perfecta",
    "service": {
      "name": "Limpieza dental",
      "description": "Eliminación de placa y sarro para mantener la salud oral.",
      "price": 20,
      "details": "Evitar comer antes de la cita.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Dr. Mariana González",
        "description": "Odontologo",
        "services": [
          "Limpieza dental"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Dr. Juan Pérez",
        "description": "Odontologo",
        "services": [
          "Limpieza dental"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Clínica Odontológica",
    "commerce_name": "Sonrisa Perfecta",
    "service": {
      "name": "Blanqueamiento dental",
      "description": "Procedimiento para aclarar el tono de los dientes.",
      "price": 50,
      "details": "Se recomienda evitar alimentos que manchen los dientes.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "Dr. Mariana González",
        "description": "Odontologo",
        "services": [
          "Blanqueamiento dental"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Dr. Juan Pérez",
        "description": "Odontologo",
        "services": [
          "Blanqueamiento dental"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Clínica Odontológica",
    "commerce_name": "Sonrisa Perfecta",
    "service": {
      "name": "Ortodoncia",
      "description": "Tratamiento para corregir la alineación de los dientes.",
      "price": 70,
      "details": "Programar citas periódicas de ajuste.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Dr. Mariana González",
        "description": "Ortodoncista ",
        "services": [
          "Ortodoncia"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Dr. Juan Pérez",
        "description": "Odontologo",
        "services": [
          "Ortodoncia"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Consultorio",
    "commerce_name": "Salud Familiar",
    "service": {
      "name": "Consulta médica general",
      "description": "Evaluación y diagnóstico general de salud.",
      "price": 50,
      "details": "Llevar lista de medicamentos actuales.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Juan Velazquez",
        "description": "Médico General",
        "services": [
          "Consulta médica general"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ana López",
        "description": "Médico General",
        "services": [
          "Consulta médica general"
        ],
        "timeframes": [
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Consultorio",
    "commerce_name": "Salud Familiar",
    "service": {
      "name": "Consulta pediátrica",
      "description": "Atención médica especializada para niños.",
      "price": 60,
      "details": "Traer carnet de vacunación y historial médico del niño.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "Pedro Jimenez",
        "description": "Pediatra",
        "services": [
          "Consulta pediátrica"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Sebastián Medina",
        "description": "Pediatra",
        "services": [
          "Consulta pediátrica"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Consultorio",
    "commerce_name": "Salud Familiar",
    "service": {
      "name": "Consulta ginecológica",
      "description": "Atención médica especializada para salud femenina.",
      "price": 70,
      "details": "Llevar estudios previos relacionados.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Juan Pérez",
        "description": "Ginecólogo",
        "services": [
          "Consulta ginecológica"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Camila Fernández",
        "description": "Ginecóloga",
        "services": [
          "Consulta ginecológica"
        ],
        "timeframes": [
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Veterinaria",
    "commerce_name": "Cuidados Peludos",
    "service": {
      "name": "Consulta veterinaria",
      "description": "Evaluación general de la salud de la mascota.",
      "price": 70,
      "details": "Llevar documentación de vacunas y historial del animal.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "Dr. Jorge Torres",
        "description": "Veterinario",
        "services": [
          "Consulta veterinaria"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Dr. Carla Medina",
        "description": "Veterinario",
        "services": [
          "Consulta veterinaria"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Veterinaria",
    "commerce_name": "Cuidados Peludos",
    "service": {
      "name": "Vacunación de mascotas",
      "description": "Aplicación de vacunas para prevenir enfermedades en animales.",
      "price": 30,
      "details": "Llevar documentación de vacunas previas",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Dr. Jorge Torres",
        "description": "Veterinario",
        "services": [
          "Vacunación de mascotas"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Dr. Carla Medina",
        "description": "Veterinario",
        "services": [
          "Vacunación de mascotas"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Veterinaria",
    "commerce_name": "Cuidados Peludos",
    "service": {
      "name": "Desparasitación de Mascotas",
      "description": "Tratamiento para eliminar parásitos en mascotas.",
      "price": 50,
      "details": "Asegúrate de que la mascota esté libre de otros tratamientos previos",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Dr. Jorge Torres",
        "description": "Veterinario",
        "services": [
          "Desparasitación de Mascotas"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Dr. Carla Medina",
        "description": "Veterinario",
        "services": [
          "Desparasitación de Mascotas"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Psicología",
    "commerce_name": "Dr. Marta Fernández",
    "service": {
      "name": "Sesión de terapia",
      "description": "Sesión para tratar problemas emocionales y psicológicos.",
      "price": 80,
      "details": "Llevar una lista de temas o preocupaciones que deseas abordar",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Dr. Marta Fernández",
        "description": "Psicóloga",
        "services": [
          "Sesión de terapia"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Psicología",
    "commerce_name": "Dr. Marta Fernández",
    "service": {
      "name": "Evaluación psicológica",
      "description": "Evaluación completa para diagnóstico psicológico.",
      "price": 100,
      "details": "Requiere evaluación previa para determinar el tratamiento.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 90
    },
    "resources": [
      {
        "name": "Dr. Marta Fernández",
        "description": "Psicóloga",
        "services": [
          "Evaluación psicológica"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Psicología",
    "commerce_name": "Dr. Marta Fernández",
    "service": {
      "name": "Terapia de pareja",
      "description": "Sesiones para ayudar a mejorar la relación de pareja.",
      "price": 150,
      "details": "Ambas partes deben estar presentes en la sesión.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Dr. Marta Fernández",
        "description": "Psicóloga",
        "services": [
          "Terapia de pareja"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Medicina",
    "commerce_name": "Dr. Luis Ramírez",
    "service": {
      "name": "Consulta médica",
      "description": "Evaluación general de salud y tratamiento personalizado.",
      "price": 50,
      "details": "Avisar sobre cualquier medicación actual.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Dr. Luis Ramírez",
        "description": "Médico General",
        "services": [
          "Consulta médica"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Medicina",
    "commerce_name": "Dr. Luis Ramírez",
    "service": {
      "name": "Control de enfermedades crónicas",
      "description": "Seguimiento y tratamiento de enfermedades crónicas.",
      "price": 60,
      "details": "Seguimiento periódico",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Dr. Luis Ramírez",
        "description": "Médico General",
        "services": [
          "Control de enfermedades crónicas"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Medicina",
    "commerce_name": "Dr. Luis Ramírez",
    "service": {
      "name": "Prescripción de medicamentos",
      "description": "Consulta breve para prescribir o renovar medicamentos.",
      "price": 20,
      "details": "Llevar informes previos si los hay.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Dr. Luis Ramírez",
        "description": "Médico General",
        "services": [
          "Prescripción de medicamentos"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Nutrición",
    "commerce_name": "Nutricionista Andrés Silva",
    "service": {
      "name": "Consulta nutricional",
      "description": "Evaluación de hábitos alimenticios y diseño de un plan de dieta.",
      "price": 50,
      "details": "Traer un registro de comidas de la última semana.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Dr. Andrés Silva",
        "description": "Nutricionista",
        "services": [
          "Consulta nutricional"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Nutrición",
    "commerce_name": "Nutricionista Andrés Silva",
    "service": {
      "name": "Plan de alimentación",
      "description": "Diseño personalizado de un plan de alimentación saludable.",
      "price": 70,
      "details": "Informar sobre alergias y preferencias alimenticias.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Dr. Andrés Silva",
        "description": "Nutricionista",
        "services": [
          "Plan de alimentación"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Nutrición",
    "commerce_name": "Nutricionista Andrés Silva",
    "service": {
      "name": "Control de peso",
      "description": "Seguimiento y ajuste del plan de dieta para control de peso.",
      "price": 40,
      "details": "Llevar registros de peso y medidas recientes.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Dr. Andrés Silva",
        "description": "Nutricionista",
        "services": [
          "Control de peso"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Psiquiatría",
    "commerce_name": "Dr. Javier Morales",
    "service": {
      "name": "Consulta psiquiátrica",
      "description": "Evaluación y tratamiento de trastornos mentales.",
      "price": 100,
      "details": "Avisar sobre cualquier medicación actual.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Dr. Javier Morales",
        "description": "Psiquiatra",
        "services": [
          "Consulta psiquiátrica"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Psiquiatría",
    "commerce_name": "Dr. Javier Morales",
    "service": {
      "name": "Terapia psiquiátrica",
      "description": "Tratamiento a través de medicación y terapia.",
      "price": 120,
      "details": "Traer historial médico y lista de medicamentos actuales.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Dr. Javier Morales",
        "description": "Psiquiatra",
        "services": [
          "Terapia psiquiátrica"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Psiquiatría",
    "commerce_name": "Dr. Javier Morales",
    "service": {
      "name": "Consulta de seguimiento",
      "description": "Revisión y ajuste del tratamiento psiquiátrico.",
      "price": 90,
      "details": "Llevar informe de progreso si lo hay.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "Dr. Javier Morales",
        "description": "Psiquiatra",
        "services": [
          "Consulta de seguimiento"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Matrona",
    "commerce_name": "María Gómez",
    "service": {
      "name": "Control prenatal",
      "description": "Seguimiento del embarazo para asegurar la salud del bebé y la madre.",
      "price": 50,
      "details": "Traer resultados de ecografías y exámenes previos.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "María Gómez",
        "description": "Matrona",
        "services": [
          "Control prenatal"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Matrona",
    "commerce_name": "María Gómez",
    "service": {
      "name": "Atención de parto",
      "description": "Servicio de asistencia y participación del parto.",
      "price": 500,
      "details": "Se recomienda preparar un plan de parto para discutirlo con el equipo médico",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 360
    },
    "resources": [
      {
        "name": "María Gómez",
        "description": "Matrona",
        "services": [
          "Atención de parto"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Salud",
    "business_type": "Profesional Independiente - Matrona",
    "commerce_name": "María Gómez",
    "service": {
      "name": "Consulta postnatal",
      "description": "Evaluación de la madre y el recién nacido después del parto.",
      "price": 60,
      "details": "Avisar sobre cualquier complicación postparto.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "María Gómez",
        "description": "Matrona",
        "services": [
          "Consulta postnatal"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Profesional Independiente - Derecho",
    "commerce_name": "Fernando Silva",
    "service": {
      "name": "Asesoría legal",
      "description": "Consulta legal para resolver dudas y asesoramiento jurídico.",
      "price": 150,
      "details": "Traer documentos relacionados con el caso.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Fernando Silva",
        "description": "Abogado",
        "services": [
          "Asesoría legal"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Profesional Independiente - Derecho",
    "commerce_name": "Fernando Silva",
    "service": {
      "name": "Redacción de contratos",
      "description": "Elaboración y revisión de contratos legales.",
      "price": 200,
      "details": "Especificar claramente el negocio y las cláusulas requeridas.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 120
    },
    "resources": [
      {
        "name": "Fernando Silva",
        "description": "Abogado",
        "services": [
          "Redacción de contratos"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Profesional Independiente - Derecho",
    "commerce_name": "Fernando Silva",
    "service": {
      "name": "Representación judicial",
      "description": "Servicio de defensa y representación en juicios.",
      "price": 1000,
      "details": "Presentar todos los documentos relacionados con el caso.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 360
    },
    "resources": [
      {
        "name": "Fernando Silva",
        "description": "Abogado",
        "services": [
          "Representación judicial"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Centros de Estética",
    "commerce_name": "Belleza Radiante",
    "service": {
      "name": "Tratamiento facial",
      "description": "Limpieza y tratamiento para mejorar la salud y apariencia de la piel.",
      "price": 70,
      "details": "No exponer la piel al sol antes del tratamiento.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Carla Ruiz",
        "description": "Técnica Especialista",
        "services": [
          "Tratamiento facial"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ana González",
        "description": "Técnica Especialista",
        "services": [
          "Tratamiento facial"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Centros de Estética",
    "commerce_name": "Belleza Radiante",
    "service": {
      "name": "Depilación láser",
      "description": "Eliminación del vello no deseado utilizando tecnología láser.",
      "price": 100,
      "details": "Tratamiento requiere sesiones",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "Carla Ruiz",
        "description": "Técnica Especialista",
        "services": [
          "Depilación láser"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ana González",
        "description": "Técnica Especialista",
        "services": [
          "Depilación láser"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Centros de Estética",
    "commerce_name": "Belleza Radiante",
    "service": {
      "name": "Masaje relajante",
      "description": "Masaje para reducir el estrés y la tensión muscular.",
      "price": 60,
      "details": "Avisar sobre alergias a productos cosméticos.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "María Fernández",
        "description": "Masajista",
        "services": [
          "Masaje relajante"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Claudia Rivas",
        "description": "Masajista",
        "services": [
          "Masaje relajante"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Peluquería",
    "commerce_name": "Estilo Perfecto",
    "service": {
      "name": "Corte de cabello",
      "description": "Servicio de corte y estilo de cabello según la preferencia del cliente.",
      "price": 30,
      "details": "Se recomienda traer referencias o fotos del estilo deseado.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Juan Pérez",
        "description": "Peluquero",
        "services": [
          "Corte de cabello"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ana López",
        "description": "Peluquera",
        "services": [
          "Corte de cabello"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Peluquería",
    "commerce_name": "Estilo Perfecto",
    "service": {
      "name": "Tinte",
      "description": "Servicio de coloración del cabello con tintes profesionales.",
      "price": 60,
      "details": "Avisar sobre tratamientos previos de color.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Juan Pérez",
        "description": "Peluquero",
        "services": [
          "Tinte"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ana López",
        "description": "Peluquera",
        "services": [
          "Tinte"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Peluquería",
    "commerce_name": "Estilo Perfecto",
    "service": {
      "name": "Alisado",
      "description": "Tratamiento químico para alisar el cabello y reducir el frizz.",
      "price": 100,
      "details": "Evitar lavar el cabello antes del tratamiento.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 90
    },
    "resources": [
      {
        "name": "María Fernández",
        "description": "Peluquera Especialista",
        "services": [
          "Alisado"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Claudia Rivas",
        "description": "Peluquera Especialista",
        "services": [
          "Alisado"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Spas",
    "commerce_name": "Spa Serenidad",
    "service": {
      "name": "Masaje sueco",
      "description": "Masaje de cuerpo completo para mejorar la circulación y relajar los músculos.",
      "price": 80,
      "details": "Usar ropa cómoda o proporcionada por el spa.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "María Fernández",
        "description": "Masajista",
        "services": [
          "Masaje sueco"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Claudia Rivas",
        "description": "Masajista",
        "services": [
          "Masaje sueco"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Spas",
    "commerce_name": "Spa Serenidad",
    "service": {
      "name": "Baño de vapor",
      "description": "Sesión de sauna para desintoxicar el cuerpo y relajar la mente.",
      "price": 40,
      "details": "Usar ropa cómoda o proporcionada por el spa.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "María Fernández",
        "description": "Masajista",
        "services": [
          "Baño de vapor"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Claudia Rivas",
        "description": "Masajista",
        "services": [
          "Baño de vapor"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Spas",
    "commerce_name": "Spa Serenidad",
    "service": {
      "name": "Tratamiento de piedras calientes",
      "description": "Masaje terapéutico con piedras calientes para aliviar el dolor y la tensión.",
      "price": 90,
      "details": "Usar ropa cómoda o proporcionada por el spa.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Juan Pérez",
        "description": "Especialista",
        "services": [
          "Tratamiento de piedras calientes"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Fernando Silva",
        "description": "Especialista",
        "services": [
          "Tratamiento de piedras calientes"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Salón de belleza",
    "commerce_name": "Glamour Total",
    "service": {
      "name": "Maquillaje",
      "description": "Servicio de aplicación de maquillaje para ocasiones especiales.",
      "price": 50,
      "details": "Informar sobre preferencias de color o diseño.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 90
    },
    "resources": [
      {
        "name": "Laura Méndez",
        "description": "Esteticista",
        "services": [
          "Maquillaje"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Salón de belleza",
    "commerce_name": "Glamour Total",
    "service": {
      "name": "Peinado para eventos",
      "description": "Diseño y estilizado del cabello para eventos y ocasiones especiales.",
      "price": 60,
      "details": "Se recomienda un lavado antes del peinado",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Laura Méndez",
        "description": "Esteticista",
        "services": [
          "Peinado para eventos"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Salón de belleza",
    "commerce_name": "Glamour Total",
    "service": {
      "name": "Tratamiento capilar",
      "description": "Tratamiento especializado para la salud y el brillo del cabello.",
      "price": 70,
      "details": "Avisar sobre alergias a productos cosméticos.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Carla Ruiz",
        "description": "Esteticista",
        "services": [
          "Tratamiento capilar"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Barbería",
    "commerce_name": "Gentlemen's Barber Shop",
    "service": {
      "name": "Corte de barba",
      "description": "Corte y arreglo de barba según el estilo deseado.",
      "price": 25,
      "details": "Informar sobre estilo deseado.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 45
    },
    "resources": [
      {
        "name": "Roberto Sánchez",
        "description": "Barbero",
        "services": [
          "Corte de barba"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Luis Torres",
        "description": "Barbero",
        "services": [
          "Corte de barba"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Barbería",
    "commerce_name": "Gentlemen's Barber Shop",
    "service": {
      "name": "Afeitado tradicional",
      "description": "Afeitado con navaja y productos de alta calidad.",
      "price": 30,
      "details": "Se recomienda no haber afeitado el día anterior.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Roberto Sánchez",
        "description": "Barbero",
        "services": [
          "Afeitado tradicional"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Luis Torres",
        "description": "Barbero",
        "services": [
          "Afeitado tradicional"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Barbería",
    "commerce_name": "Gentlemen's Barber Shop",
    "service": {
      "name": "Arreglo de bigote",
      "description": "Corte y estilo del bigote.",
      "price": 20,
      "details": "Informar sobre estilo y longitud deseada.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Roberto Sánchez",
        "description": "Barbero",
        "services": [
          "Arreglo de bigote"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Luis Torres",
        "description": "Barbero",
        "services": [
          "Arreglo de bigote"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Salón de Manicure y Pedicure",
    "commerce_name": "Manos y Pies Perfectos",
    "service": {
      "name": "Manicure",
      "description": "Servicio de cuidado de las uñas de las manos.",
      "price": 30,
      "details": "Informar sobre preferencias de color o diseño.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 40
    },
    "resources": [
      {
        "name": "Carla Ruiz",
        "description": "Manicurista",
        "services": [
          "Manicure"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ana López",
        "description": "Manicurista",
        "services": [
          "Manicure"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Salón de Manicure y Pedicure",
    "commerce_name": "Manos y Pies Perfectos",
    "service": {
      "name": "Pedicure",
      "description": "Servicio de cuidado de las uñas y piel de los pies.",
      "price": 40,
      "details": "Usar zapatos abiertos después del tratamiento.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 50
    },
    "resources": [
      {
        "name": "Carla Ruiz",
        "description": "Manicurista",
        "services": [
          "Pedicure"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ana López",
        "description": "Manicurista",
        "services": [
          "Pedicure"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Salón de Manicure y Pedicure",
    "commerce_name": "Manos y Pies Perfectos",
    "service": {
      "name": "Tratamiento de uñas acrílicas",
      "description": "Aplicación de uñas acrílicas para mayor durabilidad y estilo.",
      "price": 50,
      "details": "No retirar el esmalte previo al servicio.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Carla Ruiz",
        "description": "Manicurista",
        "services": [
          "Tratamiento de uñas acrílicas"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Ana López",
        "description": "Manicurista",
        "services": [
          "Tratamiento de uñas acrílicas"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Maquillaje",
    "commerce_name": "Belleza Natural",
    "service": {
      "name": "Maquillaje de día",
      "description": "Aplicación de maquillaje natural para el día a día.",
      "price": 40,
      "details": "Avisar sobre alergias a tintas o materiales.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "María Fernández",
        "description": "Maquilladora",
        "services": [
          "Maquillaje de día"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Claudia Rivas",
        "description": "Maquilladora",
        "services": [
          "Maquillaje de día"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Maquillaje",
    "commerce_name": "Belleza Natural",
    "service": {
      "name": "Maquillaje de noche",
      "description": "Aplicación de maquillaje más intenso para eventos nocturnos.",
      "price": 60,
      "details": "Traer diseño o idea clara del estilo deseado.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 80
    },
    "resources": [
      {
        "name": "María Fernández",
        "description": "Maquilladora",
        "services": [
          "Maquillaje de noche"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Claudia Rivas",
        "description": "Maquilladora",
        "services": [
          "Maquillaje de noche"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Maquillaje",
    "commerce_name": "Belleza Natural",
    "service": {
      "name": "Maquillaje para bodas",
      "description": "Maquillaje especializado para el día de la boda.",
      "price": 100,
      "details": "Se recomienda realizar una prueba de maquillaje antes del día de la boda",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 100
    },
    "resources": [
      {
        "name": "María Fernández",
        "description": "Maquilladora",
        "services": [
          "Maquillaje para bodas"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Claudia Rivas",
        "description": "Maquilladora",
        "services": [
          "Maquillaje para bodas"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Salón de Cejas y Pestañas",
    "commerce_name": "Mirada Encantadora",
    "service": {
      "name": "Diseño de cejas",
      "description": "Moldeado y arreglo de cejas para mejorar la expresión facial.",
      "price": 40,
      "details": "Informar sobre preferencias de color o diseño.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 40
    },
    "resources": [
      {
        "name": "Marta Urzúa",
        "description": "Técnico en cejas",
        "services": [
          "Diseño de cejas"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "María Gómez",
        "description": "Estilista",
        "services": [
          "Diseño de cejas"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Salón de Cejas y Pestañas",
    "commerce_name": "Mirada Encantadora",
    "service": {
      "name": "Extensión de pestañas",
      "description": "Aplicación de pestañas postizas para mayor longitud y volumen.",
      "price": 80,
      "details": "No mojar las pestañas durante 24 horas después del servicio.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Carla Moreno",
        "description": "Estilista de pestañas",
        "services": [
          "Extensión de pestañas"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "María Gómez",
        "description": "Estilista",
        "services": [
          "Extensión de pestañas"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Salón de Cejas y Pestañas",
    "commerce_name": "Mirada Encantadora",
    "service": {
      "name": "Lifting de pestañas",
      "description": "Tratamiento para levantar y curvar las pestañas naturales.",
      "price": 70,
      "details": "No utilizar máscara de pestañas antes del servicio.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Carta Moreno",
        "description": "Estilista de pestañas",
        "services": [
          "Lifting de pestañas"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "María Gómez",
        "description": "Estilista",
        "services": [
          "Lifting de pestañas"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Estilista Independiente",
    "commerce_name": "Andrea Moraga",
    "service": {
      "name": "Corte de cabello",
      "description": "Servicio de corte y estilizado personalizado.",
      "price": 40,
      "details": "Se recomienda traer imágenes de referencia del estilo deseado",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 40
    },
    "resources": [
      {
        "name": "Andrea Moraga",
        "description": "Estilista",
        "services": [
          "Corte de cabello"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Estilista Independiente",
    "commerce_name": "Andrea Moraga",
    "service": {
      "name": "Asesoría de imagen",
      "description": "Asesoría para mejorar la imagen personal a través del estilo y vestuario.",
      "price": 100,
      "details": "Traer una selección de prendas y accesorios actuales",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 100
    },
    "resources": [
      {
        "name": "Andrea Moraga",
        "description": "Estilista",
        "services": [
          "Asesoría de imagen"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Estilista Independiente",
    "commerce_name": "Andrea Moraga",
    "service": {
      "name": "Coloración",
      "description": "Servicio de cambio de color de cabello con tintes profesionales.",
      "price": 60,
      "details": "Favor considerar el estado actual del cabello para elegir el color ",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Andrea Moraga",
        "description": "Estilista",
        "services": [
          "Coloración"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Estudio de tatuajes",
    "commerce_name": "Tatuaje pequeño",
    "service": {
      "name": "Tatuaje de diseño pequeño.",
      "description": "Arte en Piel",
      "price": 100,
      "details": "Avisar sobre alergias a tintas o materiales.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Hector Morales",
        "description": "Tatuador",
        "services": [
          "Tatuaje de diseño pequeño."
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "TUESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "WEDNESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "THURSDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "FRIDAY",
            "start": "14:00",
            "duration": 240
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Estudio de tatuajes",
    "commerce_name": "Tatuaje mediano",
    "service": {
      "name": "Tatuaje de diseño mediano.",
      "description": "Arte en Piel",
      "price": 200,
      "details": "Traer diseño o idea clara del tatuaje deseado.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Hector Morales",
        "description": "Tatuador",
        "services": [
          "Tatuaje de diseño mediano."
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "TUESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "WEDNESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "THURSDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "FRIDAY",
            "start": "14:00",
            "duration": 240
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Estética y Belleza",
    "business_type": "Estudio de tatuajes",
    "commerce_name": "Tatuaje grande",
    "service": {
      "name": "Tatuaje de diseño grande y detallado.",
      "description": "Arte en Piel",
      "price": 400,
      "details": "Puede requerir múltiples sesiones.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 120
    },
    "resources": [
      {
        "name": "Hector Morales",
        "description": "Tatuador",
        "services": [
          "Tatuaje de diseño grande y detallado."
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 240
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 240
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 240
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 240
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 240
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Centro deportivo",
    "commerce_name": "Deportes Total",
    "service": {
      "name": "Entrenamiento personal",
      "description": "Sesión personalizada de entrenamiento físico.",
      "price": 50,
      "details": "Traer ropa cómoda y botella de agua.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Alex Gómez",
        "description": "Entrenador Personal",
        "services": [
          "Entrenamiento personal"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Cristobal Calvo",
        "description": "Entrenador Personal",
        "services": [
          "Entrenamiento personal"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Centro deportivo",
    "commerce_name": "Deportes Total",
    "service": {
      "name": "Clases de spinning",
      "description": "Clase grupal de spinning para mejorar la resistencia y quema de calorías.",
      "price": 30,
      "details": "Traer ropa cómoda y botella de agua.",
      "needs_confirmation": "1",
      "capacity": "20",
      "duration": 60
    },
    "resources": [
      {
        "name": "Ignacia Pinto",
        "description": "Profesora Spinning",
        "services": [
          "Clases de spinning"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Andres Moreno",
        "description": "Profesor Spinning",
        "services": [
          "Clases de spinning"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Centro deportivo",
    "commerce_name": "Deportes Total",
    "service": {
      "name": "Evaluación física",
      "description": "Evaluación inicial de la condición física y aptitud.",
      "price": 40,
      "details": "Informar sobre lesiones previas.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Guillermo Navarro",
        "description": "Entrenador Personal",
        "services": [
          "Evaluación física"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Centro Deportivo",
    "commerce_name": "Deportes Total",
    "service": {
      "name": "Alquiler de Cancha de Fútbol",
      "description": "Renta de cancha de fútbol por hora para partidos.",
      "price": 100,
      "details": "Reservar con anticipación, traer equipo deportivo.",
      "needs_confirmation": "1",
      "capacity": "2",
      "duration": 60
    },
    "resources": [
      {
        "name": "Cancha Futbol 1",
        "description": "Cancha futbol 11",
        "services": [
          "Alquiler de Cancha de Fútbol"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Cancha Futbol 2",
        "description": "Cancha futbol 7",
        "services": [
          "Alquiler de Cancha de Fútbol"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Centro Deportivo",
    "commerce_name": "Deportes Total",
    "service": {
      "name": "Clase de Zumba",
      "description": "Clase de baile fitness para todos los niveles.",
      "price": 30,
      "details": "Usar ropa cómoda y zapatillas deportivas.",
      "needs_confirmation": "1",
      "capacity": "15",
      "duration": 50
    },
    "resources": [
      {
        "name": "Francisca Soto",
        "description": "Profesora Zumba",
        "services": [
          "Clase de Zumba"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 180
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Centro Deportivo",
    "commerce_name": "Deportes Total",
    "service": {
      "name": "Alquiler de Cancha de Padel",
      "description": "Renta de cancha de Padel por hora para partidos.",
      "price": 60,
      "details": "Reservar con anticipación, traer equipo deportivo.",
      "needs_confirmation": "1",
      "capacity": "3",
      "duration": 60
    },
    "resources": [
      {
        "name": "Cancha Padel 1",
        "description": "Cancha Sur",
        "services": [
          "Alquiler de Cancha de Padel"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      },
      {
        "name": "Cancha Padel 2",
        "description": "Cancha Norte",
        "services": [
          "Alquiler de Cancha de Padel"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Estudio de Pilates",
    "commerce_name": "Pilates Studio",
    "service": {
      "name": "Clase de Pilates",
      "description": "Sesión de ejercicios de Pilates para mejorar la flexibilidad y fuerza.",
      "price": 30,
      "details": "Traer esterilla propia o alquilar en el estudio.",
      "needs_confirmation": "1",
      "capacity": "12",
      "duration": 60
    },
    "resources": [
      {
        "name": "Marta López",
        "description": "Instructora de Pilates",
        "services": [
          "Clase de Pilates"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 180
          }
        ]
      },
      {
        "name": "Marta López",
        "description": "Instructora de Pilates",
        "services": [
          "Clase de Pilates"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Estudio de Pilates",
    "commerce_name": "Pilates Studio",
    "service": {
      "name": "Sesión de reformer",
      "description": "Ejercicio en máquina reformer para un entrenamiento más intenso.",
      "price": 40,
      "details": "No recomendable para principiantes.",
      "needs_confirmation": "1",
      "capacity": "6",
      "duration": 60
    },
    "resources": [
      {
        "name": "Marta Ruiz",
        "description": "Instructora de Pilates",
        "services": [
          "Sesión de reformer"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "12:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Estudio de Pilates",
    "commerce_name": "Pilates Studio",
    "service": {
      "name": "Pilates en suelo",
      "description": "Ejercicios de Pilates realizados en colchoneta.",
      "price": 30,
      "details": "Se requiere reserva previa.",
      "needs_confirmation": "1",
      "capacity": "12",
      "duration": 60
    },
    "resources": [
      {
        "name": "Ana Gómez",
        "description": "Instructora de Pilates",
        "services": [
          "Pilates en suelo"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "TUESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "WEDNESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "THURSDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "FRIDAY",
            "start": "14:00",
            "duration": 240
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Centro de Crossfit",
    "commerce_name": "CrossFit Zone",
    "service": {
      "name": "Clase de Crossfit",
      "description": "Entrenamiento de alta intensidad para mejorar la fuerza y resistencia.",
      "price": 30,
      "details": "Traer botella de agua y toalla personal.",
      "needs_confirmation": "1",
      "capacity": "12",
      "duration": 60
    },
    "resources": [
      {
        "name": "Guillermo Navarro",
        "description": "Entrenador Profesional",
        "services": [
          "Clase de Crossfit"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 180
          }
        ]
      },
      {
        "name": "Marco Ortega",
        "description": "Entrenador Profesiona",
        "services": [
          "Clase de Crossfit"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Centro de Crossfit",
    "commerce_name": "CrossFit Zone",
    "service": {
      "name": "Entrenamiento de fuerza",
      "description": "Programa enfocado en aumentar la fuerza muscular.",
      "price": 50,
      "details": "Informar sobre objetivos y condiciones físicas.",
      "needs_confirmation": "1",
      "capacity": "8",
      "duration": 60
    },
    "resources": [
      {
        "name": "Guillermo Navarro",
        "description": "Entrenador Profesional",
        "services": [
          "Entrenamiento de fuerza"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "12:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Centro de Crossfit",
    "commerce_name": "CrossFit Zone",
    "service": {
      "name": "Entrenamiento funcional",
      "description": "Ejercicios que mejoran el rendimiento en actividades diarias.",
      "price": 40,
      "details": "Traer calzado adecuado para levantamiento de pesas.",
      "needs_confirmation": "1",
      "capacity": "6",
      "duration": 60
    },
    "resources": [
      {
        "name": "Guillermo Navarro",
        "description": "Entrenador Profesional",
        "services": [
          "Entrenamiento funcional"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "TUESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "WEDNESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "THURSDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "FRIDAY",
            "start": "14:00",
            "duration": 240
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Yoga y Meditación",
    "commerce_name": "Yoga Harmony",
    "service": {
      "name": "Clase de yoga",
      "description": "Sesión de yoga para mejorar la flexibilidad y reducir el estrés.",
      "price": 25,
      "details": "Traer esterilla propia o alquilar en el estudio.",
      "needs_confirmation": "1",
      "capacity": "6",
      "duration": 60
    },
    "resources": [
      {
        "name": "Marta López",
        "description": "Instructora de Yoga",
        "services": [
          "Clase de yoga"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 180
          }
        ]
      },
      {
        "name": "Marta López",
        "description": "Instructora de Yoga",
        "services": [
          "Clase de yoga"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Yoga y Meditación",
    "commerce_name": "Yoga Harmony",
    "service": {
      "name": "Meditación guiada",
      "description": "Sesión de meditación dirigida para alcanzar la relajación mental.",
      "price": 20,
      "details": "No comer al menos 2 horas antes de la clase.",
      "needs_confirmation": "1",
      "capacity": "10",
      "duration": 30
    },
    "resources": [
      {
        "name": "Marta Ruiz",
        "description": "Instructora de Meditación",
        "services": [
          "Meditación guiada"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "12:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Yoga y Meditación",
    "commerce_name": "Yoga Harmony",
    "service": {
      "name": "Yoga prenatal",
      "description": "Clase de yoga especializada para mujeres embarazadas.",
      "price": 30,
      "details": "Usar ropa cómoda y traer manta si es necesario.",
      "needs_confirmation": "1",
      "capacity": "4",
      "duration": 60
    },
    "resources": [
      {
        "name": "Ana Gómez",
        "description": "Instructora de Yoga",
        "services": [
          "Yoga prenatal"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "TUESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "WEDNESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "THURSDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "FRIDAY",
            "start": "14:00",
            "duration": 240
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Gimnasio",
    "commerce_name": "FitnessPro",
    "service": {
      "name": "Entrenamiento personal",
      "description": "Sesión de entrenamiento personalizado en gimnasio.",
      "price": 50,
      "details": "Llevar toalla y calzado deportivo adecuado.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Guillermo Navarro",
        "description": "Entrenador Profesiona",
        "services": [
          "Entrenamiento personal"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 180
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Gimnasio",
    "commerce_name": "FitnessPro",
    "service": {
      "name": "Clase de spinning",
      "description": "Clases de ciclismo indoor de alta intensidad.",
      "price": 30,
      "details": "Traer botella de agua y toalla personal.",
      "needs_confirmation": "1",
      "capacity": "10",
      "duration": 60
    },
    "resources": [
      {
        "name": "Ignacia Pinto",
        "description": "Profesora Spinning",
        "services": [
          "Clase de spinning"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "12:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Gimnasio",
    "commerce_name": "FitnessPro",
    "service": {
      "name": "Sala de pesas",
      "description": "Zona de ejercicios con pesas para aumentar la masa muscular.",
      "price": 40,
      "details": "Usar ropa cómoda y zapatillas deportivas.",
      "needs_confirmation": "1",
      "capacity": "20",
      "duration": 60
    },
    "resources": [
      {
        "name": "Zona de Pesas",
        "description": "Espacio con aire Acondicionado",
        "services": [
          "Sala de pesas"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Entrenamiento Funcional",
    "commerce_name": "Functional Fit",
    "service": {
      "name": "Entrenamiento HIIT",
      "description": "Entrenamiento de intervalos de alta intensidad para quemar calorías.",
      "price": 40,
      "details": "Traer toalla y botella de agua.",
      "needs_confirmation": "1",
      "capacity": "10",
      "duration": 60
    },
    "resources": [
      {
        "name": "Pedro Jimenez",
        "description": "Entrenador Profesional",
        "services": [
          "Entrenamiento HIIT"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 180
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Entrenamiento Funcional",
    "commerce_name": "Functional Fit",
    "service": {
      "name": "Sesión de bootcamp",
      "description": "Entrenamiento intensivo en grupo para mejorar la condición física.",
      "price": 50,
      "details": "Reservar con anticipación.",
      "needs_confirmation": "1",
      "capacity": "10",
      "duration": 60
    },
    "resources": [
      {
        "name": "Pedro Jimenez",
        "description": "Entrenador Profesional",
        "services": [
          "Sesión de bootcamp"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "TUESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "WEDNESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "THURSDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "FRIDAY",
            "start": "14:00",
            "duration": 240
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Entrenamiento Funcional",
    "commerce_name": "Functional Fit",
    "service": {
      "name": "Evaluación física",
      "description": "Evaluación del estado físico antes de iniciar un programa de entrenamiento.",
      "price": 40,
      "details": "Informar sobre cualquier lesión o condición física previa.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Pedro Jimenez",
        "description": "Entrenador Profesional",
        "services": [
          "Evaluación física"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "12:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Danza / Baile",
    "commerce_name": "Dance Studio Passion",
    "service": {
      "name": "Clase de salsa",
      "description": "Sesión de baile para aprender o mejorar la técnica de salsa.",
      "price": 30,
      "details": "No se requiere pareja para participar.",
      "needs_confirmation": "1",
      "capacity": "10",
      "duration": 60
    },
    "resources": [
      {
        "name": "Marta López",
        "description": "Profesora Salsa",
        "services": [
          "Clase de salsa"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 180
          }
        ]
      },
      {
        "name": "Marco Ortega",
        "description": "Profesor Salsa",
        "services": [
          "Clase de salsa"
        ],
        "timeframes": [
          {
            "day": "SATURDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "SATURDAY",
            "start": "12:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Danza / Baile",
    "commerce_name": "Dance Studio Passion",
    "service": {
      "name": "Clase de ballet",
      "description": "Clase de ballet para principiantes o avanzados.",
      "price": 40,
      "details": "Traer zapatos y vestimenta adecuadas para practicar ballet",
      "needs_confirmation": "1",
      "capacity": "8",
      "duration": 60
    },
    "resources": [
      {
        "name": "Marta Ruiz",
        "description": "Profesora Ballet",
        "services": [
          "Clase de ballet"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "12:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Danza / Baile",
    "commerce_name": "Dance Studio Passion",
    "service": {
      "name": "Coreografía personalizada",
      "description": "Creación de coreografías personalizadas para eventos o competencias.",
      "price": 50,
      "details": "Usar ropa cómoda y zapatos de baile.",
      "needs_confirmation": "1",
      "capacity": "8",
      "duration": 120
    },
    "resources": [
      {
        "name": "Ana Gómez",
        "description": "Coreografa profesional",
        "services": [
          "Coreografía personalizada"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "TUESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "WEDNESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "THURSDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "FRIDAY",
            "start": "14:00",
            "duration": 240
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Electroestimulación",
    "commerce_name": "Electro Fitness",
    "service": {
      "name": "Sesión de electroestimulación",
      "description": "Entrenamiento que utiliza impulsos eléctricos para estimular los músculos.",
      "price": 60,
      "details": "No realizar actividades físicas intensas después del tratamiento.",
      "needs_confirmation": "1",
      "capacity": "4",
      "duration": 60
    },
    "resources": [
      {
        "name": "Andrea Huerta",
        "description": "Fisioterapeuta",
        "services": [
          "Sesión de electroestimulación"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 180
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Electroestimulación",
    "commerce_name": "Electro Fitness",
    "service": {
      "name": "Evaluación inicial",
      "description": "Evaluación para determinar la aptitud para electroestimulación.",
      "price": 50,
      "details": "Informar sobre cualquier condición médica previa.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Andrea Huerta",
        "description": "Fisioterapeuta",
        "services": [
          "Evaluación inicial"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "12:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "12:00",
            "duration": 120
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Electroestimulación",
    "commerce_name": "Electro Fitness",
    "service": {
      "name": "Rutina de recuperación",
      "description": "Programa de recuperación muscular post-entrenamiento.",
      "price": 60,
      "details": "Traer ropa cómoda y estar bien hidratado.",
      "needs_confirmation": "1",
      "capacity": "2",
      "duration": 60
    },
    "resources": [
      {
        "name": "Andrea Huerta",
        "description": "Fisioterapeuta",
        "services": [
          "Rutina de recuperación"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "TUESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "WEDNESDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "THURSDAY",
            "start": "14:00",
            "duration": 240
          },
          {
            "day": "FRIDAY",
            "start": "14:00",
            "duration": 240
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Personal Trainer",
    "commerce_name": "Ramón Fuenzalida",
    "service": {
      "name": "Sesión de entrenamiento personalizado",
      "description": "Entrenamiento individualizado para alcanzar objetivos específicos.",
      "price": 50,
      "details": "Informar sobre metas y limitaciones físicas.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Ramón Fuenzalida",
        "description": "Entrenador Profesional",
        "services": [
          "Sesión de entrenamiento personalizado"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Personal Trainer",
    "commerce_name": "Ramón Fuenzalida",
    "service": {
      "name": "Plan de entrenamiento",
      "description": "Creación de un plan de entrenamiento personalizado según las necesidades del cliente.",
      "price": 100,
      "details": "Traer ropa deportiva y estar bien descansado.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Ramón Fuenzalida",
        "description": "Entrenador Profesional",
        "services": [
          "Plan de entrenamiento"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Personal Trainer",
    "commerce_name": "Ramón Fuenzalida",
    "service": {
      "name": "Evaluación física",
      "description": "Evaluación inicial para diseñar un plan de entrenamiento adecuado.",
      "price": 50,
      "details": "Requiere compromiso de seguimiento regular.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 30
    },
    "resources": [
      {
        "name": "Ramón Fuenzalida",
        "description": "Entrenador Profesional",
        "services": [
          "Evaluación física"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Spa",
    "commerce_name": "Relaja & Rejuvenece",
    "service": {
      "name": "Masaje sueco",
      "description": "Masaje relajante para mejorar la circulación y aliviar la tensión muscular.",
      "price": 80,
      "details": "No comer en exceso antes del masaje.",
      "needs_confirmation": "1",
      "capacity": "2",
      "duration": 60
    },
    "resources": [
      {
        "name": "María Fernández",
        "description": "Masajista",
        "services": [
          "Masaje sueco"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Spa",
    "commerce_name": "Relaja & Rejuvenece",
    "service": {
      "name": "Tratamiento de piedras calientes",
      "description": "Masaje terapéutico con piedras calientes para aliviar el dolor y la tensión.",
      "price": 90,
      "details": "Traer ropa cómoda y estar bien hidratado.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "María Fernández",
        "description": "Masajista",
        "services": [
          "Tratamiento de piedras calientes"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Deporte y Bienestar",
    "business_type": "Spa",
    "commerce_name": "Relaja & Rejuvenece",
    "service": {
      "name": "Aromaterapia",
      "description": "Tratamiento de relajación con aceites esenciales aromáticos.",
      "price": 60,
      "details": "Evitar el uso de maquillaje antes del tratamiento.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 40
    },
    "resources": [
      {
        "name": "Claudia Rivas",
        "description": "Aromaterapeuta",
        "services": [
          "Aromaterapia"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Educación",
    "commerce_name": "Creativity Hub",
    "service": {
      "name": "Clase particular",
      "description": "Lección personalizada en una materia específica.",
      "price": 40,
      "details": "Informar sobre el tema y nivel previo de conocimiento.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Profesor A",
        "description": "",
        "services": [
          "Clase particular"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "8:00",
            "duration": 240
          },
          {
            "day": "TUESDAY",
            "start": "8:00",
            "duration": 240
          },
          {
            "day": "WEDNESDAY",
            "start": "8:00",
            "duration": 240
          },
          {
            "day": "THURSDAY",
            "start": "8:00",
            "duration": 240
          },
          {
            "day": "FRIDAY",
            "start": "8:00",
            "duration": 240
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Educación",
    "commerce_name": "Creativity Hub",
    "service": {
      "name": "Clase de guitarra",
      "description": "Clases de guitarra para todos los niveles, desde básico hasta avanzado.",
      "price": 40,
      "details": "Llevar guitarra propia.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Profesor C",
        "description": "",
        "services": [
          "Clase de guitarra"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "12:00",
            "duration": 240
          },
          {
            "day": "TUESDAY",
            "start": "12:00",
            "duration": 240
          },
          {
            "day": "WEDNESDAY",
            "start": "12:00",
            "duration": 240
          },
          {
            "day": "THURSDAY",
            "start": "12:00",
            "duration": 240
          },
          {
            "day": "FRIDAY",
            "start": "12:00",
            "duration": 240
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Educación",
    "commerce_name": "Creativity Hub",
    "service": {
      "name": "Clase de fotografía",
      "description": "Clases de fotografía para aprender composición, iluminación y edición básica.",
      "price": 45,
      "details": "Traer cámara propia.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Profesor E",
        "description": "",
        "services": [
          "Clase de fotografía"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "16:00",
            "duration": 240
          },
          {
            "day": "TUESDAY",
            "start": "16:00",
            "duration": 240
          },
          {
            "day": "WEDNESDAY",
            "start": "16:00",
            "duration": 240
          },
          {
            "day": "THURSDAY",
            "start": "16:00",
            "duration": 240
          },
          {
            "day": "FRIDAY",
            "start": "16:00",
            "duration": 240
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Estudio Jurídico",
    "commerce_name": "Legal Solutions",
    "service": {
      "name": "Consulta legal",
      "description": "Asesoría jurídica sobre problemas legales.",
      "price": 150,
      "details": "Traer documentos relevantes al caso.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Fernando Silva",
        "description": "Abogado",
        "services": [
          "Consulta legal"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Estudio Jurídico",
    "commerce_name": "Legal Solutions",
    "service": {
      "name": "Redacción de documentos legales",
      "description": "Servicio de redacción y revisión de documentos legales.",
      "price": 200,
      "details": "Confirmar el alcance del asesoramiento requerido.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 120
    },
    "resources": [
      {
        "name": "Fernando Silva",
        "description": "Abogado",
        "services": [
          "Redacción de documentos legales"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Estudio Jurídico",
    "commerce_name": "Legal Solutions",
    "service": {
      "name": "Representación en juicio",
      "description": "Servicio de defensa legal en tribunales.",
      "price": 1000,
      "details": "Proveer toda la información necesaria para la redacción.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 360
    },
    "resources": [
      {
        "name": "Fernando Silva",
        "description": "Abogado",
        "services": [
          "Representación en juicio"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Industria automotriz",
    "commerce_name": "Auto Care Center",
    "service": {
      "name": "Servicio de mantenimiento básico",
      "description": "Cambio de aceite, filtro y revisión general del vehículo.",
      "price": 50,
      "details": "Traer el vehículo con el tanque de combustible lleno.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Patricio Carrasco",
        "description": "Equipo mantención",
        "services": [
          "Servicio de mantenimiento básico"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Industria automotriz",
    "commerce_name": "Auto Care Center",
    "service": {
      "name": "Diagnóstico de reparación",
      "description": "Diagnóstico para reparación de problemas en el motor del vehículo.",
      "price": 100,
      "details": "Informar sobre los síntomas de falla antes de la reparación.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Roberto Andrade",
        "description": "Mecánico",
        "services": [
          "Diagnóstico de reparación"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Industria automotriz",
    "commerce_name": "Auto Care Center",
    "service": {
      "name": "Alineación",
      "description": "Servicio de Alineación ",
      "price": 30,
      "details": "Traer el vehículo limpio para facilitar el servicio.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Andres Salaz",
        "description": "Equipo Alineación",
        "services": [
          "Alineación"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Otro",
    "commerce_name": "Expert Advisors",
    "service": {
      "name": "Consultoría General",
      "description": "Asesoramiento en diversas áreas de interés empresarial o personal.",
      "price": 120,
      "details": "Especificar el área de consultoría al solicitar la cita.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Josefina Carreño",
        "description": "Consultora",
        "services": [
          "Consultoría General"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Otro",
    "commerce_name": "Language Solutions",
    "service": {
      "name": "Servicios de Traducción",
      "description": "Traducción de documentos en Inglés y Portugués",
      "price": 100,
      "details": "Proveer documentos en formato editable si es posible.",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Pedro Lopez",
        "description": "Traductor",
        "services": [
          "Servicios de Traducción"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  },
  {
    "industry_type": "Otros",
    "business_type": "Otro",
    "commerce_name": "Event Planners Pro",
    "service": {
      "name": "Organización de Eventos",
      "description": "Planificación y coordinación de eventos especiales.",
      "price": 60,
      "details": "En reunión se debe definir el tipo de evento y requisitos para presupuesto",
      "needs_confirmation": "0",
      "capacity": "",
      "duration": 60
    },
    "resources": [
      {
        "name": "Marta Calderon",
        "description": "Event Planner",
        "services": [
          "Organización de Eventos"
        ],
        "timeframes": [
          {
            "day": "MONDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "TUESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "WEDNESDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "THURSDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "FRIDAY",
            "start": "9:00",
            "duration": 120
          },
          {
            "day": "MONDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "TUESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "WEDNESDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "THURSDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "FRIDAY",
            "start": "13:00",
            "duration": 180
          },
          {
            "day": "MONDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "TUESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "WEDNESDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "THURSDAY",
            "start": "17:00",
            "duration": 60
          },
          {
            "day": "FRIDAY",
            "start": "17:00",
            "duration": 60
          }
        ]
      }
    ],
    "commerce_timeframes": [
      {
        "day": "MONDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "TUESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "WEDNESDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "THURSDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "FRIDAY",
        "start": "9:00",
        "duration": 600
      },
      {
        "day": "SATURDAY",
        "start": "9:00",
        "duration": 240
      }
    ]
  }
];
