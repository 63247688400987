export const countries = [
  {
    code: "AD",
    label: { en: "Andorra", es: "Andorra", pt: "Andorra" },
    phone: "376",
    title: "Andorra",
    emoji: "🇦🇩",
    unicode: "U+1F1E6 U+1F1E9",
    name: "Andorra",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "AE",
    label: {
      en: "United Arab Emirates",
      es: "Emiratos Árabes Unidos",
      pt: "Emirados Árabes Unidos",
    },
    phone: "971",
    title: "United Arab Emirates",
    emoji: "🇦🇪",
    unicode: "U+1F1E6 U+1F1EA",
    name: "United Arab Emirates",
    currency: "UAE Dirham",
    currencyCode: "AED",
    currencyId: "784",
  },
  {
    code: "AF",
    label: { en: "Afghanistan", es: "Afganistán", pt: "Afeganistão" },
    phone: "93",
    title: "Afghanistan",
    emoji: "🇦🇫",
    unicode: "U+1F1E6 U+1F1EB",
    name: "Afghanistan",
    currency: "Afghani",
    currencyCode: "AFN",
    currencyId: "971",
  },
  {
    code: "AG",
    label: {
      en: "Antigua and Barbuda",
      es: "Antigua y Barbuda",
      pt: "Antígua e Barbuda",
    },
    phone: "1-268",
    title: "Antigua and Barbuda",
    emoji: "🇦🇬",
    unicode: "U+1F1E6 U+1F1EC",
    name: "Antigua and Barbuda",
    currency: "East Caribbean Dollar",
    currencyCode: "XCD",
    currencyId: "951",
  },
  {
    code: "AI",
    label: { en: "Anguilla", es: "Anguila", pt: "Anguilla" },
    phone: "1-264",
    title: "Anguilla",
    emoji: "🇦🇮",
    unicode: "U+1F1E6 U+1F1EE",
    name: "Anguilla",
    currency: "East Caribbean Dollar",
    currencyCode: "XCD",
    currencyId: "951",
  },
  {
    code: "AL",
    label: { en: "Albania", es: "Albania", pt: "Albânia" },
    phone: "355",
    title: "Albania",
    emoji: "🇦🇱",
    unicode: "U+1F1E6 U+1F1F1",
    name: "Albania",
    currency: "Lek",
    currencyCode: "ALL",
    currencyId: "008",
  },
  {
    code: "AM",
    label: { en: "Armenia", es: "Armenia", pt: "Arménia" },
    phone: "374",
    title: "Armenia",
    emoji: "🇦🇲",
    unicode: "U+1F1E6 U+1F1F2",
    name: "Armenia",
    currency: "Armenian Dram",
    currencyCode: "AMD",
    currencyId: "051",
  },
  {
    code: "AO",
    label: { en: "Angola", es: "Angola", pt: "Angola" },
    phone: "244",
    title: "Angola",
    emoji: "🇦🇴",
    unicode: "U+1F1E6 U+1F1F4",
    name: "Angola",
    currency: "Kwanza",
    currencyCode: "AOA",
    currencyId: "973",
  },
  {
    code: "AQ",
    label: { en: "Antarctica", es: "Antártida", pt: "Antártica" },
    phone: "672",
    title: "Antarctica",
    emoji: "🇦🇶",
    unicode: "U+1F1E6 U+1F1F6",
    name: "Antarctica",
    currency: "No universal currency",
    currencyCode: "",
    currencyId: "",
  },
  {
    code: "AR",
    label: { en: "Argentina", es: "Argentina", pt: "Argentina" },
    phone: "54",
    title: "Argentina",
    emoji: "🇦🇷",
    unicode: "U+1F1E6 U+1F1F7",
    name: "Argentina",
    currency: "Argentine Peso",
    currencyCode: "ARS",
    currencyId: "032",
  },
  {
    code: "AS",
    label: {
      en: "American Samoa",
      es: "Samoa Americana",
      pt: "Samoa Americana",
    },
    phone: "1-684",
    title: "American Samoa",
    emoji: "🇦🇸",
    unicode: "U+1F1E6 U+1F1F8",
    name: "American Samoa",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "AT",
    label: { en: "Austria", es: "Austria", pt: "Áustria" },
    phone: "43",
    title: "Austria",
    emoji: "🇦🇹",
    unicode: "U+1F1E6 U+1F1F9",
    name: "Austria",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "AU",
    label: { en: "Australia", es: "Australia", pt: "Austrália" },
    phone: "61",
    title: "Australia",
    emoji: "🇦🇺",
    unicode: "U+1F1E6 U+1F1FA",
    name: "Australia",
    currency: "Australian Dollar",
    currencyCode: "AUD",
    currencyId: "036",
  },
  {
    code: "AW",
    label: { en: "Aruba", es: "Aruba", pt: "Aruba" },
    phone: "297",
    title: "Aruba",
    emoji: "🇦🇼",
    unicode: "U+1F1E6 U+1F1FC",
    name: "Aruba",
    currency: "Aruban Florin",
    currencyCode: "AWG",
    currencyId: "533",
  },
  {
    code: "AX",
    label: { en: "Åland Islands", es: "Islas Åland", pt: "Ilhas Åland" },
    phone: "358",
    title: "Åland Islands",
    emoji: "🇦🇽",
    unicode: "U+1F1E6 U+1F1FD",
    name: "Åland Islands",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "AZ",
    label: { en: "Azerbaijan", es: "Azerbaiyán", pt: "Azerbaijão" },
    phone: "994",
    title: "Azerbaijan",
    emoji: "🇦🇿",
    unicode: "U+1F1E6 U+1F1FF",
    name: "Azerbaijan",
    currency: "Azerbaijan Manat",
    currencyCode: "AZN",
    currencyId: "944",
  },
  {
    code: "BA",
    label: {
      en: "Bosnia and Herzegovina",
      es: "Bosnia y Herzegovina",
      pt: "Bósnia e Herzegovina",
    },
    phone: "387",
    title: "Bosnia and Herzegovina",
    emoji: "🇧🇦",
    unicode: "U+1F1E7 U+1F1E6",
    name: "Bosnia and Herzegovina",
    currency: "Convertible Mark",
    currencyCode: "BAM",
    currencyId: "977",
  },
  {
    code: "BB",
    label: { en: "Barbados", es: "Barbados", pt: "Barbados" },
    phone: "1-246",
    title: "Barbados",
    emoji: "🇧🇧",
    unicode: "U+1F1E7 U+1F1E7",
    name: "Barbados",
    currency: "Barbadian Dollar",
    currencyCode: "BBD",
    currencyId: "052",
  },
  {
    code: "BD",
    label: { en: "Bangladesh", es: "Bangladés", pt: "Bangladesh" },
    phone: "880",
    title: "Bangladesh",
    emoji: "🇧🇩",
    unicode: "U+1F1E7 U+1F1E9",
    name: "Bangladesh",
    currency: "Bangladeshi Taka",
    currencyCode: "BDT",
    currencyId: "050",
  },
  {
    code: "BE",
    label: { en: "Belgium", es: "Bélgica", pt: "Bélgica" },
    phone: "32",
    title: "Belgium",
    emoji: "🇧🇪",
    unicode: "U+1F1E7 U+1F1EA",
    name: "Belgium",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "BF",
    label: { en: "Burkina Faso", es: "Burkina Faso", pt: "Burquina Faso" },
    phone: "226",
    title: "Burkina Faso",
    emoji: "🇧🇫",
    unicode: "U+1F1E7 U+1F1EB",
    name: "Burkina Faso",
    currency: "West African CFA franc",
    currencyCode: "XOF",
    currencyId: "952",
  },
  {
    code: "BG",
    label: { en: "Bulgaria", es: "Bulgaria", pt: "Bulgária" },
    phone: "359",
    title: "Bulgaria",
    emoji: "🇧🇬",
    unicode: "U+1F1E7 U+1F1EC",
    name: "Bulgaria",
    currency: "Bulgarian Lev",
    currencyCode: "BGN",
    currencyId: "975",
  },
  {
    code: "BH",
    label: { en: "Bahrain", es: "Baréin", pt: "Barém" },
    phone: "973",
    title: "Bahrain",
    emoji: "🇧🇭",
    unicode: "U+1F1E7 U+1F1ED",
    name: "Bahrain",
    currency: "Bahraini Dinar",
    currencyCode: "BHD",
    currencyId: "048",
  },
  {
    code: "BI",
    label: { en: "Burundi", es: "Burundi", pt: "Burundi" },
    phone: "257",
    title: "Burundi",
    emoji: "🇧🇮",
    unicode: "U+1F1E7 U+1F1EE",
    name: "Burundi",
    currency: "Burundian Franc",
    currencyCode: "BIF",
    currencyId: "108",
  },
  {
    code: "BJ",
    label: { en: "Benin", es: "Benín", pt: "Benim" },
    phone: "229",
    title: "Benin",
    emoji: "🇧🇯",
    unicode: "U+1F1E7 U+1F1EF",
    name: "Benin",
    currency: "West African CFA franc",
    currencyCode: "XOF",
    currencyId: "952",
  },
  {
    code: "BL",
    label: {
      en: "Saint Barthélemy",
      es: "San Bartolomé",
      pt: "São Bartolomeu",
    },
    phone: "590",
    title: "Saint Barthélemy",
    emoji: "🇧🇱",
    unicode: "U+1F1E7 U+1F1F1",
    name: "Saint Barthélemy",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "BM",
    label: { en: "Bermuda", es: "Bermudas", pt: "Bermudas" },
    phone: "1-441",
    title: "Bermuda",
    emoji: "🇧🇲",
    unicode: "U+1F1E7 U+1F1F2",
    name: "Bermuda",
    currency: "Bermudian Dollar",
    currencyCode: "BMD",
    currencyId: "060",
  },
  {
    code: "BN",
    label: { en: "Brunei", es: "Brunéi", pt: "Brunei" },
    phone: "673",
    title: "Brunei Darussalam",
    emoji: "🇧🇳",
    unicode: "U+1F1E7 U+1F1F3",
    name: "Brunei",
    currency: "Brunei Dollar",
    currencyCode: "BND",
    currencyId: "096",
  },
  {
    code: "BO",
    label: { en: "Bolivia", es: "Bolivia", pt: "Bolívia" },
    phone: "591",
    title: "Bolivia",
    emoji: "🇧🇴",
    unicode: "U+1F1E7 U+1F1F4",
    name: "Bolivia",
    currency: "Bolivian Boliviano",
    currencyCode: "BOB",
    currencyId: "068",
  },
  {
    code: "BQ",
    label: {
      en: "Bonaire, Sint Eustatius and Saba",
      es: "Bonaire, San Eustaquio y Saba",
      pt: "Bonaire, Santo Eustáquio e Saba",
    },
    phone: "599",
    title: "Bonaire",
    emoji: "🇧🇶",
    unicode: "U+1F1E7 U+1F1F6",
    name: "Bonaire, Sint Eustatius and Saba",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "BR",
    label: { en: "Brazil", es: "Brasil", pt: "Brasil" },
    phone: "55",
    title: "Brazil",
    emoji: "🇧🇷",
    unicode: "U+1F1E7 U+1F1F7",
    name: "Brazil",
    currency: "Brazilian Real",
    currencyCode: "BRL",
    currencyId: "986",
  },
  {
    code: "BS",
    label: { en: "Bahamas", es: "Bahamas", pt: "Bahamas" },
    phone: "1-242",
    title: "Bahamas",
    emoji: "🇧🇸",
    unicode: "U+1F1E7 U+1F1F8",
    name: "Bahamas",
    currency: "Bahamian Dollar",
    currencyCode: "BSD",
    currencyId: "044",
  },
  {
    code: "BT",
    label: { en: "Bhutan", es: "Bután", pt: "Butão" },
    phone: "975",
    title: "Bhutan",
    emoji: "🇧🇹",
    unicode: "U+1F1E7 U+1F1F9",
    name: "Bhutan",
    currency: "Bhutanese Ngultrum",
    currencyCode: "BTN",
    currencyId: "064",
  },
  {
    code: "BV",
    label: { en: "Bouvet Island", es: "Isla Bouvet", pt: "Ilha Bouvet" },
    phone: "47",
    title: "Bouvet Island",
    emoji: "🇧🇻",
    unicode: "U+1F1E7 U+1F1FB",
    name: "Bouvet Island",
    currency: "Norwegian Krone",
    currencyCode: "NOK",
    currencyId: "578",
  },
  {
    code: "BW",
    label: { en: "Botswana", es: "Botsuana", pt: "Botsuana" },
    phone: "267",
    title: "Botswana",
    emoji: "🇧🇼",
    unicode: "U+1F1E7 U+1F1FC",
    name: "Botswana",
    currency: "Botswana Pula",
    currencyCode: "BWP",
    currencyId: "072",
  },
  {
    code: "BY",
    label: { en: "Belarus", es: "Bielorrusia", pt: "Bielorrússia" },
    phone: "375",
    title: "Belarus",
    emoji: "🇧🇾",
    unicode: "U+1F1E7 U+1F1FE",
    name: "Belarus",
    currency: "Belarusian Ruble",
    currencyCode: "BYN",
    currencyId: "933",
  },
  {
    code: "BZ",
    label: { en: "Belize", es: "Belice", pt: "Belize" },
    phone: "501",
    title: "Belize",
    emoji: "🇧🇿",
    unicode: "U+1F1E7 U+1F1FF",
    name: "Belize",
    currency: "Belize Dollar",
    currencyCode: "BZD",
    currencyId: "084",
  },
  {
    code: "CA",
    label: { en: "Canada", es: "Canadá", pt: "Canadá" },
    phone: "1",
    title: "Canada",
    emoji: "🇨🇦",
    unicode: "U+1F1E8 U+1F1E6",
    name: "Canada",
    currency: "Canadian Dollar",
    currencyCode: "CAD",
    currencyId: "124",
  },
  {
    code: "CC",
    label: {
      en: "Cocos (Keeling) Islands",
      es: "Islas Cocos (Keeling)",
      pt: "Ilhas Cocos (Keeling)",
    },
    phone: "61",
    title: "Cocos (Keeling) Islands",
    emoji: "🇨🇨",
    unicode: "U+1F1E8 U+1F1E8",
    name: "Cocos (Keeling) Islands",
    currency: "Australian Dollar",
    currencyCode: "AUD",
    currencyId: "036",
  },
  {
    code: "CD",
    label: {
      en: "Congo, Democratic Republic of the",
      es: "Congo, República Democrática del",
      pt: "Congo, República Democrática do",
    },
    phone: "243",
    title: "Congo, Democratic Republic",
    emoji: "🇨🇩",
    unicode: "U+1F1E8 U+1F1E9",
    name: "Congo (Democratic Republic of the)",
    currency: "Congolese Franc",
    currencyCode: "CDF",
    currencyId: "976",
  },
  {
    code: "CF",
    label: {
      en: "Central African Republic",
      es: "República Centroafricana",
      pt: "República Centro-Africana",
    },
    phone: "236",
    title: "Central African Republic",
    emoji: "🇨🇫",
    unicode: "U+1F1E8 U+1F1EB",
    name: "Central African Republic",
    currency: "CFA Franc BEAC",
    currencyCode: "XAF",
    currencyId: "950",
  },
  {
    code: "CG",
    label: {
      en: "Congo, Republic of the",
      es: "Congo, República del",
      pt: "Congo, República do",
    },
    phone: "242",
    title: "Congo",
    emoji: "🇨🇬",
    unicode: "U+1F1E8 U+1F1EC",
    name: "Congo (Republic of the)",
    currency: "CFA Franc BEAC",
    currencyCode: "XAF",
    currencyId: "950",
  },
  {
    code: "CH",
    label: { en: "Switzerland", es: "Suiza", pt: "Suíça" },
    phone: "41",
    title: "Switzerland",
    emoji: "🇨🇭",
    unicode: "U+1F1E8 U+1F1ED",
    name: "Switzerland",
    currency: "Swiss Franc",
    currencyCode: "CHF",
    currencyId: "756",
  },
  {
    code: "CI",
    label: {
      en: "Côte d'Ivoire",
      es: "Costa de Marfil",
      pt: "Costa do Marfim",
    },
    phone: "225",
    title: "Côte d'Ivoire",
    emoji: "🇨🇮",
    unicode: "U+1F1E8 U+1F1EE",
    name: "Côte d'Ivoire",
    currency: "West African CFA franc",
    currencyCode: "XOF",
    currencyId: "952",
  },
  {
    code: "CK",
    label: { en: "Cook Islands", es: "Islas Cook", pt: "Ilhas Cook" },
    phone: "682",
    title: "Cook Islands",
    emoji: "🇨🇰",
    unicode: "U+1F1E8 U+1F1F0",
    name: "Cook Islands",
    currency: "New Zealand Dollar",
    currencyCode: "NZD",
    currencyId: "554",
  },
  {
    code: "CL",
    label: { en: "Chile", es: "Chile", pt: "Chile" },
    phone: "56",
    title: "Chile",
    emoji: "🇨🇱",
    unicode: "U+1F1E8 U+1F1F1",
    name: "Chile",
    currency: "Chilean Peso",
    currencyCode: "CLP",
    currencyId: "152",
  },
  {
    code: "CM",
    label: { en: "Cameroon", es: "Camerún", pt: "Camarões" },
    phone: "237",
    title: "Cameroon",
    emoji: "🇨🇲",
    unicode: "U+1F1E8 U+1F1F2",
    name: "Cameroon",
    currency: "Central African CFA franc",
    currencyCode: "XAF",
    currencyId: "950",
  },
  {
    code: "CN",
    label: { en: "China", es: "China", pt: "China" },
    phone: "86",
    title: "China",
    emoji: "🇨🇳",
    unicode: "U+1F1E8 U+1F1F3",
    name: "China",
    currency: "Renminbi (Yuan)",
    currencyCode: "CNY",
    currencyId: "156",
  },
  {
    code: "CO",
    label: { en: "Colombia", es: "Colombia", pt: "Colômbia" },
    phone: "57",
    title: "Colombia",
    emoji: "🇨🇴",
    unicode: "U+1F1E8 U+1F1F4",
    name: "Colombia",
    currency: "Colombian Peso",
    currencyCode: "COP",
    currencyId: "170",
  },
  {
    code: "CR",
    label: { en: "Costa Rica", es: "Costa Rica", pt: "Costa Rica" },
    phone: "506",
    title: "Costa Rica",
    emoji: "🇨🇷",
    unicode: "U+1F1E8 U+1F1F7",
    name: "Costa Rica",
    currency: "Costa Rican Colón",
    currencyCode: "CRC",
    currencyId: "188",
  },
  {
    code: "CU",
    label: { en: "Cuba", es: "Cuba", pt: "Cuba" },
    phone: "53",
    title: "Cuba",
    emoji: "🇨🇺",
    unicode: "U+1F1E8 U+1F1FA",
    name: "Cuba",
    currency: "Cuban Peso",
    currencyCode: "CUP",
    currencyId: "192",
  },
  {
    code: "CV",
    label: { en: "Cape Verde", es: "Cabo Verde", pt: "Cabo Verde" },
    phone: "238",
    title: "Cape Verde",
    emoji: "🇨🇻",
    unicode: "U+1F1E8 U+1F1FB",
    name: "Cape Verde",
    currency: "Cape Verdean Escudo",
    currencyCode: "CVE",
    currencyId: "132",
  },
  {
    code: "CW",
    label: { en: "Curaçao", es: "Curazao", pt: "Curaçao" },
    phone: "599",
    title: "Curaçao",
    emoji: "🇨🇼",
    unicode: "U+1F1E8 U+1F1FC",
    name: "Curaçao",
    currency: "Netherlands Antillean Guilder",
    currencyCode: "ANG",
    currencyId: "532",
  },
  {
    code: "CX",
    label: {
      en: "Christmas Island",
      es: "Isla de Navidad",
      pt: "Ilha Christmas",
    },
    phone: "61",
    title: "Christmas Island",
    emoji: "🇨🇽",
    unicode: "U+1F1E8 U+1F1FD",
    name: "Christmas Island",
    currency: "Australian Dollar",
    currencyCode: "AUD",
    currencyId: "036",
  },
  {
    code: "CY",
    label: { en: "Cyprus", es: "Chipre", pt: "Chipre" },
    phone: "357",
    title: "Cyprus",
    emoji: "🇨🇾",
    unicode: "U+1F1E8 U+1F1FE",
    name: "Cyprus",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "CZ",
    label: {
      en: "Czech Republic",
      es: "República Checa",
      pt: "República Checa",
    },
    phone: "420",
    title: "Czech Republic",
    emoji: "🇨🇿",
    unicode: "U+1F1E8 U+1F1FF",
    name: "Czech Republic",
    currency: "Czech Koruna",
    currencyCode: "CZK",
    currencyId: "203",
  },
  {
    code: "DE",
    label: { en: "Germany", es: "Alemania", pt: "Alemanha" },
    phone: "49",
    title: "Germany",
    emoji: "🇩🇪",
    unicode: "U+1F1E9 U+1F1EA",
    name: "Germany",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "DJ",
    label: { en: "Djibouti", es: "Yibuti", pt: "Djibouti" },
    phone: "253",
    title: "Djibouti",
    emoji: "🇩🇯",
    unicode: "U+1F1E9 U+1F1EF",
    name: "Djibouti",
    currency: "Djiboutian Franc",
    currencyCode: "DJF",
    currencyId: "262",
  },
  {
    code: "DK",
    label: { en: "Denmark", es: "Dinamarca", pt: "Dinamarca" },
    phone: "45",
    title: "Denmark",
    emoji: "🇩🇰",
    unicode: "U+1F1E9 U+1F1F0",
    name: "Denmark",
    currency: "Danish Krone",
    currencyCode: "DKK",
    currencyId: "208",
  },
  {
    code: "DM",
    label: { en: "Dominica", es: "Dominica", pt: "Dominica" },
    phone: "1-767",
    title: "Dominica",
    emoji: "🇩🇲",
    unicode: "U+1F1E9 U+1F1F2",
    name: "Dominica",
    currency: "East Caribbean Dollar",
    currencyCode: "XCD",
    currencyId: "951",
  },
  {
    code: "DO",
    label: {
      en: "Dominican Republic",
      es: "República Dominicana",
      pt: "República Dominicana",
    },
    phone: "1-809, 1-829, 1-849",
    title: "Dominican Republic",
    emoji: "🇩🇴",
    unicode: "U+1F1E9 U+1F1F4",
    name: "Dominican Republic",
    currency: "Dominican Peso",
    currencyCode: "DOP",
    currencyId: "214",
  },
  {
    code: "DZ",
    label: { en: "Algeria", es: "Argelia", pt: "Argélia" },
    phone: "213",
    title: "Algeria",
    emoji: "🇩🇿",
    unicode: "U+1F1E9 U+1F1FF",
    name: "Algeria",
    currency: "Algerian Dinar",
    currencyCode: "DZD",
    currencyId: "012",
  },
  {
    code: "EC",
    label: { en: "Ecuador", es: "Ecuador", pt: "Equador" },
    phone: "593",
    title: "Ecuador",
    emoji: "🇪🇨",
    unicode: "U+1F1EA U+1F1E8",
    name: "Ecuador",
    currency: "United States Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "EE",
    label: { en: "Estonia", es: "Estonia", pt: "Estónia" },
    phone: "372",
    title: "Estonia",
    emoji: "🇪🇪",
    unicode: "U+1F1EA U+1F1EA",
    name: "Estonia",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "EG",
    label: { en: "Egypt", es: "Egipto", pt: "Egito" },
    phone: "20",
    title: "Egypt",
    emoji: "🇪🇬",
    unicode: "U+1F1EA U+1F1EC",
    name: "Egypt",
    currency: "Egyptian Pound",
    currencyCode: "EGP",
    currencyId: "818",
  },
  {
    code: "EH",
    label: {
      en: "Western Sahara",
      es: "Sahara Occidental",
      pt: "Saara Ocidental",
    },
    phone: "",
    title: "Western Sahara",
    emoji: "🇪🇭",
    unicode: "U+1F1EA U+1F1ED",
    name: "Western Sahara",
    currency: "Moroccan Dirham",
    currencyCode: "MAD",
    currencyId: "504",
  },
  {
    code: "ER",
    label: { en: "Eritrea", es: "Eritrea", pt: "Eritreia" },
    phone: "291",
    title: "Eritrea",
    emoji: "🇪🇷",
    unicode: "U+1F1EA U+1F1F7",
    name: "Eritrea",
    currency: "Eritrean Nakfa",
    currencyCode: "ERN",
    currencyId: "232",
  },
  {
    code: "ES",
    label: { en: "Spain", es: "España", pt: "Espanha" },
    phone: "34",
    title: "Spain",
    emoji: "🇪🇸",
    unicode: "U+1F1EA U+1F1F8",
    name: "Spain",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "ET",
    label: { en: "Ethiopia", es: "Etiopía", pt: "Etiópia" },
    phone: "251",
    title: "Ethiopia",
    emoji: "🇪🇹",
    unicode: "U+1F1EA U+1F1F9",
    name: "Ethiopia",
    currency: "Ethiopian Birr",
    currencyCode: "ETB",
    currencyId: "230",
  },
  {
    code: "FI",
    label: { en: "Finland", es: "Finlandia", pt: "Finlândia" },
    phone: "358",
    title: "Finland",
    emoji: "🇫🇮",
    unicode: "U+1F1EB U+1F1EE",
    name: "Finland",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "FJ",
    label: { en: "Fiji", es: "Fiyi", pt: "Fiji" },
    phone: "679",
    title: "Fiji",
    emoji: "🇫🇯",
    unicode: "U+1F1EB U+1F1EF",
    name: "Fiji",
    currency: "Fijian Dollar",
    currencyCode: "FJD",
    currencyId: "242",
  },
  {
    code: "FK",
    label: {
      en: "Falkland Islands (Malvinas)",
      es: "Islas Malvinas",
      pt: "Ilhas Malvinas",
    },
    phone: "500",
    title: "Falkland Islands",
    emoji: "🇫🇰",
    unicode: "U+1F1EB U+1F1F0",
    name: "Falkland Islands",
    currency: "Falkland Islands Pound",
    currencyCode: "FKP",
    currencyId: "238",
  },
  {
    code: "FM",
    label: {
      en: "Micronesia, Federated States of",
      es: "Micronesia, Estados Federados de",
      pt: "Micronésia, Estados Federados da",
    },
    phone: "691",
    title: "Micronesia",
    emoji: "🇫🇲",
    unicode: "U+1F1EB U+1F1F2",
    name: "Micronesia",
    currency: "United States Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "FO",
    label: { en: "Faroe Islands", es: "Islas Feroe", pt: "Ilhas Faroé" },
    phone: "298",
    title: "Faroe Islands",
    emoji: "🇫🇴",
    unicode: "U+1F1EB U+1F1F4",
    name: "Faroe Islands",
    currency: "Danish Krone",
    currencyCode: "DKK",
    currencyId: "208",
  },
  {
    code: "FR",
    label: { en: "France", es: "Francia", pt: "França" },
    phone: "33",
    title: "France",
    emoji: "🇫🇷",
    unicode: "U+1F1EB U+1F1F7",
    name: "France",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "GA",
    label: { en: "Gabon", es: "Gabón", pt: "Gabão" },
    phone: "241",
    title: "Gabon",
    emoji: "🇬🇦",
    unicode: "U+1F1EC U+1F1E6",
    name: "Gabon",
    currency: "Central African CFA franc",
    currencyCode: "XAF",
    currencyId: "950",
  },
  {
    code: "GB",
    label: { en: "United Kingdom", es: "Reino Unido", pt: "Reino Unido" },
    phone: "44",
    title: "United Kingdom",
    emoji: "🇬🇧",
    unicode: "U+1F1EC U+1F1E7",
    name: "United Kingdom",
    currency: "British Pound",
    currencyCode: "GBP",
    currencyId: "826",
  },
  {
    code: "GD",
    label: { en: "Grenada", es: "Granada", pt: "Granada" },
    phone: "1-473",
    title: "Grenada",
    emoji: "🇬🇩",
    unicode: "U+1F1EC U+1F1E9",
    name: "Grenada",
    currency: "East Caribbean Dollar",
    currencyCode: "XCD",
    currencyId: "951",
  },
  {
    code: "GE",
    label: { en: "Georgia", es: "Georgia", pt: "Geórgia" },
    phone: "995",
    title: "Georgia",
    emoji: "🇬🇪",
    unicode: "U+1F1EC U+1F1EA",
    name: "Georgia",
    currency: "Georgian Lari",
    currencyCode: "GEL",
    currencyId: "981",
  },
  {
    code: "GF",
    label: {
      en: "French Guiana",
      es: "Guayana Francesa",
      pt: "Guiana Francesa",
    },
    phone: "594",
    title: "French Guiana",
    emoji: "🇬🇫",
    unicode: "U+1F1EC U+1F1EB",
    name: "French Guiana",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "GG",
    label: { en: "Guernsey", es: "Guernsey", pt: "Guernsey" },
    phone: "44",
    title: "Guernsey",
    emoji: "🇬🇬",
    unicode: "U+1F1EC U+1F1EC",
    name: "Guernsey",
    currency: "British Pound",
    currencyCode: "GBP",
    currencyId: "826",
  },
  {
    code: "GH",
    label: { en: "Ghana", es: "Ghana", pt: "Gana" },
    phone: "233",
    title: "Ghana",
    emoji: "🇬🇭",
    unicode: "U+1F1EC U+1F1ED",
    name: "Ghana",
    currency: "Ghanaian Cedi",
    currencyCode: "GHS",
    currencyId: "936",
  },
  {
    code: "GI",
    label: { en: "Gibraltar", es: "Gibraltar", pt: "Gibraltar" },
    phone: "350",
    title: "Gibraltar",
    emoji: "🇬🇮",
    unicode: "U+1F1EC U+1F1EE",
    name: "Gibraltar",
    currency: "Gibraltar Pound",
    currencyCode: "GIP",
    currencyId: "292",
  },
  {
    code: "GL",
    label: { en: "Greenland", es: "Groenlandia", pt: "Groenlândia" },
    phone: "299",
    title: "Greenland",
    emoji: "🇬🇱",
    unicode: "U+1F1EC U+1F1F1",
    name: "Greenland",
    currency: "Danish Krone",
    currencyCode: "DKK",
    currencyId: "208",
  },
  {
    code: "GM",
    label: { en: "Gambia", es: "Gambia", pt: "Gâmbia" },
    phone: "220",
    title: "Gambia",
    emoji: "🇬🇲",
    unicode: "U+1F1EC U+1F1F2",
    name: "Gambia",
    currency: "Gambian Dalasi",
    currencyCode: "GMD",
    currencyId: "270",
  },
  {
    code: "GN",
    label: { en: "Guinea", es: "Guinea", pt: "Guiné" },
    phone: "224",
    title: "Guinea",
    emoji: "🇬🇳",
    unicode: "U+1F1EC U+1F1F3",
    name: "Guinea",
    currency: "Guinean Franc",
    currencyCode: "GNF",
    currencyId: "324",
  },
  {
    code: "GP",
    label: { en: "Guadeloupe", es: "Guadalupe", pt: "Guadalupe" },
    phone: "590",
    title: "Guadeloupe",
    emoji: "🇬🇵",
    unicode: "U+1F1EC U+1F1F5",
    name: "Guadeloupe",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "GQ",
    label: {
      en: "Equatorial Guinea",
      es: "Guinea Ecuatorial",
      pt: "Guiné Equatorial",
    },
    phone: "240",
    title: "Equatorial Guinea",
    emoji: "🇬🇶",
    unicode: "U+1F1EC U+1F1F6",
    name: "Equatorial Guinea",
    currency: "Central African CFA franc",
    currencyCode: "XAF",
    currencyId: "950",
  },
  {
    code: "GR",
    label: { en: "Greece", es: "Grecia", pt: "Grécia" },
    phone: "30",
    title: "Greece",
    emoji: "🇬🇷",
    unicode: "U+1F1EC U+1F1F7",
    name: "Greece",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "GS",
    label: {
      en: "South Georgia and the South Sandwich Islands",
      es: "Georgia del Sur y las Islas Sandwich del Sur",
      pt: "Geórgia do Sul e Ilhas Sandwich do Sul",
    },
    phone: "",
    title: "South Georgia and the South Sandwich Islands",
    emoji: "🇬🇸",
    unicode: "U+1F1EC U+1F1F8",
    name: "South Georgia and the South Sandwich Islands",
    currency: "British Pound",
    currencyCode: "GBP",
    currencyId: "826",
  },
  {
    code: "GT",
    label: { en: "Guatemala", es: "Guatemala", pt: "Guatemala" },
    phone: "502",
    title: "Guatemala",
    emoji: "🇬🇹",
    unicode: "U+1F1EC U+1F1F9",
    name: "Guatemala",
    currency: "Guatemalan Quetzal",
    currencyCode: "GTQ",
    currencyId: "320",
  },
  {
    code: "GU",
    label: { en: "Guam", es: "Guam", pt: "Guam" },
    phone: "1-671",
    title: "Guam",
    emoji: "🇬🇺",
    unicode: "U+1F1EC U+1F1FA",
    name: "Guam",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "GW",
    label: { en: "Guinea-Bissau", es: "Guinea-Bisáu", pt: "Guiné-Bissau" },
    phone: "245",
    title: "Guinea-Bissau",
    emoji: "🇬🇼",
    unicode: "U+1F1EC U+1F1FC",
    name: "Guinea-Bissau",
    currency: "West African CFA franc",
    currencyCode: "XOF",
    currencyId: "952",
  },
  {
    code: "GY",
    label: { en: "Guyana", es: "Guyana", pt: "Guiana" },
    phone: "592",
    title: "Guyana",
    emoji: "🇬🇾",
    unicode: "U+1F1EC U+1F1FE",
    name: "Guyana",
    currency: "Guyanese Dollar",
    currencyCode: "GYD",
    currencyId: "328",
  },
  {
    code: "HK",
    label: { en: "Hong Kong", es: "Hong Kong", pt: "Hong Kong" },
    phone: "852",
    title: "Hong Kong",
    emoji: "🇭🇰",
    unicode: "U+1F1ED U+1F1F0",
    name: "Hong Kong",
    currency: "Hong Kong Dollar",
    currencyCode: "HKD",
    currencyId: "344",
  },
  {
    code: "HM",
    label: {
      en: "Heard Island and McDonald Islands",
      es: "Islas Heard y McDonald",
      pt: "Ilha Heard e Ilhas McDonald",
    },
    phone: "",
    title: "Heard Island and McDonald Islands",
    emoji: "🇭🇲",
    unicode: "U+1F1ED U+1F1F2",
    name: "Heard Island and McDonald Islands",
    currency: "Australian Dollar",
    currencyCode: "AUD",
    currencyId: "036",
  },
  {
    code: "HN",
    label: { en: "Honduras", es: "Honduras", pt: "Honduras" },
    phone: "504",
    title: "Honduras",
    emoji: "🇭🇳",
    unicode: "U+1F1ED U+1F1F3",
    name: "Honduras",
    currency: "Honduran Lempira",
    currencyCode: "HNL",
    currencyId: "340",
  },
  {
    code: "HR",
    label: { en: "Croatia", es: "Croacia", pt: "Croácia" },
    phone: "385",
    title: "Croatia",
    emoji: "🇭🇷",
    unicode: "U+1F1ED U+1F1F7",
    name: "Croatia",
    currency: "Croatian Kuna",
    currencyCode: "HRK",
    currencyId: "191",
  },
  {
    code: "HT",
    label: { en: "Haiti", es: "Haití", pt: "Haiti" },
    phone: "509",
    title: "Haiti",
    emoji: "🇭🇹",
    unicode: "U+1F1ED U+1F1F9",
    name: "Haiti",
    currency: "Haitian Gourde",
    currencyCode: "HTG",
    currencyId: "332",
  },
  {
    code: "HU",
    label: { en: "Hungary", es: "Hungría", pt: "Hungria" },
    phone: "36",
    title: "Hungary",
    emoji: "🇭🇺",
    unicode: "U+1F1ED U+1F1FA",
    name: "Hungary",
    currency: "Hungarian Forint",
    currencyCode: "HUF",
    currencyId: "348",
  },
  {
    code: "ID",
    label: { en: "Indonesia", es: "Indonesia", pt: "Indonésia" },
    phone: "62",
    title: "Indonesia",
    emoji: "🇮🇩",
    unicode: "U+1F1EE U+1F1E9",
    name: "Indonesia",
    currency: "Indonesian Rupiah",
    currencyCode: "IDR",
    currencyId: "360",
  },
  {
    code: "IE",
    label: { en: "Ireland", es: "Irlanda", pt: "Irlanda" },
    phone: "353",
    title: "Ireland",
    emoji: "🇮🇪",
    unicode: "U+1F1EE U+1F1EA",
    name: "Ireland",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "IL",
    label: { en: "Israel", es: "Israel", pt: "Israel" },
    phone: "972",
    title: "Israel",
    emoji: "🇮🇱",
    unicode: "U+1F1EE U+1F1F1",
    name: "Israel",
    currency: "New Israeli Shekel",
    currencyCode: "ILS",
    currencyId: "376",
  },
  {
    code: "IM",
    label: { en: "Isle of Man", es: "Isla de Man", pt: "Ilha de Man" },
    phone: "44",
    title: "Isle of Man",
    emoji: "🇮🇲",
    unicode: "U+1F1EE U+1F1F2",
    name: "Isle of Man",
    currency: "Manx pound",
    currencyCode: "IMP",
    currencyId: "N/A",
  },
  {
    code: "IN",
    label: { en: "India", es: "India", pt: "Índia" },
    phone: "91",
    title: "India",
    emoji: "🇮🇳",
    unicode: "U+1F1EE U+1F1F3",
    name: "India",
    currency: "Indian Rupee",
    currencyCode: "INR",
    currencyId: "356",
  },
  {
    code: "IO",
    label: {
      en: "British Indian Ocean Territory",
      es: "Territorio Británico del Océano Índico",
      pt: "Território Britânico do Oceano Índico",
    },
    phone: "",
    title: "British Indian Ocean Territory",
    emoji: "🇮🇴",
    unicode: "U+1F1EE U+1F1F4",
    name: "British Indian Ocean Territory",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "IQ",
    label: { en: "Iraq", es: "Irak", pt: "Iraque" },
    phone: "964",
    title: "Iraq",
    emoji: "🇮🇶",
    unicode: "U+1F1EE U+1F1F6",
    name: "Iraq",
    currency: "Iraqi Dinar",
    currencyCode: "IQD",
    currencyId: "368",
  },
  {
    code: "IR",
    label: { en: "Iran", es: "Irán", pt: "Irão" },
    phone: "98",
    title: "Iran",
    emoji: "🇮🇷",
    unicode: "U+1F1EE U+1F1F7",
    name: "Iran",
    currency: "Iranian Rial",
    currencyCode: "IRR",
    currencyId: "364",
  },
  {
    code: "IS",
    label: { en: "Iceland", es: "Islandia", pt: "Islândia" },
    phone: "354",
    title: "Iceland",
    emoji: "🇮🇸",
    unicode: "U+1F1EE U+1F1F8",
    name: "Iceland",
    currency: "Icelandic Króna",
    currencyCode: "ISK",
    currencyId: "352",
  },
  {
    code: "IT",
    label: { en: "Italy", es: "Italia", pt: "Itália" },
    phone: "39",
    title: "Italy",
    emoji: "🇮🇹",
    unicode: "U+1F1EE U+1F1F9",
    name: "Italy",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "JE",
    label: { en: "Jersey", es: "Jersey", pt: "Jersey" },
    phone: "44",
    title: "Jersey",
    emoji: "🇯🇪",
    unicode: "U+1F1EF U+1F1EA",
    name: "Jersey",
    currency: "British Pound",
    currencyCode: "GBP",
    currencyId: "826",
  },
  {
    code: "JM",
    label: { en: "Jamaica", es: "Jamaica", pt: "Jamaica" },
    phone: "1-876",
    title: "Jamaica",
    emoji: "🇯🇲",
    unicode: "U+1F1EF U+1F1F2",
    name: "Jamaica",
    currency: "Jamaican Dollar",
    currencyCode: "JMD",
    currencyId: "388",
  },
  {
    code: "JO",
    label: { en: "Jordan", es: "Jordania", pt: "Jordânia" },
    phone: "962",
    title: "Jordan",
    emoji: "🇯🇴",
    unicode: "U+1F1EF U+1F1F4",
    name: "Jordan",
    currency: "Jordanian Dinar",
    currencyCode: "JOD",
    currencyId: "400",
  },
  {
    code: "JP",
    label: { en: "Japan", es: "Japón", pt: "Japão" },
    phone: "81",
    title: "Japan",
    emoji: "🇯🇵",
    unicode: "U+1F1EF U+1F1F5",
    name: "Japan",
    currency: "Japanese Yen",
    currencyCode: "JPY",
    currencyId: "392",
  },
  {
    code: "KE",
    label: { en: "Kenya", es: "Kenia", pt: "Quênia" },
    phone: "254",
    title: "Kenya",
    emoji: "🇰🇪",
    unicode: "U+1F1F0 U+1F1EA",
    name: "Kenya",
    currency: "Kenyan Shilling",
    currencyCode: "KES",
    currencyId: "404",
  },
  {
    code: "KG",
    label: { en: "Kyrgyzstan", es: "Kirguistán", pt: "Quirguistão" },
    phone: "996",
    title: "Kyrgyzstan",
    emoji: "🇰🇬",
    unicode: "U+1F1F0 U+1F1EC",
    name: "Kyrgyzstan",
    currency: "Kyrgyzstani Som",
    currencyCode: "KGS",
    currencyId: "417",
  },
  {
    code: "KH",
    label: { en: "Cambodia", es: "Camboya", pt: "Camboja" },
    phone: "855",
    title: "Cambodia",
    emoji: "🇰🇭",
    unicode: "U+1F1F0 U+1F1ED",
    name: "Cambodia",
    currency: "Cambodian Riel",
    currencyCode: "KHR",
    currencyId: "116",
  },
  {
    code: "KI",
    label: { en: "Kiribati", es: "Kiribati", pt: "Kiribati" },
    phone: "686",
    title: "Kiribati",
    emoji: "🇰🇮",
    unicode: "U+1F1F0 U+1F1EE",
    name: "Kiribati",
    currency: "Kiribati Dollar",
    currencyCode: "KID",
    currencyId: "N/A",
  },
  {
    code: "KM",
    label: { en: "Comoros", es: "Comoras", pt: "Comores" },
    phone: "269",
    title: "Comoros",
    emoji: "🇰🇲",
    unicode: "U+1F1F0 U+1F1F2",
    name: "Comoros",
    currency: "Comorian Franc",
    currencyCode: "KMF",
    currencyId: "174",
  },
  {
    code: "KN",
    label: {
      en: "Saint Kitts and Nevis",
      es: "San Cristóbal y Nieves",
      pt: "São Cristóvão e Nevis",
    },
    phone: "1-869",
    title: "Saint Kitts and Nevis",
    emoji: "🇰🇳",
    unicode: "U+1F1F0 U+1F1F3",
    name: "Saint Kitts and Nevis",
    currency: "East Caribbean Dollar",
    currencyCode: "XCD",
    currencyId: "951",
  },
  {
    code: "KP",
    label: { en: "North Korea", es: "Corea del Norte", pt: "Coreia do Norte" },
    phone: "850",
    title: "North Korea",
    emoji: "🇰🇵",
    unicode: "U+1F1F0 U+1F1F5",
    name: "North Korea",
    currency: "North Korean Won",
    currencyCode: "KPW",
    currencyId: "408",
  },
  {
    code: "KR",
    label: { en: "South Korea", es: "Corea del Sur", pt: "Coreia do Sul" },
    phone: "82",
    title: "South Korea",
    emoji: "🇰🇷",
    unicode: "U+1F1F0 U+1F1F7",
    name: "South Korea",
    currency: "South Korean Won",
    currencyCode: "KRW",
    currencyId: "410",
  },
  {
    code: "KW",
    label: { en: "Kuwait", es: "Kuwait", pt: "Kuwait" },
    phone: "965",
    title: "Kuwait",
    emoji: "🇰🇼",
    unicode: "U+1F1F0 U+1F1FC",
    name: "Kuwait",
    currency: "Kuwaiti Dinar",
    currencyCode: "KWD",
    currencyId: "414",
  },
  {
    code: "KY",
    label: { en: "Cayman Islands", es: "Islas Caimán", pt: "Ilhas Cayman" },
    phone: "1-345",
    title: "Cayman Islands",
    emoji: "🇰🇾",
    unicode: "U+1F1F0 U+1F1FE",
    name: "Cayman Islands",
    currency: "Cayman Islands Dollar",
    currencyCode: "KYD",
    currencyId: "136",
  },
  {
    code: "KZ",
    label: { en: "Kazakhstan", es: "Kazajistán", pt: "Cazaquistão" },
    phone: "7",
    title: "Kazakhstan",
    emoji: "🇰🇿",
    unicode: "U+1F1F0 U+1F1FF",
    name: "Kazakhstan",
    currency: "Kazakhstani Tenge",
    currencyCode: "KZT",
    currencyId: "398",
  },
  {
    code: "LA",
    label: { en: "Laos", es: "Laos", pt: "Laos" },
    phone: "856",
    title: "Laos",
    emoji: "🇱🇦",
    unicode: "U+1F1F1 U+1F1E6",
    name: "Laos",
    currency: "Lao Kip",
    currencyCode: "LAK",
    currencyId: "418",
  },
  {
    code: "LB",
    label: { en: "Lebanon", es: "Líbano", pt: "Líbano" },
    phone: "961",
    title: "Lebanon",
    emoji: "🇱🇧",
    unicode: "U+1F1F1 U+1F1E7",
    name: "Lebanon",
    currency: "Lebanese Pound",
    currencyCode: "LBP",
    currencyId: "422",
  },
  {
    code: "LC",
    label: { en: "Saint Lucia", es: "Santa Lucía", pt: "Santa Lúcia" },
    phone: "1-758",
    title: "Saint Lucia",
    emoji: "🇱🇨",
    unicode: "U+1F1F1 U+1F1E8",
    name: "Saint Lucia",
    currency: "East Caribbean Dollar",
    currencyCode: "XCD",
    currencyId: "951",
  },
  {
    code: "LI",
    label: { en: "Liechtenstein", es: "Liechtenstein", pt: "Liechtenstein" },
    phone: "423",
    title: "Liechtenstein",
    emoji: "🇱🇮",
    unicode: "U+1F1F1 U+1F1EE",
    name: "Liechtenstein",
    currency: "Swiss Franc",
    currencyCode: "CHF",
    currencyId: "756",
  },
  {
    code: "LK",
    label: { en: "Sri Lanka", es: "Sri Lanka", pt: "Sri Lanka" },
    phone: "94",
    title: "Sri Lanka",
    emoji: "🇱🇰",
    unicode: "U+1F1F1 U+1F1F0",
    name: "Sri Lanka",
    currency: "Sri Lankan Rupee",
    currencyCode: "LKR",
    currencyId: "144",
  },
  {
    code: "LR",
    label: { en: "Liberia", es: "Liberia", pt: "Libéria" },
    phone: "231",
    title: "Liberia",
    emoji: "🇱🇷",
    unicode: "U+1F1F1 U+1F1F7",
    name: "Liberia",
    currency: "Liberian Dollar",
    currencyCode: "LRD",
    currencyId: "430",
  },
  {
    code: "LS",
    label: { en: "Lesotho", es: "Lesoto", pt: "Lesoto" },
    phone: "266",
    title: "Lesotho",
    emoji: "🇱🇸",
    unicode: "U+1F1F1 U+1F1F8",
    name: "Lesotho",
    currency: "Lesotho Loti",
    currencyCode: "LSL",
    currencyId: "426",
  },
  {
    code: "LT",
    label: { en: "Lithuania", es: "Lituania", pt: "Lituânia" },
    phone: "370",
    title: "Lithuania",
    emoji: "🇱🇹",
    unicode: "U+1F1F1 U+1F1F9",
    name: "Lithuania",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "LU",
    label: { en: "Luxembourg", es: "Luxemburgo", pt: "Luxemburgo" },
    phone: "352",
    title: "Luxembourg",
    emoji: "🇱🇺",
    unicode: "U+1F1F1 U+1F1FA",
    name: "Luxembourg",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "LV",
    label: { en: "Latvia", es: "Letonia", pt: "Letônia" },
    phone: "371",
    title: "Latvia",
    emoji: "🇱🇻",
    unicode: "U+1F1F1 U+1F1FB",
    name: "Latvia",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "LY",
    label: { en: "Libya", es: "Libia", pt: "Líbia" },
    phone: "218",
    title: "Libya",
    emoji: "🇱🇾",
    unicode: "U+1F1F1 U+1F1FE",
    name: "Libya",
    currency: "Libyan Dinar",
    currencyCode: "LYD",
    currencyId: "434",
  },
  {
    code: "MA",
    label: { en: "Morocco", es: "Marruecos", pt: "Marrocos" },
    phone: "212",
    title: "Morocco",
    emoji: "🇲🇦",
    unicode: "U+1F1F2 U+1F1E6",
    name: "Morocco",
    currency: "Moroccan Dirham",
    currencyCode: "MAD",
    currencyId: "504",
  },
  {
    code: "MC",
    label: { en: "Monaco", es: "Mónaco", pt: "Mônaco" },
    phone: "377",
    title: "Monaco",
    emoji: "🇲🇨",
    unicode: "U+1F1F2 U+1F1E8",
    name: "Monaco",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "MD",
    label: { en: "Moldova", es: "Moldavia", pt: "Moldávia" },
    phone: "373",
    title: "Moldova",
    emoji: "🇲🇩",
    unicode: "U+1F1F2 U+1F1E9",
    name: "Moldova",
    currency: "Moldovan Leu",
    currencyCode: "MDL",
    currencyId: "498",
  },
  {
    code: "ME",
    label: { en: "Montenegro", es: "Montenegro", pt: "Montenegro" },
    phone: "382",
    title: "Montenegro",
    emoji: "🇲🇪",
    unicode: "U+1F1F2 U+1F1EA",
    name: "Montenegro",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "MF",
    label: {
      en: "Saint Martin (French part)",
      es: "San Martín (parte francesa)",
      pt: "São Martinho (parte francesa)",
    },
    phone: "590",
    title: "Saint Martin",
    emoji: "🇲🇫",
    unicode: "U+1F1F2 U+1F1EB",
    name: "Saint Martin",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "MG",
    label: { en: "Madagascar", es: "Madagascar", pt: "Madagáscar" },
    phone: "261",
    title: "Madagascar",
    emoji: "🇲🇬",
    unicode: "U+1F1F2 U+1F1EC",
    name: "Madagascar",
    currency: "Malagasy Ariary",
    currencyCode: "MGA",
    currencyId: "969",
  },
  {
    code: "MH",
    label: {
      en: "Marshall Islands",
      es: "Islas Marshall",
      pt: "Ilhas Marshall",
    },
    phone: "692",
    title: "Marshall Islands",
    emoji: "🇲🇭",
    unicode: "U+1F1F2 U+1F1ED",
    name: "Marshall Islands",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "MK",
    label: {
      en: "North Macedonia",
      es: "Macedonia del Norte",
      pt: "Macedônia do Norte",
    },
    phone: "389",
    title: "North Macedonia",
    emoji: "🇲🇰",
    unicode: "U+1F1F2 U+1F1F0",
    name: "North Macedonia",
    currency: "Macedonian Denar",
    currencyCode: "MKD",
    currencyId: "807",
  },
  {
    code: "ML",
    label: { en: "Mali", es: "Malí", pt: "Mali" },
    phone: "223",
    title: "Mali",
    emoji: "🇲🇱",
    unicode: "U+1F1F2 U+1F1F1",
    name: "Mali",
    currency: "West African CFA franc",
    currencyCode: "XOF",
    currencyId: "952",
  },
  {
    code: "MM",
    label: { en: "Myanmar", es: "Myanmar (Birmania)", pt: "Mianmar" },
    phone: "95",
    title: "Myanmar",
    emoji: "🇲🇲",
    unicode: "U+1F1F2 U+1F1F2",
    name: "Myanmar",
    currency: "Burmese Kyat",
    currencyCode: "MMK",
    currencyId: "104",
  },
  {
    code: "MN",
    label: { en: "Mongolia", es: "Mongolia", pt: "Mongólia" },
    phone: "976",
    title: "Mongolia",
    emoji: "🇲🇳",
    unicode: "U+1F1F2 U+1F1F3",
    name: "Mongolia",
    currency: "Mongolian Tögrög",
    currencyCode: "MNT",
    currencyId: "496",
  },
  {
    code: "MO",
    label: { en: "Macao", es: "Macao", pt: "Macau" },
    phone: "853",
    title: "Macao",
    emoji: "🇲🇴",
    unicode: "U+1F1F2 U+1F1F4",
    name: "Macao",
    currency: "Macanese Pataca",
    currencyCode: "MOP",
    currencyId: "446",
  },
  {
    code: "MP",
    label: {
      en: "Northern Mariana Islands",
      es: "Islas Marianas del Norte",
      pt: "Ilhas Marianas do Norte",
    },
    phone: "1-670",
    title: "Northern Mariana Islands",
    emoji: "🇲🇵",
    unicode: "U+1F1F2 U+1F1F5",
    name: "Northern Mariana Islands",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "MQ",
    label: { en: "Martinique", es: "Martinica", pt: "Martinica" },
    phone: "596",
    title: "Martinique",
    emoji: "🇲🇶",
    unicode: "U+1F1F2 U+1F1F6",
    name: "Martinique",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "MR",
    label: { en: "Mauritania", es: "Mauritania", pt: "Mauritânia" },
    phone: "222",
    title: "Mauritania",
    emoji: "🇲🇷",
    unicode: "U+1F1F2 U+1F1F7",
    name: "Mauritania",
    currency: "Mauritanian Ouguiya",
    currencyCode: "MRU",
    currencyId: "929",
  },
  {
    code: "MS",
    label: { en: "Montserrat", es: "Montserrat", pt: "Montserrat" },
    phone: "1-664",
    title: "Montserrat",
    emoji: "🇲🇸",
    unicode: "U+1F1F2 U+1F1F8",
    name: "Montserrat",
    currency: "East Caribbean Dollar",
    currencyCode: "XCD",
    currencyId: "951",
  },
  {
    code: "MT",
    label: { en: "Malta", es: "Malta", pt: "Malta" },
    phone: "356",
    title: "Malta",
    emoji: "🇲🇹",
    unicode: "U+1F1F2 U+1F1F9",
    name: "Malta",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "MU",
    label: { en: "Mauritius", es: "Mauricio", pt: "Maurícia" },
    phone: "230",
    title: "Mauritius",
    emoji: "🇲🇺",
    unicode: "U+1F1F2 U+1F1FA",
    name: "Mauritius",
    currency: "Mauritian Rupee",
    currencyCode: "MUR",
    currencyId: "480",
  },
  {
    code: "MV",
    label: { en: "Maldives", es: "Maldivas", pt: "Maldivas" },
    phone: "960",
    title: "Maldives",
    emoji: "🇲🇻",
    unicode: "U+1F1F2 U+1F1FB",
    name: "Maldives",
    currency: "Maldivian Rufiyaa",
    currencyCode: "MVR",
    currencyId: "462",
  },
  {
    code: "MW",
    label: { en: "Malawi", es: "Malaui", pt: "Malawi" },
    phone: "265",
    title: "Malawi",
    emoji: "🇲🇼",
    unicode: "U+1F1F2 U+1F1FC",
    name: "Malawi",
    currency: "Malawian Kwacha",
    currencyCode: "MWK",
    currencyId: "454",
  },
  {
    code: "MX",
    label: { en: "Mexico", es: "México", pt: "México" },
    phone: "52",
    title: "Mexico",
    emoji: "🇲🇽",
    unicode: "U+1F1F2 U+1F1FD",
    name: "Mexico",
    currency: "Mexican Peso",
    currencyCode: "MXN",
    currencyId: "484",
  },
  {
    code: "MY",
    label: { en: "Malaysia", es: "Malasia", pt: "Malásia" },
    phone: "60",
    title: "Malaysia",
    emoji: "🇲🇾",
    unicode: "U+1F1F2 U+1F1FE",
    name: "Malaysia",
    currency: "Malaysian Ringgit",
    currencyCode: "MYR",
    currencyId: "458",
  },
  {
    code: "MZ",
    label: { en: "Mozambique", es: "Mozambique", pt: "Moçambique" },
    phone: "258",
    title: "Mozambique",
    emoji: "🇲🇿",
    unicode: "U+1F1F2 U+1F1FF",
    name: "Mozambique",
    currency: "Mozambican Metical",
    currencyCode: "MZN",
    currencyId: "943",
  },
  {
    code: "NA",
    label: { en: "Namibia", es: "Namibia", pt: "Namíbia" },
    phone: "264",
    title: "Namibia",
    emoji: "🇳🇦",
    unicode: "U+1F1F3 U+1F1E6",
    name: "Namibia",
    currency: "Namibian Dollar",
    currencyCode: "NAD",
    currencyId: "516",
  },
  {
    code: "NC",
    label: { en: "New Caledonia", es: "Nueva Caledonia", pt: "Nova Caledônia" },
    phone: "687",
    title: "New Caledonia",
    emoji: "🇳🇨",
    unicode: "U+1F1F3 U+1F1E8",
    name: "New Caledonia",
    currency: "CFP Franc",
    currencyCode: "XPF",
    currencyId: "953",
  },
  {
    code: "NE",
    label: { en: "Niger", es: "Níger", pt: "Níger" },
    phone: "227",
    title: "Niger",
    emoji: "🇳🇪",
    unicode: "U+1F1F3 U+1F1EA",
    name: "Niger",
    currency: "West African CFA franc",
    currencyCode: "XOF",
    currencyId: "952",
  },
  {
    code: "NF",
    label: { en: "Norfolk Island", es: "Isla Norfolk", pt: "Ilha Norfolk" },
    phone: "672",
    title: "Norfolk Island",
    emoji: "🇳🇫",
    unicode: "U+1F1F3 U+1F1EB",
    name: "Norfolk Island",
    currency: "Australian Dollar",
    currencyCode: "AUD",
    currencyId: "036",
  },
  {
    code: "NG",
    label: { en: "Nigeria", es: "Nigeria", pt: "Nigéria" },
    phone: "234",
    title: "Nigeria",
    emoji: "🇳🇬",
    unicode: "U+1F1F3 U+1F1EC",
    name: "Nigeria",
    currency: "Nigerian Naira",
    currencyCode: "NGN",
    currencyId: "566",
  },
  {
    code: "NI",
    label: { en: "Nicaragua", es: "Nicaragua", pt: "Nicarágua" },
    phone: "505",
    title: "Nicaragua",
    emoji: "🇳🇮",
    unicode: "U+1F1F3 U+1F1EE",
    name: "Nicaragua",
    currency: "Nicaraguan Córdoba",
    currencyCode: "NIO",
    currencyId: "558",
  },
  {
    code: "NL",
    label: { en: "Netherlands", es: "Países Bajos", pt: "Países Baixos" },
    phone: "31",
    title: "Netherlands",
    emoji: "🇳🇱",
    unicode: "U+1F1F3 U+1F1F1",
    name: "Netherlands",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "NO",
    label: { en: "Norway", es: "Noruega", pt: "Noruega" },
    phone: "47",
    title: "Norway",
    emoji: "🇳🇴",
    unicode: "U+1F1F3 U+1F1F4",
    name: "Norway",
    currency: "Norwegian Krone",
    currencyCode: "NOK",
    currencyId: "578",
  },
  {
    code: "NP",
    label: { en: "Nepal", es: "Nepal", pt: "Nepal" },
    phone: "977",
    title: "Nepal",
    emoji: "🇳🇵",
    unicode: "U+1F1F3 U+1F1F5",
    name: "Nepal",
    currency: "Nepalese Rupee",
    currencyCode: "NPR",
    currencyId: "524",
  },
  {
    code: "NR",
    label: { en: "Nauru", es: "Nauru", pt: "Nauru" },
    phone: "674",
    title: "Nauru",
    emoji: "🇳🇷",
    unicode: "U+1F1F3 U+1F1F7",
    name: "Nauru",
    currency: "Australian Dollar",
    currencyCode: "AUD",
    currencyId: "036",
  },
  {
    code: "NU",
    label: { en: "Niue", es: "Niue", pt: "Niue" },
    phone: "683",
    title: "Niue",
    emoji: "🇳🇺",
    unicode: "U+1F1F3 U+1F1FA",
    name: "Niue",
    currency: "New Zealand Dollar",
    currencyCode: "NZD",
    currencyId: "554",
  },
  {
    code: "NZ",
    label: { en: "New Zealand", es: "Nueva Zelanda", pt: "Nova Zelândia" },
    phone: "64",
    title: "New Zealand",
    emoji: "🇳🇿",
    unicode: "U+1F1F3 U+1F1FF",
    name: "New Zealand",
    currency: "New Zealand Dollar",
    currencyCode: "NZD",
    currencyId: "554",
  },
  {
    code: "OM",
    label: { en: "Oman", es: "Omán", pt: "Omã" },
    phone: "968",
    title: "Oman",
    emoji: "🇴🇲",
    unicode: "U+1F1F4 U+1F1F2",
    name: "Oman",
    currency: "Omani Rial",
    currencyCode: "OMR",
    currencyId: "512",
  },
  {
    code: "PA",
    label: { en: "Panama", es: "Panamá", pt: "Panamá" },
    phone: "507",
    title: "Panama",
    emoji: "🇵🇦",
    unicode: "U+1F1F5 U+1F1E6",
    name: "Panama",
    currency: "Panamanian Balboa",
    currencyCode: "PAB",
    currencyId: "590",
  },
  {
    code: "PE",
    label: { en: "Peru", es: "Perú", pt: "Peru" },
    phone: "51",
    title: "Peru",
    emoji: "🇵🇪",
    unicode: "U+1F1F5 U+1F1EA",
    name: "Peru",
    currency: "Peruvian Sol",
    currencyCode: "PEN",
    currencyId: "604",
  },
  {
    code: "PF",
    label: {
      en: "French Polynesia",
      es: "Polinesia Francesa",
      pt: "Polinésia Francesa",
    },
    phone: "689",
    title: "French Polynesia",
    emoji: "🇵🇫",
    unicode: "U+1F1F5 U+1F1EB",
    name: "French Polynesia",
    currency: "CFP Franc",
    currencyCode: "XPF",
    currencyId: "953",
  },
  {
    code: "PG",
    label: {
      en: "Papua New Guinea",
      es: "Papúa Nueva Guinea",
      pt: "Papua-Nova Guiné",
    },
    phone: "675",
    title: "Papua New Guinea",
    emoji: "🇵🇬",
    unicode: "U+1F1F5 U+1F1EC",
    name: "Papua New Guinea",
    currency: "Papua New Guinean Kina",
    currencyCode: "PGK",
    currencyId: "598",
  },
  {
    code: "PH",
    label: { en: "Philippines", es: "Filipinas", pt: "Filipinas" },
    phone: "63",
    title: "Philippines",
    emoji: "🇵🇭",
    unicode: "U+1F1F5 U+1F1ED",
    name: "Philippines",
    currency: "Philippine Peso",
    currencyCode: "PHP",
    currencyId: "608",
  },
  {
    code: "PK",
    label: { en: "Pakistan", es: "Pakistán", pt: "Paquistão" },
    phone: "92",
    title: "Pakistan",
    emoji: "🇵🇰",
    unicode: "U+1F1F5 U+1F1F0",
    name: "Pakistan",
    currency: "Pakistani Rupee",
    currencyCode: "PKR",
    currencyId: "586",
  },
  {
    code: "PL",
    label: { en: "Poland", es: "Polonia", pt: "Polónia" },
    phone: "48",
    title: "Poland",
    emoji: "🇵🇱",
    unicode: "U+1F1F5 U+1F1F1",
    name: "Poland",
    currency: "Polish Złoty",
    currencyCode: "PLN",
    currencyId: "985",
  },
  {
    code: "PM",
    label: {
      en: "Saint Pierre and Miquelon",
      es: "San Pedro y Miquelón",
      pt: "Saint-Pierre e Miquelon",
    },
    phone: "508",
    title: "Saint Pierre and Miquelon",
    emoji: "🇵🇲",
    unicode: "U+1F1F5 U+1F1F2",
    name: "Saint Pierre and Miquelon",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "PN",
    label: {
      en: "Pitcairn Islands",
      es: "Islas Pitcairn",
      pt: "Ilhas Pitcairn",
    },
    phone: "",
    title: "Pitcairn Islands",
    emoji: "🇵🇳",
    unicode: "U+1F1F5 U+1F1F3",
    name: "Pitcairn Islands",
    currency: "New Zealand Dollar",
    currencyCode: "NZD",
    currencyId: "554",
  },
  {
    code: "PR",
    label: { en: "Puerto Rico", es: "Puerto Rico", pt: "Porto Rico" },
    phone: "1-787, 1-939",
    title: "Puerto Rico",
    emoji: "🇵🇷",
    unicode: "U+1F1F5 U+1F1F7",
    name: "Puerto Rico",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "PS",
    label: {
      en: "Palestinian Territories",
      es: "Territorios Palestinos",
      pt: "Territórios Palestinos",
    },
    phone: "970",
    title: "Palestine",
    emoji: "🇵🇸",
    unicode: "U+1F1F5 U+1F1F8",
    name: "Palestinian Territories",
    currency: "Israeli New Sheqel",
    currencyCode: "ILS",
    currencyId: "376",
  },
  {
    code: "PT",
    label: { en: "Portugal", es: "Portugal", pt: "Portugal" },
    phone: "351",
    title: "Portugal",
    emoji: "🇵🇹",
    unicode: "U+1F1F5 U+1F1F9",
    name: "Portugal",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "PW",
    label: { en: "Palau", es: "Palaos", pt: "Palau" },
    phone: "680",
    title: "Palau",
    emoji: "🇵🇼",
    unicode: "U+1F1F5 U+1F1FC",
    name: "Palau",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "PY",
    label: { en: "Paraguay", es: "Paraguay", pt: "Paraguai" },
    phone: "595",
    title: "Paraguay",
    emoji: "🇵🇾",
    unicode: "U+1F1F5 U+1F1FE",
    name: "Paraguay",
    currency: "Paraguayan Guarani",
    currencyCode: "PYG",
    currencyId: "600",
  },
  {
    code: "QA",
    label: { en: "Qatar", es: "Catar", pt: "Catar" },
    phone: "974",
    title: "Qatar",
    emoji: "🇶🇦",
    unicode: "U+1F1F6 U+1F1E6",
    name: "Qatar",
    currency: "Qatari Rial",
    currencyCode: "QAR",
    currencyId: "634",
  },
  {
    code: "RE",
    label: { en: "Réunion", es: "Reunión", pt: "Reunião" },
    phone: "262",
    title: "Réunion",
    emoji: "🇷🇪",
    unicode: "U+1F1F7 U+1F1EA",
    name: "Réunion",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "RO",
    label: { en: "Romania", es: "Rumanía", pt: "Romênia" },
    phone: "40",
    title: "Romania",
    emoji: "🇷🇴",
    unicode: "U+1F1F7 U+1F1F4",
    name: "Romania",
    currency: "Romanian Leu",
    currencyCode: "RON",
    currencyId: "946",
  },
  {
    code: "RS",
    label: { en: "Serbia", es: "Serbia", pt: "Sérvia" },
    phone: "381",
    title: "Serbia",
    emoji: "🇷🇸",
    unicode: "U+1F1F7 U+1F1F8",
    name: "Serbia",
    currency: "Serbian Dinar",
    currencyCode: "RSD",
    currencyId: "941",
  },
  {
    code: "RU",
    label: { en: "Russia", es: "Rusia", pt: "Rússia" },
    phone: "7",
    title: "Russia",
    emoji: "🇷🇺",
    unicode: "U+1F1F7 U+1F1FA",
    name: "Russia",
    currency: "Russian Ruble",
    currencyCode: "RUB",
    currencyId: "643",
  },
  {
    code: "RW",
    label: { en: "Rwanda", es: "Ruanda", pt: "Ruanda" },
    phone: "250",
    title: "Rwanda",
    emoji: "🇷🇼",
    unicode: "U+1F1F7 U+1F1FC",
    name: "Rwanda",
    currency: "Rwandan Franc",
    currencyCode: "RWF",
    currencyId: "646",
  },
  {
    code: "SA",
    label: { en: "Saudi Arabia", es: "Arabia Saudí", pt: "Arábia Saudita" },
    phone: "966",
    title: "Saudi Arabia",
    emoji: "🇸🇦",
    unicode: "U+1F1F8 U+1F1E6",
    name: "Saudi Arabia",
    currency: "Saudi Riyal",
    currencyCode: "SAR",
    currencyId: "682",
  },
  {
    code: "SB",
    label: { en: "Solomon Islands", es: "Islas Salomón", pt: "Ilhas Salomão" },
    phone: "677",
    title: "Solomon Islands",
    emoji: "🇸🇧",
    unicode: "U+1F1F8 U+1F1E7",
    name: "Solomon Islands",
    currency: "Solomon Islands Dollar",
    currencyCode: "SBD",
    currencyId: "090",
  },
  {
    code: "SC",
    label: { en: "Seychelles", es: "Seychelles", pt: "Seicheles" },
    phone: "248",
    title: "Seychelles",
    emoji: "🇸🇨",
    unicode: "U+1F1F8 U+1F1E8",
    name: "Seychelles",
    currency: "Seychellois Rupee",
    currencyCode: "SCR",
    currencyId: "690",
  },
  {
    code: "SD",
    label: { en: "Sudan", es: "Sudán", pt: "Sudão" },
    phone: "249",
    title: "Sudan",
    emoji: "🇸🇩",
    unicode: "U+1F1F8 U+1F1E9",
    name: "Sudan",
    currency: "Sudanese Pound",
    currencyCode: "SDG",
    currencyId: "938",
  },
  {
    code: "SE",
    label: { en: "Sweden", es: "Suecia", pt: "Suécia" },
    phone: "46",
    title: "Sweden",
    emoji: "🇸🇪",
    unicode: "U+1F1F8 U+1F1EA",
    name: "Sweden",
    currency: "Swedish Krona",
    currencyCode: "SEK",
    currencyId: "752",
  },
  {
    code: "SG",
    label: { en: "Singapore", es: "Singapur", pt: "Singapura" },
    phone: "65",
    title: "Singapore",
    emoji: "🇸🇬",
    unicode: "U+1F1F8 U+1F1EC",
    name: "Singapore",
    currency: "Singapore Dollar",
    currencyCode: "SGD",
    currencyId: "702",
  },
  {
    code: "SH",
    label: { en: "Saint Helena", es: "Santa Elena", pt: "Santa Helena" },
    phone: "290",
    title: "Saint Helena",
    emoji: "🇸🇭",
    unicode: "U+1F1F8 U+1F1ED",
    name: "Saint Helena",
    currency: "Saint Helena Pound",
    currencyCode: "SHP",
    currencyId: "654",
  },
  {
    code: "SI",
    label: { en: "Slovenia", es: "Eslovenia", pt: "Eslovénia" },
    phone: "386",
    title: "Slovenia",
    emoji: "🇸🇮",
    unicode: "U+1F1F8 U+1F1EE",
    name: "Slovenia",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "SJ",
    label: {
      en: "Svalbard and Jan Mayen",
      es: "Svalbard y Jan Mayen",
      pt: "Svalbard e Jan Mayen",
    },
    phone: "47",
    title: "Svalbard and Jan Mayen",
    emoji: "🇸🇯",
    unicode: "U+1F1F8 U+1F1EF",
    name: "Svalbard and Jan Mayen",
    currency: "Norwegian Krone",
    currencyCode: "NOK",
    currencyId: "578",
  },
  {
    code: "SK",
    label: { en: "Slovakia", es: "Eslovaquia", pt: "Eslováquia" },
    phone: "421",
    title: "Slovakia",
    emoji: "🇸🇰",
    unicode: "U+1F1F8 U+1F1F0",
    name: "Slovakia",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "SL",
    label: { en: "Sierra Leone", es: "Sierra Leona", pt: "Serra Leoa" },
    phone: "232",
    title: "Sierra Leone",
    emoji: "🇸🇱",
    unicode: "U+1F1F8 U+1F1F1",
    name: "Sierra Leone",
    currency: "Sierra Leonean Leone",
    currencyCode: "SLL",
    currencyId: "694",
  },
  {
    code: "SM",
    label: { en: "San Marino", es: "San Marino", pt: "San Marino" },
    phone: "378",
    title: "San Marino",
    emoji: "🇸🇲",
    unicode: "U+1F1F8 U+1F1F2",
    name: "San Marino",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "SN",
    label: { en: "Senegal", es: "Senegal", pt: "Senegal" },
    phone: "221",
    title: "Senegal",
    emoji: "🇸🇳",
    unicode: "U+1F1F8 U+1F1F3",
    name: "Senegal",
    currency: "West African CFA franc",
    currencyCode: "XOF",
    currencyId: "952",
  },
  {
    code: "SO",
    label: { en: "Somalia", es: "Somalia", pt: "Somália" },
    phone: "252",
    title: "Somalia",
    emoji: "🇸🇴",
    unicode: "U+1F1F8 U+1F1F4",
    name: "Somalia",
    currency: "Somali Shilling",
    currencyCode: "SOS",
    currencyId: "706",
  },
  {
    code: "SR",
    label: { en: "Suriname", es: "Surinam", pt: "Suriname" },
    phone: "597",
    title: "Suriname",
    emoji: "🇸🇷",
    unicode: "U+1F1F8 U+1F1F7",
    name: "Suriname",
    currency: "Surinamese Dollar",
    currencyCode: "SRD",
    currencyId: "968",
  },
  {
    code: "SS",
    label: { en: "South Sudan", es: "Sudán del Sur", pt: "Sudão do Sul" },
    phone: "211",
    title: "South Sudan",
    emoji: "🇸🇸",
    unicode: "U+1F1F8 U+1F1F8",
    name: "South Sudan",
    currency: "South Sudanese Pound",
    currencyCode: "SSP",
    currencyId: "728",
  },
  {
    code: "ST",
    label: {
      en: "São Tomé and Príncipe",
      es: "Santo Tomé y Príncipe",
      pt: "São Tomé e Príncipe",
    },
    phone: "239",
    title: "São Tomé and Príncipe",
    emoji: "🇸🇹",
    unicode: "U+1F1F8 U+1F1F9",
    name: "São Tomé and Príncipe",
    currency: "São Tomé and Príncipe Dobra",
    currencyCode: "STN",
    currencyId: "930",
  },
  {
    code: "SV",
    label: { en: "El Salvador", es: "El Salvador", pt: "El Salvador" },
    phone: "503",
    title: "El Salvador",
    emoji: "🇸🇻",
    unicode: "U+1F1F8 U+1F1FB",
    name: "El Salvador",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "SX",
    label: { en: "Sint Maarten", es: "Sint Maarten", pt: "São Martinho" },
    phone: "599",
    title: "Sint Maarten",
    emoji: "🇸🇽",
    unicode: "U+1F1F8 U+1F1FD",
    name: "Sint Maarten",
    currency: "Netherlands Antillean Guilder",
    currencyCode: "ANG",
    currencyId: "532",
  },
  {
    code: "SY",
    label: { en: "Syria", es: "Siria", pt: "Síria" },
    phone: "963",
    title: "Syria",
    emoji: "🇸🇾",
    unicode: "U+1F1F8 U+1F1FE",
    name: "Syria",
    currency: "Syrian Pound",
    currencyCode: "SYP",
    currencyId: "760",
  },
  {
    code: "SZ",
    label: { en: "Eswatini", es: "Esuatini", pt: "Essuatíni" },
    phone: "268",
    title: "Eswatini",
    emoji: "🇸🇿",
    unicode: "U+1F1F8 U+1F1FF",
    name: "Eswatini",
    currency: "Swazi Lilangeni",
    currencyCode: "SZL",
    currencyId: "748",
  },
  {
    code: "TC",
    label: {
      en: "Turks and Caicos Islands",
      es: "Islas Turcas y Caicos",
      pt: "Ilhas Turks e Caicos",
    },
    phone: "1-649",
    title: "Turks and Caicos Islands",
    emoji: "🇹🇨",
    unicode: "U+1F1F9 U+1F1E8",
    name: "Turks and Caicos Islands",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "TD",
    label: { en: "Chad", es: "Chad", pt: "Chade" },
    phone: "235",
    title: "Chad",
    emoji: "🇹🇩",
    unicode: "U+1F1F9 U+1F1E9",
    name: "Chad",
    currency: "Central African CFA franc",
    currencyCode: "XAF",
    currencyId: "950",
  },
  {
    code: "TF",
    label: {
      en: "French Southern Territories",
      es: "Territorios Franceses del Sur",
      pt: "Territórios Franceses do Sul",
    },
    phone: "",
    title: "French Southern Territories",
    emoji: "🇹🇫",
    unicode: "U+1F1F9 U+1F1EB",
    name: "French Southern Territories",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "TG",
    label: { en: "Togo", es: "Togo", pt: "Togo" },
    phone: "228",
    title: "Togo",
    emoji: "🇹🇬",
    unicode: "U+1F1F9 U+1F1EC",
    name: "Togo",
    currency: "West African CFA franc",
    currencyCode: "XOF",
    currencyId: "952",
  },
  {
    code: "TH",
    label: { en: "Thailand", es: "Tailandia", pt: "Tailândia" },
    phone: "66",
    title: "Thailand",
    emoji: "🇹🇭",
    unicode: "U+1F1F9 U+1F1ED",
    name: "Thailand",
    currency: "Thai Baht",
    currencyCode: "THB",
    currencyId: "764",
  },
  {
    code: "TJ",
    label: { en: "Tajikistan", es: "Tayikistán", pt: "Tajiquistão" },
    phone: "992",
    title: "Tajikistan",
    emoji: "🇹🇯",
    unicode: "U+1F1F9 U+1F1EF",
    name: "Tajikistan",
    currency: "Tajikistani Somoni",
    currencyCode: "TJS",
    currencyId: "972",
  },
  {
    code: "TK",
    label: { en: "Tokelau", es: "Tokelau", pt: "Tokelau" },
    phone: "690",
    title: "Tokelau",
    emoji: "🇹🇰",
    unicode: "U+1F1F9 U+1F1F0",
    name: "Tokelau",
    currency: "New Zealand Dollar",
    currencyCode: "NZD",
    currencyId: "554",
  },
  {
    code: "TL",
    label: { en: "Timor-Leste", es: "Timor Oriental", pt: "Timor-Leste" },
    phone: "670",
    title: "Timor-Leste",
    emoji: "🇹🇱",
    unicode: "U+1F1F9 U+1F1F1",
    name: "Timor-Leste",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "TM",
    label: { en: "Turkmenistan", es: "Turkmenistán", pt: "Turquemenistão" },
    phone: "993",
    title: "Turkmenistan",
    emoji: "🇹🇲",
    unicode: "U+1F1F9 U+1F1F2",
    name: "Turkmenistan",
    currency: "Turkmenistan Manat",
    currencyCode: "TMT",
    currencyId: "934",
  },
  {
    code: "TN",
    label: { en: "Tunisia", es: "Túnez", pt: "Tunísia" },
    phone: "216",
    title: "Tunisia",
    emoji: "🇹🇳",
    unicode: "U+1F1F9 U+1F1F3",
    name: "Tunisia",
    currency: "Tunisian Dinar",
    currencyCode: "TND",
    currencyId: "788",
  },
  {
    code: "TO",
    label: { en: "Tonga", es: "Tonga", pt: "Tonga" },
    phone: "676",
    title: "Tonga",
    emoji: "🇹🇴",
    unicode: "U+1F1F9 U+1F1F4",
    name: "Tonga",
    currency: "Tongan Pa'anga",
    currencyCode: "TOP",
    currencyId: "776",
  },
  {
    code: "TR",
    label: { en: "Turkey", es: "Turquía", pt: "Turquia" },
    phone: "90",
    title: "Turkey",
    emoji: "🇹🇷",
    unicode: "U+1F1F9 U+1F1F7",
    name: "Turkey",
    currency: "Turkish Lira",
    currencyCode: "TRY",
    currencyId: "949",
  },
  {
    code: "TT",
    label: {
      en: "Trinidad and Tobago",
      es: "Trinidad y Tobago",
      pt: "Trindade e Tobago",
    },
    phone: "1-868",
    title: "Trinidad and Tobago",
    emoji: "🇹🇹",
    unicode: "U+1F1F9 U+1F1F9",
    name: "Trinidad and Tobago",
    currency: "Trinidad and Tobago Dollar",
    currencyCode: "TTD",
    currencyId: "780",
  },
  {
    code: "TV",
    label: { en: "Tuvalu", es: "Tuvalu", pt: "Tuvalu" },
    phone: "688",
    title: "Tuvalu",
    emoji: "🇹🇻",
    unicode: "U+1F1F9 U+1F1FB",
    name: "Tuvalu",
    currency: "Australian Dollar",
    currencyCode: "AUD",
    currencyId: "036",
  },
  {
    code: "TW",
    label: { en: "Taiwan", es: "Taiwán", pt: "Taiwan" },
    phone: "886",
    title: "Taiwan",
    emoji: "🇹🇼",
    unicode: "U+1F1F9 U+1F1FC",
    name: "Taiwan",
    currency: "New Taiwan Dollar",
    currencyCode: "TWD",
    currencyId: "901",
  },
  {
    code: "TZ",
    label: { en: "Tanzania", es: "Tanzania", pt: "Tanzânia" },
    phone: "255",
    title: "Tanzania",
    emoji: "🇹🇿",
    unicode: "U+1F1F9 U+1F1FF",
    name: "Tanzania",
    currency: "Tanzanian Shilling",
    currencyCode: "TZS",
    currencyId: "834",
  },
  {
    code: "UA",
    label: { en: "Ukraine", es: "Ucrania", pt: "Ucrânia" },
    phone: "380",
    title: "Ukraine",
    emoji: "🇺🇦",
    unicode: "U+1F1FA U+1F1E6",
    name: "Ukraine",
    currency: "Ukrainian Hryvnia",
    currencyCode: "UAH",
    currencyId: "980",
  },
  {
    code: "UG",
    label: { en: "Uganda", es: "Uganda", pt: "Uganda" },
    phone: "256",
    title: "Uganda",
    emoji: "🇺🇬",
    unicode: "U+1F1FA U+1F1EC",
    name: "Uganda",
    currency: "Ugandan Shilling",
    currencyCode: "UGX",
    currencyId: "800",
  },
  {
    code: "UM",
    label: {
      en: "United States Minor Outlying Islands",
      es: "Islas Ultramarinas Menores de Estados Unidos",
      pt: "Ilhas Menores Distantes dos Estados Unidos",
    },
    phone: "",
    title: "United States Minor Outlying Islands",
    emoji: "🇺🇲",
    unicode: "U+1F1FA U+1F1F2",
    name: "United States Minor Outlying Islands",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "US",
    label: { en: "United States", es: "Estados Unidos", pt: "Estados Unidos" },
    phone: "1",
    title: "United States",
    emoji: "🇺🇸",
    unicode: "U+1F1FA U+1F1F8",
    name: "United States",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "UY",
    label: { en: "Uruguay", es: "Uruguay", pt: "Uruguai" },
    phone: "598",
    title: "Uruguay",
    emoji: "🇺🇾",
    unicode: "U+1F1FA U+1F1FE",
    name: "Uruguay",
    currency: "Uruguayan Peso",
    currencyCode: "UYU",
    currencyId: "858",
  },
  {
    code: "UZ",
    label: { en: "Uzbekistan", es: "Uzbekistán", pt: "Uzbequistão" },
    phone: "998",
    title: "Uzbekistan",
    emoji: "🇺🇿",
    unicode: "U+1F1FA U+1F1FF",
    name: "Uzbekistan",
    currency: "Uzbekistan Som",
    currencyCode: "UZS",
    currencyId: "860",
  },
  {
    code: "VA",
    label: {
      en: "Vatican City",
      es: "Ciudad del Vaticano",
      pt: "Cidade do Vaticano",
    },
    phone: "379",
    title: "Vatican City",
    emoji: "🇻🇦",
    unicode: "U+1F1FB U+1F1E6",
    name: "Vatican City",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "VC",
    label: {
      en: "Saint Vincent and the Grenadines",
      es: "San Vicente y las Granadinas",
      pt: "São Vicente e Granadinas",
    },
    phone: "1-784",
    title: "Saint Vincent and the Grenadines",
    emoji: "🇻🇨",
    unicode: "U+1F1FB U+1F1E8",
    name: "Saint Vincent and the Grenadines",
    currency: "East Caribbean Dollar",
    currencyCode: "XCD",
    currencyId: "951",
  },
  {
    code: "VE",
    label: { en: "Venezuela", es: "Venezuela", pt: "Venezuela" },
    phone: "58",
    title: "Venezuela",
    emoji: "🇻🇪",
    unicode: "U+1F1FB U+1F1EA",
    name: "Venezuela",
    currency: "Venezuelan Bolívar",
    currencyCode: "VES",
    currencyId: "928",
  },
  {
    code: "VG",
    label: {
      en: "British Virgin Islands",
      es: "Islas Vírgenes Británicas",
      pt: "Ilhas Virgens Britânicas",
    },
    phone: "1-284",
    title: "British Virgin Islands",
    emoji: "🇻🇬",
    unicode: "U+1F1FB U+1F1EC",
    name: "British Virgin Islands",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "VI",
    label: {
      en: "U.S. Virgin Islands",
      es: "Islas Vírgenes de los Estados Unidos",
      pt: "Ilhas Virgens Americanas",
    },
    phone: "1-340",
    title: "U.S. Virgin Islands",
    emoji: "🇻🇮",
    unicode: "U+1F1FB U+1F1EE",
    name: "U.S. Virgin Islands",
    currency: "US Dollar",
    currencyCode: "USD",
    currencyId: "840",
  },
  {
    code: "VN",
    label: { en: "Vietnam", es: "Vietnam", pt: "Vietnã" },
    phone: "84",
    title: "Vietnam",
    emoji: "🇻🇳",
    unicode: "U+1F1FB U+1F1F3",
    name: "Vietnam",
    currency: "Vietnamese Dong",
    currencyCode: "VND",
    currencyId: "704",
  },
  {
    code: "VU",
    label: { en: "Vanuatu", es: "Vanuatu", pt: "Vanuatu" },
    phone: "678",
    title: "Vanuatu",
    emoji: "🇻🇺",
    unicode: "U+1F1FB U+1F1FA",
    name: "Vanuatu",
    currency: "Vanuatu Vatu",
    currencyCode: "VUV",
    currencyId: "548",
  },
  {
    code: "WF",
    label: {
      en: "Wallis and Futuna",
      es: "Wallis y Futuna",
      pt: "Wallis e Futuna",
    },
    phone: "681",
    title: "Wallis and Futuna",
    emoji: "🇼🇫",
    unicode: "U+1F1FC U+1F1EB",
    name: "Wallis and Futuna",
    currency: "CFP Franc",
    currencyCode: "XPF",
    currencyId: "953",
  },
  {
    code: "WS",
    label: { en: "Samoa", es: "Samoa", pt: "Samoa" },
    phone: "685",
    title: "Samoa",
    emoji: "🇼🇸",
    unicode: "U+1F1FC U+1F1F8",
    name: "Samoa",
    currency: "Samoan Tala",
    currencyCode: "WST",
    currencyId: "882",
  },
  {
    code: "YE",
    label: { en: "Yemen", es: "Yemen", pt: "Iémen" },
    phone: "967",
    title: "Yemen",
    emoji: "🇾🇪",
    unicode: "U+1F1FE U+1F1EA",
    name: "Yemen",
    currency: "Yemeni Rial",
    currencyCode: "YER",
    currencyId: "886",
  },
  {
    code: "YT",
    label: { en: "Mayotte", es: "Mayotte", pt: "Mayotte" },
    phone: "262",
    title: "Mayotte",
    emoji: "🇾🇹",
    unicode: "U+1F1FE U+1F1F9",
    name: "Mayotte",
    currency: "Euro",
    currencyCode: "EUR",
    currencyId: "978",
  },
  {
    code: "ZA",
    label: { en: "South Africa", es: "Sudáfrica", pt: "África do Sul" },
    phone: "27",
    title: "South Africa",
    emoji: "🇿🇦",
    unicode: "U+1F1FF U+1F1E6",
    name: "South Africa",
    currency: "South African Rand",
    currencyCode: "ZAR",
    currencyId: "710",
  },
  {
    code: "ZM",
    label: { en: "Zambia", es: "Zambia", pt: "Zâmbia" },
    phone: "260",
    title: "Zambia",
    emoji: "🇿🇲",
    unicode: "U+1F1FF U+1F1F2",
    name: "Zambia",
    currency: "Zambian Kwacha",
    currencyCode: "ZMW",
    currencyId: "967",
  },
  {
    code: "ZW",
    label: { en: "Zimbabwe", es: "Zimbabue", pt: "Zimbabué" },
    phone: "263",
    title: "Zimbabwe",
    emoji: "🇿🇼",
    unicode: "U+1F1FF U+1F1FC",
    name: "Zimbabwe",
    currency: "Zimbabwean Dollar",
    currencyCode: "ZWL",
    currencyId: "932",
  },
];
