import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../store";

export const selectResourceTimeframeState = createSelector(
  [selectState],
  (state) => state.resourceTimeframes,
);

export const selectResourceTimeframeStatus = createSelector(
  [selectResourceTimeframeState],
  (state) => state.status,
);

export const selectResourceTimeframes = createSelector(
  [selectResourceTimeframeState],
  (state) => state.value,
);
