import { createAsyncThunk } from "@reduxjs/toolkit";
import { EmailOtpType } from "@supabase/supabase-js";
import { SupabaseApi } from "../../api/supabase";

export const loginThunk = createAsyncThunk(
  "user/login",
  async (info: { email: string; password: string }, { rejectWithValue }) => {
    const { data, error } =
      await SupabaseApi.supabase.auth.signInWithPassword(info);

    if (!data.user) {
      return rejectWithValue(error);
    }

    return data.user;
  },
);

export const loginAnonThunk = createAsyncThunk(
  "user/loginAnon",
  async (_, { rejectWithValue }) => {
    const { data, error } = await SupabaseApi.supabase.auth.signInAnonymously();

    if (!data.user) {
      return rejectWithValue(error);
    }

    return data.user;
  },
);

export const logoutThunk = createAsyncThunk(
  "user/logout",
  async (_, { rejectWithValue }) => {
    const { error } = await SupabaseApi.supabase.auth.signOut();

    if (error) {
      return rejectWithValue(error);
    }
  },
);

export const registerThunk = createAsyncThunk(
  "user/register",
  async (
    user: {
      email: string;
      password: string;
      options: {
        data: {
          phone: string;
          first_name: string;
          last_name: string;
        };
      };
    },
    { rejectWithValue },
  ) => {
    const { data, error } = await SupabaseApi.supabase.auth.signUp(user);

    if (!data.user) {
      return rejectWithValue(error);
    }

    return data.user;
  },
);

export const sendRecoveryEmailThunk = createAsyncThunk(
  "user/recovery",
  async (email: string, { rejectWithValue }) => {
    const { data, error } =
      await SupabaseApi.supabase.auth.resetPasswordForEmail(email);

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);

export const verifyOtpThunk = createAsyncThunk(
  "user/verify",
  async (
    data: {
      email: string;
      token: string;
      type: EmailOtpType;
    },
    { rejectWithValue, fulfillWithValue },
  ) => {
    return SupabaseApi.supabase.auth.verifyOtp(data).then((data) => {
      if (data.error) {
        return rejectWithValue(data.error);
      } else {
        return fulfillWithValue(data.data.user);
      }
    });
  },
);

export const updateUserThunk = createAsyncThunk(
  "user/update",
  async (
    user: {
      email?: string;
      password?: string;
      options?: {
        data: {
          phone?: string;
          first_name?: string;
          last_name?: string;
        };
      };
    },
    { rejectWithValue },
  ) => {
    const { data: d, error } = await SupabaseApi.supabase.auth.updateUser(user);

    if (error) {
      return rejectWithValue(error);
    }

    return d.user;
  },
);
