import { createSlice } from "@reduxjs/toolkit";
import { defaultCases, defaultListBuilder, initListState } from "../utils";
import { CustomerLinkInterface } from "./interfaces";
import { addCustomerLink, getCustomerLinksThunk } from "./thunks";

const customerLinkSlice = createSlice({
  name: "customerLink",
  initialState: initListState<CustomerLinkInterface>(),
  reducers: {},
  extraReducers: (builder) => {
    defaultCases(builder, getCustomerLinksThunk)
      .addCase(
        getCustomerLinksThunk.fulfilled,
        (state, action) => {
          state.status = "fulfilled";
          state.value = action.payload;
        }
      )

    defaultListBuilder(addCustomerLink)(builder);
  }
});

export const customerLinksReducer = customerLinkSlice.reducer;
