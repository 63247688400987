import { LiveStreamMessages } from "../../../../components/live-stream-component/live-stream-messages";
import { LiveViewCalendar } from "./calendar";

export const LiveView = () => {
  return (
    <div className="h-full overflow-y-scrol flex flex-col items-center">
      
      <div className="w-full">
        <h1 className="text-4xl font-logo-semibold text-center m-4 select-none">
          Magnetic<span className="font-logo text-slate-400">Demo</span>
        </h1>
      </div>
      <div className="w-full bg-white/60 rounded-xl">
        <LiveViewCalendar />
      </div>

      <div className="pb-4">
        <LiveStreamMessages />
      </div>
    </div>
  );
};
