import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupabaseApi } from "../../api/supabase";
import type { CommerceInterface } from "./interfaces";

export const getCommerceThunk = createAsyncThunk(
  "commerce/getCommerce",
  async (userId: string) => {
    const response = await SupabaseApi.supabase
      .from("commerce")
      .select("*")
      .eq("owner", userId);

    return response.data?.at(0) as CommerceInterface;
  },
);

export const setCommerceThunk = createAsyncThunk(
  "commerce/setCommerce",
  async ({
    userId,
    ...data
  }: { userId: string } & Partial<CommerceInterface>) => {
    const response = await SupabaseApi.supabase
      .from("commerce")
      .update({ ...data })
      .eq("owner", userId)
      .select();

    return response.data?.at(0) as CommerceInterface;
  },
);

export const createCommerceThunk = createAsyncThunk(
  "commerce/createCommerce",
  async ({
    userId,
    ...data
  }: { userId: string } & Omit<Partial<CommerceInterface>, "id">) => {
    const response = await SupabaseApi.supabase
      .from("commerce")
      .insert({ ...data })
      .eq("owner", userId)
      .select();

    return response.data?.at(0) as CommerceInterface;
  },
);
