import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { WeekScheduleTable } from "../../../components/week-schedule/week-schedule-table";
import { translations } from "../../../core/constants/translations";
import { useLangParam } from "../../../core/hooks";
import { selectCommerceId } from "../../../store/commerce/selectors";
import { selectResources } from "../../../store/resource/selectors";
import { addResourceTimeframesThunk } from "../../../store/resource_timeframe/thunks";
import { AppDispatch } from "../../../store/store";
import { selectTimeframes } from "../../../store/timeframe/selectors";
import {
  addTimeframesThunk,
  removeTimeframesThunk,
  updateTimeframesThunk,
} from "../../../store/timeframe/thunks";

export const ScheduleStage = ({
  onBack,
  onContinue,
}: {
  onBack: () => void;
  onContinue: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();

  const translation = translations["Onboarding"]["Business Hours"];

  const commerceId = useSelector(selectCommerceId);
  const timeframes = useSelector(selectTimeframes);
  const resources = useSelector(selectResources);

  return (
    <div className="bg-white w-96 h-max rounded-lg flex flex-col items-center">
      <div className="border-b border-gray-300 w-full flex items-center justify-center py-6">
        <div className="relative w-0 -left-16">
          <div
            className="cursor-pointer"
            onClick={() => {
              onBack();
            }}
          >
            <ArrowBack />
          </div>
        </div>

        <div className="w-[200px] h-3 overflow-hidden rounded-full bg-gray-100">
          <div className="w-[80px] h-3 rounded-full bg-purple-300" />
        </div>
      </div>

      <p className="text-2xl mt-4 font-bold">
        {translation[lang]}
      </p>

      <p className="text-center text-sm text-gray-500 mt-4">
        {translation["Description"][lang]}
      </p>

      <div className="w-full">
        <WeekScheduleTable
          timeframes={timeframes}
          onAddTimeframe={(t) => {
            commerceId &&
              dispatch(
                addTimeframesThunk({
                  ...t,
                  commerce_id: commerceId,
                }),
              );
          }}
          onRemoveTimeframes={(ids) => {
            dispatch(removeTimeframesThunk(ids));
          }}
          onUpdateTimeframes={(t) => {
            dispatch(updateTimeframesThunk(t));
          }}
        />
      </div>

      <div className="px-6 pb-6 w-full">
        <Button
          variant="contained"
          sx={{
            width: "100%",
            background: "rgb(192 132 252)",
            "&:hover": {
              background: "rgb(216 180 254)",
            },
          }}
          onClick={() => {
            resources.forEach(({ id }) => {
              timeframes.forEach(({ day, start, duration }) => {
                dispatch(
                  addResourceTimeframesThunk({
                    day,
                    start,
                    duration,
                    resource_id: id,
                  }),
                );
              });
            });

            onContinue();
          }}
        >
          {translation["Continue"][lang]}
        </Button>
      </div>
    </div>
  );
};
