import { useNavigate } from "react-router-dom"


export const SignUpBtn = () => {
    const navigate = useNavigate();  
    return (
        <div
          className="cursor-pointer hover:bg-slate-300 px-5 py-2 rounded-full"
          onClick={() => {
            navigate("/register");
          }}
        >
          Sign up
        </div>
    );
  };