import { ChevronLeft } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { AppointmentStatus } from "../../core/interfaces";
import { Time } from "../../core/utils/time-utils";
import { dayObject } from "../../pages/demo/components/constants";
import { selectAppointments } from "../../store/appointment/selectors";
import { selectResources } from "../../store/resource/selectors";
import { selectServices } from "../../store/service/selectors";
import { Months } from "./constants";

export const DaySchedule = ({
  date,
  onBack,
}: {
  date: Date;
  onBack: () => void;
}) => {
  const services = useSelector(selectServices);
  const resources = useSelector(selectResources);

  const appointments = useSelector(selectAppointments);

  const dayAppointments = appointments.filter((appointment) => {
    const ad = new Date(appointment.scheduled_start_at);

    return (
      ad.getDate() === date.getDate() &&
      ad.getMonth() === date.getMonth() &&
      ad.getFullYear() === date.getFullYear()
    );
  });

  return (
    <div className="flex flex-col gap-1 w-full">
      <div className="flex gap-2 py-1">
        <ChevronLeft
          sx={{
            ml: "-7px",
            cursor: "pointer",
          }}
          onClick={() => {
            onBack();
          }}
        />

        <p className="font-medium">
          {Object.keys(dayObject)[date.getDay()]}, {date.getDate()}{" "}
          {Months[date.getMonth()]}
        </p>
      </div>

      {dayAppointments
        .filter((a) => {
          return a.status === AppointmentStatus.confirmed;
        })
        .sort((a, b) => {
          return new Date(a.scheduled_start_at).getTime()
            - new Date(b.scheduled_start_at).getTime();
        })
        .map(({ service_id, resource_id, scheduled_start_at }, i) => {
          const service = services.find(({ id }) => service_id === id);
          const resource = resources.find(({ id }) => resource_id === id);
          const time = `${new Date(scheduled_start_at).getHours()}:${new Date(scheduled_start_at).getMinutes().toString().padStart(2, "0")}`;

          return (
            <div key={i} className="w-full">
              <div className="grid grid-cols-[10px_1fr]">
                <div className={`${service?.color} h-full w-1 rounded-full`} />

                <div className="grid grid-cols-2">
                  <p className="font-medium">{service?.name}</p>

                  <p className="text-sm justify-self-end">{time}</p>

                  <p className="text-sm text-gray-500">{resource?.name}</p>

                  <p className="text-sm justify-self-end">
                    {service?.duration &&
                      new Time(time).addMinutes(service?.duration)?.str}
                  </p>
                </div>
              </div>

              <div className="w-full pl-3 mt-1">
                <div className="w-full border-b border-indigo-200" />
              </div>
            </div>
          );
        })}
    </div>
  );
};
