import { ExpandMoreOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useHandleClickOutside } from "../../core/hooks";

export const CommerceSelector = ({
  options,
  placeholder,
  value,
  setValue,
  disabled,
  onTop,
  maxH = "max-h-64",
}: {
  options: string[];
  placeholder: string;
  value?: string | null;
  setValue: (v: string) => void;
  disabled?: boolean;
  maxH?: string;
  onTop?: boolean,
}) => {
  const [open, setOpen] = useState(false);

  const dropdownRef = useHandleClickOutside<HTMLDivElement>(() => {
    setOpen(false);
  });

  return (
    <div ref={dropdownRef} className="select-none">
      <div
        className={`w-full h-10 p-3 border border-gray-300 flex items-center justify-between rounded ${disabled ? "" : "cursor-pointer"} ${open ? "bg-white" : ""}`}
        onClick={() => {
          !disabled && setOpen((v) => !v);
        }}
      >
        <p
          className={`${value ? "text-gray-800" : disabled ? "text-gray-300" : "text-gray-500"}`}
        >
          {value || placeholder}
        </p>

        {disabled || (
          <ExpandMoreOutlined
            sx={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.1s ease",
            }}
          />
        )}
      </div>

      {open && (
        <div className="relative h-0 w-0">
          <div className={`absolute ${onTop ? "bottom-10" : ""} bg-white z-10 shadow rounded w-56 h-max overflow-y-scroll ${maxH}`}>
            {options.map((option) => {
              return (
                <p
                  className="p-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setValue(option);
                    setOpen(false);
                  }}
                >
                  {option}
                </p>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
