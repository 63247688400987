import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../../store";

export const selectBusinessTypesState = createSelector(
  [selectState],
  (state) => state.constants.businessTypes,
);

export const selectBusinessTypesStatus = createSelector(
  [selectBusinessTypesState],
  (state) => state.status,
);

export const selectBusinessTypes = createSelector(
  [selectBusinessTypesState],
  (state) => state.value,
);
