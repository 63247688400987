import { CheckOutlined } from "@mui/icons-material";
import { useState } from "react";
import { Time } from "../../core/utils/time-utils";
import { WeekScheduleTimeframeInterface } from "./interfaces";
import { WeekScheduleModal } from "./week-schedule-modal";

export const WeekSchedule = <T extends WeekScheduleTimeframeInterface>({
  timeframes,
  onAddTimeframes,
  onRemoveTimeframes,
  onUpdateTimeframes,
}: {
  timeframes: T[];
  onAddTimeframes: (t: Omit<T, "id">) => void;
  onRemoveTimeframes: (ids: string[]) => void;
  onUpdateTimeframes: (t: Partial<T>) => void;
}) => {
  const [openScheduleModal, setOpenScheduleModal] = useState(false);

  return (
    <div className="grid grid-cols-7 justify-center gap-1">
      {[
        {
          key: "Sunday",
          title: "S",
        },
        {
          key: "Monday",
          title: "M",
        },
        {
          key: "Tuesday",
          title: "T",
        },
        {
          key: "Wednesday",
          title: "W",
        },
        {
          key: "Thursday",
          title: "T",
        },
        {
          key: "Friday",
          title: "F",
        },
        {
          key: "Saturday",
          title: "S",
        },
      ].map(({ key, title }) => {
        const dayFrames = timeframes.filter(({ day }) => {
          return day === key.toUpperCase();
        });

        const isChecked = dayFrames.length > 0;

        return (
          <div className="flex flex-col items-center gap-2">
            <p>{title}</p>

            <div
              className={`
                  flex items-center justify-center
                  ${isChecked ? "" : "border border-gray-300"}
                  rounded-md
                  ${isChecked ? "bg-indigo-400" : ""}
                  w-5 h-5 mb-1
                  ${isChecked ? "hover:bg-indigo-200" : "hover:bg-gray-100"}
                  cursor-pointer
                `}
              onClick={() => {
                if (!isChecked) {
                  onAddTimeframes({
                    day: key.toUpperCase() as any,
                    start: "9:00",
                    duration: 60 * 9,
                  } as T);
                } else {
                  onRemoveTimeframes(dayFrames.map((f) => f.id));
                }
              }}
            >
              {isChecked ? (
                <CheckOutlined
                  sx={{
                    color: "white",
                    height: "1.25rem",
                    width: "1.25rem",
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            <div className="flex flex-col gap-1">
              {dayFrames.sort((a, b) => {
                return parseInt(a.start?.split(":").at(0) || "0") - parseInt(b.start?.split(":").at(0) || "0");
              }).map(({ start, duration }) => {
                if (!start || !duration) return null;

                return (
                  <div
                    className="flex flex-col items-center rounded-lg bg-gray-300 p-1 cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      setOpenScheduleModal(true);
                    }}
                  >
                    <p className="text-xs">{new Time(start).str}</p>

                    <p className="text-xs">
                      {new Time(start).addMinutes(duration)?.str}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}

      {openScheduleModal && (
        <WeekScheduleModal
          onClose={() => {
            setOpenScheduleModal(false);
          }}
          timeframes={timeframes}
          onAddTimeframe={onAddTimeframes}
          onRemoveTimeframes={onRemoveTimeframes}
          onUpdateTimeframes={onUpdateTimeframes}
        />
      )}
    </div>
  );
};
